import { useCallback, useContext, useEffect } from "react";
import { WebsocketContext } from "Providers";
import { WebsocketFunctionNames } from "Constants";
import { INotification } from "Interfaces";
import { NotificationStatusEnum, NotificationTypeEnum } from "Enums";

export const useNotification = (onNotificationAsync: (notification: INotification) => Promise<void>, notificationTypesFilter?: NotificationTypeEnum[]) => {
    const { webSocketController } = useContext(WebsocketContext);

    const onReceiveNotification = useCallback(async (correlationId: string, type: NotificationTypeEnum, status: NotificationStatusEnum, message: string) => {
        if (notificationTypesFilter && !notificationTypesFilter.includes(type)) return;

        await onNotificationAsync({ correlationId, type, status, message });
    }, [notificationTypesFilter, onNotificationAsync]);

    useEffect(() => {
        webSocketController.addHandler(
            WebsocketFunctionNames.ReceiveNotification,
            onReceiveNotification
        );

        return () => {
            webSocketController.removeHandler(
                WebsocketFunctionNames.ReceiveNotification,
                onReceiveNotification
            );
        };
    }, [onReceiveNotification, webSocketController]);
};
