// React
import debounce from "lodash.debounce";
import { FC, useEffect, useMemo, useState } from "react";
// Components
import {
  FindestButton,
  FindestTextBox,
  LoadingStatusIndicator,
  Modal,
  Attachment,
} from "Components";
// Controllers
import {
  DocumentControllerSingleton,
  SavedWeblinkControllerSingleton,
} from "Controllers";

// Enums
import { LoadingStatusEnum, SavedDocumentTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import {
  DocumentTypeHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Types
import { TDocumentDTO } from "Types";
// Styles
import styles from "./addDocument.module.scss";

type TAddDocumentProps = {
  onCreationDone: (createdSavedDocumentId: string, createdSavedDocumentType: SavedDocumentTypeEnum) => void;
  onCancel?: () => void;
  clearSpotlight?: () => void;
};

export const AddDocument: FC<TAddDocumentProps> = ({
  onCreationDone,
  onCancel,
  clearSpotlight,
}: TAddDocumentProps) => {
  // State
  const [textualInputValidationStatus, setTextualInputValidationStatus] =
    useState<LoadingStatusEnum>(LoadingStatusEnum.NotStarted);
  const [foundDocument, setFoundDocument] = useState<TDocumentDTO | undefined>(
    undefined
  );
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [documentTitle, setDocumentTitle] = useState<string>("");
  const [isImportFileModalOpen, setIsImportFileModalOpen] =
    useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Memos
  const isAddButtonEnabled = useMemo(() => {
    return textualInputValidationStatus === LoadingStatusEnum.Done;
  }, [textualInputValidationStatus]);

  const getFileNameWithoutFileExtension = (fileName: string) => {
    const splitted = fileName.split(".");
    if (splitted.length === 1) return fileName;
    return splitted.slice(0, -1).join(".");
  };

  // at component mount
  useEffect(() => {
    // log
    LogHelperSingleton.log("StartAddingDocument");
  }, []);

  // Handle the change of the textbox input
  const onTextualInputChanged = debounce(async (value: string) => {
    setFoundDocument(undefined);

    if (!value || value.trim().length === 0) {
      setTextualInputValidationStatus(LoadingStatusEnum.NotStarted);
      return;
    }

    setTextualInputValidationStatus(LoadingStatusEnum.Loading);
    const currFoundDocument =
      await DocumentControllerSingleton.validateAddDocumentAsync(value);
    if (!currFoundDocument) {
      setTextualInputValidationStatus(LoadingStatusEnum.Failed);
      return;
    }

    setTextualInputValidationStatus(LoadingStatusEnum.Done);
    setFoundDocument(currFoundDocument);
    clearSpotlight?.();
  }, 100);

  const onDocumentTitleChange = (value: string) => {
    setDocumentTitle(value);
  };

  // Handle the click on the add document button
  const onAddDocumentClick = async () => {
    if (!isAddButtonEnabled) return;
    if (!foundDocument && !selectedFile) return;
    setIsSubmitting(true);

    if (foundDocument) {
      const savedDocument =
        await DocumentControllerSingleton.createAsync(foundDocument);

      if (!savedDocument) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not save the document."
        );
        setIsSubmitting(false);
        return;
      }

      onCreationDone(savedDocument.id, savedDocument.savedDocumentType);
    }
    setIsSubmitting(false);

  };

  const customRightIcon = () => (
    <div className={styles.customInputRightIcon}>
      <LoadingStatusIndicator size={20} status={textualInputValidationStatus} />
    </div>
  );

  const onImportFileButtonClick = () => {
    setIsImportFileModalOpen(true);
    clearSpotlight?.();
  };

  const onImportFileModalClose = () => {
    setIsImportFileModalOpen(false);
    setSelectedFile(undefined);
    setDocumentTitle("");
  };

  const onAddAttachmentProp = (file: File) => {
    if (
      !documentTitle ||
      (selectedFile &&
        getFileNameWithoutFileExtension(selectedFile.name) === documentTitle)
    ) {
      const fileNameWithoutFileExtension = getFileNameWithoutFileExtension(
        file.name
      );
      setDocumentTitle(fileNameWithoutFileExtension);
    }
    setSelectedFile(file);
  };

  const onDeleteAttachmentProp = () => {
    setSelectedFile(undefined);
    setDocumentTitle("");
  };

  const onLocalDocumentAddClick = async () => {
    if (!selectedFile || !documentTitle) return;
    const weblinkId = await SavedWeblinkControllerSingleton.uploadLocalDocumentAsync({
      title: documentTitle,
      formFile: selectedFile,
    });
    if (!weblinkId) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not add the document."
      );
      return;
    }
    onImportFileModalClose();
    onCreationDone(weblinkId, SavedDocumentTypeEnum.Weblink);
  };

  return (
    <div>
      <div className={styles.section}>
        <div className={styles.title}>Add a local document</div>
        <FindestButton
          buttonType="secondary"
          title="Import file"
          onClick={onImportFileButtonClick}
        />
      </div>
      <div className={styles.section}>
        <div className={styles.title}>or use DOI, patent number or URL</div>
        <div>
          <FindestTextBox
            placeholder="DOI, patent number, URL"
            onChange={onTextualInputChanged}
            rightIconProps={{
              customIcon: customRightIcon(),
            }}
          />
        </div>
        {foundDocument ? (
          <div className={styles.foundDocument}>
            <div>
              <span>
                Found a{" "}
                {DocumentTypeHelperSingleton.getSavedDocumentTypeDisplayName(
                  foundDocument.type
                )}{" "}
                document with the following title:
              </span>
            </div>
            <div className={styles.webpageTitle}>
              <span>&ldquo;{foundDocument.webpageTitle}&rdquo;</span>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.footer}>
        <FindestButton
          title={isSubmitting ? "" : "Add document"}
          buttonType="primary"
          onClick={onAddDocumentClick}
          isDisabled={!isAddButtonEnabled}
          extraClassName={styles.addButton}
          isLoading={isSubmitting}
        />
        {onCancel && (
          <FindestButton
            title="Cancel"
            onClick={onCancel}
            extraClassName={styles.cancelButton}
            buttonType="cancel"
          />
        )}
      </div>
      <Modal
        header="Import file to documents"
        extraClassNames={{ container: styles.importFileModal }}
        isOpen={isImportFileModalOpen}
        onClose={onImportFileModalClose}
      >
        <div className={styles.section}>
          <h4 className={styles.title}>Attachment</h4>
          <Attachment
            onAddAttachmentProp={onAddAttachmentProp}
            onDeleteAttachmentProp={onDeleteAttachmentProp}
          />
        </div>
        <div className={styles.section}>
          <h4 className={styles.title}>Title</h4>
          <FindestTextBox
            placeholder="Title of the document"
            onChange={onDocumentTitleChange}
            value={documentTitle}
          />
        </div>
        <FindestButton
          title="Add document"
          onClick={onLocalDocumentAddClick}
          isDisabled={!selectedFile || !documentTitle}
        />
      </Modal>
    </div>
  );
};
