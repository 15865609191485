import axios, { AxiosResponse } from "axios";
import { AxiosHelperSingleton } from "Helpers";
import { IAskIgorRequest, IAskIgorResponse, IAskIgorSourceSummaryRequest } from "Interfaces";

export class AskIgorController {
  private readonly _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/v1/ask-igor`;

  public async generateSourceSummaryAsync(request: IAskIgorSourceSummaryRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/source-summary`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateAdvancedQueryAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/advanced-query`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateDescriptionFromGeneralKnowledgeAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/description-from-general-knowledge`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateDescriptionFromLinkedSourcesAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/description-from-linked-sources`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateReportAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/report`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateAnswerAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/answer`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateSectionAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/section`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateInformationExtractionAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/information-extraction`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async generateExecutiveSummaryAsync(request: IAskIgorRequest, abortSignal?: AbortSignal): Promise<IAskIgorResponse | undefined> {
    try {
      const response: AxiosResponse<IAskIgorResponse> =
        await axios.post<IAskIgorResponse>(
          `${this._resourcePath}/executive-summary`,
          request,
          {
            signal: abortSignal,
          }
        );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async stopGenerationAsync(correlationId: string): Promise<boolean> {
    try {
      const response: AxiosResponse<boolean> =
        await axios.delete<boolean>(
          `${this._resourcePath}/${correlationId}`);

      if (response) {
        return response.data;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
}

export const AskIgorControllerSingleton = new AskIgorController();
