/* eslint-disable react/no-danger */
// node_modules
import { FC, MouseEvent } from "react";
// Types
import { THeaderContentDTO } from "Types";
// Styles
import "Styles/documentHighlights.scss";
import styles from "./documentMainContents.module.scss";
// Interfaces
import { IDocumentDetails } from "Interfaces";
import { SavedDocumentTypeEnum } from "Enums";

// Component props type
type TDocumentMainContentsProps = {
    document: IDocumentDetails,
    highlightedContent?: THeaderContentDTO[],
    onSelectedTextOnMouseEvent: (mouseEvent: MouseEvent<HTMLElement>) => void
}

// Component
export const DocumentMainContents: FC<TDocumentMainContentsProps> = ({ document, highlightedContent, onSelectedTextOnMouseEvent }) => {
    // Logic
    const getHeaderDisplayValue = (header: string, documentType: SavedDocumentTypeEnum) => {
        // if document type is weblink
        if (documentType === SavedDocumentTypeEnum.Weblink) {
            // return Description as header display value
            return "Description";
        }

        // otherwise, return header
        return header;
    };

    // Render
    return (
        <div className={styles.documentContentContainer}>
            {highlightedContent?.map((content) => {
                return (
                    <div key={content.header}>
                        <h3>{getHeaderDisplayValue(content.header, document.documentType)}</h3>
                        <p dangerouslySetInnerHTML={{ __html: content.content }} onMouseUp={onSelectedTextOnMouseEvent}></p>
                    </div>
                );
            })}
            {!highlightedContent && document.mainContents?.map((content) => {
                return (
                    <div key={content.header}>
                        <h3>{getHeaderDisplayValue(content.header, document.documentType)}</h3>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{content.content}</p>
                    </div>
                );
            })}
            {(!document.mainContents || document.mainContents.length === 0 || !document.documentCustomInformation?.summary) && <p>No contents</p>}
        </div>
    );
};
