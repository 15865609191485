// node_modules
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { FC, useContext, useMemo, useState } from "react";
// Components
import {
  CreatedByAccount,
  DropdownButton,
  EditorStickyMenu,
  ObjectPinElement,
  RolesChecker,
  ShareButton,
  SharingModal,
} from "Components";
import { ObjectModeToggle } from "./ObjectModeToggle";
import { ObjectUnlockDropdown } from "./ObjectUnlockDropdown/ObjectUnlockDropdown";
// Contexts
import {
  AuthContext,
  EditorContext,
  ElementVisibilityContext,
  LinkGraphContext,
  PinnedContext,
  WindowingContext,
} from "Providers";
// Enums
import {
  LinksWindowTabsEnum,
  ObjectTypeEnum,
  RolesEnum,
  ToastTypeEnum,
} from "Enums";
// Helpers
import {
  DateHelperSingleton,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
// Types
import { TDropdownButtonOption } from "Types";
// Constants
import { EntityConstants, GeneralConstants, StudyConstants } from "Constants";
// Controllers
import {
  EntityControllerSingleton,
  ExportControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
// Interfaces
import { IPage } from "Interfaces";
// Styles
import styles from "./objectTopbar.module.scss";

type TObjectTopbarProps = {
  extraClassNames?: {
    container?: string;
  };
  hideReferenceModal?: (callback?: () => void) => void;
};

export const ObjectTopbar: FC<TObjectTopbarProps> = ({
  extraClassNames = {},
  hideReferenceModal,
}: TObjectTopbarProps) => {
  // Contexts
  const { isUserExternal, auth } = useContext(AuthContext);
  const {
    isEditOn,
    isObjectLocked,
    objectEdited,
    toggleIsObjectLockedAsync,
    setIsLinkingModalOpen,
  } = useContext(EditorContext);
  const {
    isPinButtonVisible,
    isShareButtonVisible,
    isEditorEllipsisMenuVisible,
    canUserEdit,
  } = useContext(ElementVisibilityContext);
  const { onReanchorClick } = useContext(LinkGraphContext);
  const { openGraph } = useContext(WindowingContext);
  const { refreshPins } = useContext(PinnedContext);

  const [isSharingModalOpen, setIsSharingModalOpen] = useState<boolean>(false);

  const isEditable = useMemo(() => {
    return !isUserExternal && !!isEditOn && canUserEdit;
  }, [isUserExternal, isEditOn, canUserEdit]);

  const optionLabels: TDropdownButtonOption[] = useMemo(() => {
    const options: TDropdownButtonOption[] = ["create link"];

    if (UserHelperSingleton.hasAccessToAllLocked(auth)) {
      options.push(isObjectLocked ? "unlock page" : "lock page");
    }

    options.push("export as docx");
    options.push("export as pdf");

    if (!UserHelperSingleton.isSharingRestrictedToObject(auth)) {
      options.push(...["open in tree view", "open in list view"]);
    }

    if (!isObjectLocked && isEditOn) {
      options.push("delete");
    }
    return options;
  }, [auth, isEditOn, isObjectLocked]);

  const onShareClick = () => {
    if (isUserExternal) return;

    setIsSharingModalOpen(true);
  };

  const onAddLinkClick = () => {
    if (!canUserEdit) return;

    setIsLinkingModalOpen(true);
  };

  const onSetPageLock = async (newIsLocked: boolean) => {
    if (isUserExternal) return;

    if (newIsLocked) {
      await toggleIsObjectLockedAsync(newIsLocked);
    } else {
      await toggleIsObjectLockedAsync(false);
    }
  };

  const deleteObjectAsync = async (currObjectEdited: IPage) => {
    let isSuccess = false;

    if (currObjectEdited.objectType === ObjectTypeEnum.Entity) {
      isSuccess = await EntityControllerSingleton.deleteAsync(
        currObjectEdited.id
      );
    } else if (currObjectEdited.objectType === ObjectTypeEnum.Study) {
      isSuccess = await StudyControllerSingleton.deleteAsync(
        currObjectEdited.id
      );
    }

    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `Could not delete ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
          currObjectEdited.objectType
        ).toLowerCase()}.`
      );
      return;
    }

    await refreshPins();
  };

  const onDeleteClickAsync = async (
    currObjectEdited: IPage | null,
    canEdit: boolean
  ): Promise<void> => {
    if (!canEdit || !currObjectEdited) return;

    let confirmMessage = GeneralConstants.DELETE_OBJECT_CONFIRMATION;
    if (currObjectEdited.objectType === ObjectTypeEnum.Entity) {
      confirmMessage = EntityConstants.DELETE_ENTITY_CONFIRMATION;
    } else if (currObjectEdited.objectType === ObjectTypeEnum.Study) {
      confirmMessage = StudyConstants.DELETE_STUDY_CONFIRMATION;
    }

    if (!confirm(confirmMessage)) return;

    if (hideReferenceModal) {
      hideReferenceModal(async () => await deleteObjectAsync(currObjectEdited));
    } else {
      await deleteObjectAsync(currObjectEdited);
    }
  };

  const clickActionsOptionAsync = async (option: TDropdownButtonOption) => {
    // if the user is readonly then do nothing
    if (!canUserEdit) return;

    if (option === "create link") {
      onAddLinkClick();
    } else if (option === "delete") {
      onDeleteClickAsync(objectEdited, isEditable);
    } else if (option === "share") {
      onShareClick();
    } else if (option === "unlock page") {
      onSetPageLock(false);
    } else if (option === "lock page") {
      onSetPageLock(true);
    } else if (option === "open in tree view" && objectEdited) {
      onReanchorClick(objectEdited.id, objectEdited.objectType, false);
      openGraph(LinksWindowTabsEnum.TreeView);
    } else if (option === "open in list view" && objectEdited) {
      onReanchorClick(objectEdited.id, objectEdited.objectType, false);
      openGraph(LinksWindowTabsEnum.ListView);
    } else if (option === "export as docx") {
      onDocxExportClick();
      // export as docx
    } else if (option === "export as pdf") {
      onPdfExportClick();
    }
  };

  const onDocxExportClick = async () => {
    if (!objectEdited) return;

    const request = {
      contentType: "docx",
    };
    await ExportControllerSingleton.exportFileAsync(
      objectEdited.id,
      objectEdited.objectType,
      request
    );
  };

  const onPdfExportClick = async () => {
    if (!objectEdited) return;

    const request = {
      contentType: "pdf",
    };
    await ExportControllerSingleton.exportFileAsync(
      objectEdited.id,
      objectEdited.objectType,
      request
    );
  };

  if (!objectEdited) return null;

  // Render
  return (
    <div
      className={`${styles.objectTopbarContainer} ${extraClassNames.container ?? ""
        }`}
    >
      <EditorStickyMenu />
      <div className={styles.rightSide}>
        <RolesChecker
          roles={[RolesEnum.Viewer, RolesEnum.External]}
          isExcluding={true}
        >
          {!isObjectLocked && <ObjectModeToggle />}
          {isObjectLocked && (
            <ObjectUnlockDropdown
              onUnlockPageClick={() => clickActionsOptionAsync("unlock page")}
            />
          )}
        </RolesChecker>
        {isPinButtonVisible && (
          <div className={styles.standaloneButtons}>
            <div className={styles.standaloneButton}>
              <ObjectPinElement pinType={"objectBar"} id={objectEdited?.id} />
            </div>
          </div>
        )}
        {isShareButtonVisible && (
          <div className={styles.standaloneButtons}>
            <div className={styles.standaloneButton}>
              <ShareButton onClick={() => clickActionsOptionAsync("share")} />
            </div>
          </div>
        )}
        {isEditorEllipsisMenuVisible && (
          <DropdownButton
            isButtonEnabled={true}
            showDropdownInPortal
            optionLabels={optionLabels}
            onClickOption={clickActionsOptionAsync}
            extraClassNames={{
              dropdownButton: styles.actionsButton,
              buttonIconContainer: styles.actionsButtonIconContainer,
              optionText: styles.optionText,
              dropdownButtonHover: styles.actionsDropdownButtonHover,
            }}
            iconNameRight={faEllipsisV}
          />
        )}
        <CreatedByAccount
          email={objectEdited.createdByUsername}
          createdDate={DateHelperSingleton.getDateWithYear(
            new Date(objectEdited.createdOnDate)
          )}
        />
      </div>
      <SharingModal
        isOpen={isSharingModalOpen}
        setIsOpen={setIsSharingModalOpen}
        objectId={objectEdited.id}
        objectType={objectEdited.objectType}
      />
    </div>
  );
};
