/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AiConstants,
  TextConstants,
} from "Constants";
import {
  AskIgorControllerSingleton,
  LinkingControllerSingleton,
} from "Controllers";
import { AskIgorMenuItemEnum, NotificationStatusEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
import {
  AskIgorHelperSingleton,
  AskIgorMenuItemHelperSingleton,
  LogHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
import { useAskIgorNotification } from "Hooks";
import { IAskIgorRequest, IAskIgorResponse, IAskIgorResult, INotification, IUseAskIgor } from "Interfaces";
import { useCallback, useRef, useState } from "react";
import {
  TIdNameTypeObjectType,
  TLogEventName
} from "Types";

interface IUseAskIgorProps {
  askIgorMenuItem?: AskIgorMenuItemEnum;
  documentsSelected: string[];
}

export const useAskIgor = ({
  askIgorMenuItem,
  documentsSelected,
}: IUseAskIgorProps): IUseAskIgor => {
  const [isGeneratingText, setIsGeneratingText] = useState<boolean>(false);
  const [correlationId, setCorrelationId] =
    useState<string | undefined>(undefined);
  const [aiGeneratedText, setAIGeneratedText] = useState<string>("");
  const [aiGeneratedExplanation, setAIGeneratedExplanation] = useState<string>("");

  const abortControllerRef = useRef<AbortController>(new AbortController());
  const timeoutRef = useRef<number | null>(null);

  const onSubmitHandlerAsync = async (
    menuItem: AskIgorMenuItemEnum,
    userInput: string,
    sourceTypes: ObjectTypeEnum[],
    doIncludeSourceFullText: boolean,
    page?: TIdNameTypeObjectType,
  ): Promise<void> => {
    if (!menuItem) {
      return;
    }

    LogHelperSingleton.logWithProperties("AskIgorAISettingsUpdated", {
      DocumentTypes: sourceTypes.join(", "),
    });

    if (menuItem === AskIgorMenuItemEnum.InformationExtraction) {
      userInput = userInput?.trim() || "";

      if (!userInput) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(menuItem)
        );
        return;
      }
    }

    await askIgorAsync(
      menuItem,
      userInput,
      sourceTypes,
      doIncludeSourceFullText,
      page,
    );
  };

  const stopGeneration = useCallback(async (forCorrelationId?: string) => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (forCorrelationId) {
      await AskIgorControllerSingleton.stopGenerationAsync(forCorrelationId);
    }
    setCorrelationId(undefined);

    abortControllerRef.current.abort();
  }, []);

  const onAskIgorNotificationAsync = useCallback(async (notification: INotification): Promise<void> => {
    const { correlationId: notificationCorrelationId, message, status } = notification;
    const result: IAskIgorResult = JSON.parse(message);

    if (notificationCorrelationId !== correlationId) return;

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    switch (status) {
      case NotificationStatusEnum.Error:
        setAIGeneratedExplanation("");
        setAIGeneratedText(`${AiConstants.ERROR_START_MESSAGE}${message}`);
        break;
      case NotificationStatusEnum.Progress:
        setAIGeneratedExplanation(
          (prevAIGeneratedExplanation) => prevAIGeneratedExplanation + (result.explanation)
        );
        setAIGeneratedText(
          (prevAIGeneratedText) => prevAIGeneratedText + AskIgorHelperSingleton.getAskIgorResultGenerationContent(result, askIgorMenuItem)
        );
        break;
      case NotificationStatusEnum.Success:
        setAIGeneratedExplanation(result.explanation ?? "");
        setAIGeneratedText(AskIgorHelperSingleton.getAskIgorResultGenerationContent(result, askIgorMenuItem));
        break;
      default:
        break;
    }

    setCorrelationId(undefined);
    setIsGeneratingText(false);
  }, [askIgorMenuItem, correlationId]);

  useAskIgorNotification(onAskIgorNotificationAsync);

  const sendAskIgorRequestAsync = async (request: IAskIgorRequest, selectedMenuItem: AskIgorMenuItemEnum): Promise<IAskIgorResponse | undefined> => {
    abortControllerRef.current = new AbortController();

    switch (selectedMenuItem) {
      case AskIgorMenuItemEnum.Answer:
        return await AskIgorControllerSingleton.generateAnswerAsync(request, abortControllerRef.current.signal);
      case AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge:
        return await AskIgorControllerSingleton.generateDescriptionFromGeneralKnowledgeAsync(request, abortControllerRef.current.signal);
      case AskIgorMenuItemEnum.DescriptionFromLinkedSources:
        return await AskIgorControllerSingleton.generateDescriptionFromLinkedSourcesAsync(request, abortControllerRef.current.signal);
      case AskIgorMenuItemEnum.Report:
        return await AskIgorControllerSingleton.generateReportAsync(request, abortControllerRef.current.signal);
      case AskIgorMenuItemEnum.Section:
        return await AskIgorControllerSingleton.generateSectionAsync(request, abortControllerRef.current.signal);
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return await AskIgorControllerSingleton.generateExecutiveSummaryAsync(request, abortControllerRef.current.signal);
      default:
        break;
    }

    return undefined;
  };

  const onAiGenerationTimedOutAsync = useCallback(async (forCorrelationId?: string): Promise<void> => {
    stopGeneration(forCorrelationId);
    setIsGeneratingText(false);
    setAIGeneratedText(
      `${AiConstants.ERROR_START_MESSAGE}${AiConstants.AI_GENERATION_TIMED_OUT}`
    );
  }, [stopGeneration]);

  const askIgorAsync = useCallback(async (
    menuItem: AskIgorMenuItemEnum,
    userInput: string,
    sourceTypes: ObjectTypeEnum[],
    doIncludeSourceFullText: boolean,
    page?: TIdNameTypeObjectType,
  ) => {
    if (isGeneratingText) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        AiConstants.AI_GENERATION_ALREADY_RUNNING
      );
      return;
    }

    stopGeneration(correlationId);
    setIsGeneratingText(false);
    setAIGeneratedText("");
    setAIGeneratedExplanation("");

    const doRequireSourcesOrHighlights =
      AskIgorMenuItemHelperSingleton.getDoRequireSourcesOrHighlights(
        menuItem
      );

    if (doRequireSourcesOrHighlights) {
      const noDocumentsOrHighlights = documentsSelected.length <= 0 && !page;
      const pageWithoutLinks =
        page &&
        !await LinkingControllerSingleton.hasLinksOfTypesAsync(page.id, [
          ObjectTypeEnum.Highlight,
          ...sourceTypes,
        ]);

      if (noDocumentsOrHighlights || pageWithoutLinks) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS
        );
        return;
      }
    }

    const startLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedStartLogEventName(
        menuItem
      );
    LogHelperSingleton.log(startLogEventName);

    const newCorrelationId = window.crypto.randomUUID();
    setCorrelationId(newCorrelationId);
    setIsGeneratingText(true);

    timeoutRef.current = window.setTimeout(onAiGenerationTimedOutAsync, AiConstants.AI_GENERATION_TIMEOUT_DURATION, newCorrelationId);

    const response: IAskIgorResponse | undefined = await sendAskIgorRequestAsync({
      correlationId: newCorrelationId,
      input: {
        pageId: page?.id,
        pageType: page?.objectType,
        userInput,
        sourcesAmount: undefined
      },
      sourceTypes,
      doIncludeSourceFullText,
      sourceIds: documentsSelected,
    } as IAskIgorRequest, menuItem);

    if (abortControllerRef.current.signal.aborted) {
      stopGeneration(newCorrelationId);
      setIsGeneratingText(false);
      return;
    }

    if (!response) {
      stopGeneration(newCorrelationId);
      setIsGeneratingText(false);
      setAIGeneratedExplanation("");
      setAIGeneratedText(
        `${AiConstants.ERROR_START_MESSAGE
        }${AskIgorMenuItemHelperSingleton.getRelatedErrorMessage(
          menuItem
        )}`
      );
    }
  }, [correlationId, documentsSelected, isGeneratingText, onAiGenerationTimedOutAsync, stopGeneration]);

  return {
    correlationId,
    isGeneratingText,
    setIsGeneratingText,
    onSubmitHandlerAsync,
    aiGeneratedText,
    setAIGeneratedText,
    stopGeneration,
    aiGeneratedExplanation,
    setAIGeneratedExplanation
  };
};
