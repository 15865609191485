import { FC, useEffect, useRef, useState, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FindestButton,
  FindestTextBox,
  LoadingStatusIndicator,
  MoreActionsDropdownButton,
  StopOnClickEventPropagation,
  ObjectItem,
} from "Components";
import {
  AiControllerSingleton,
  WebsocketControllerSingleton,
} from "Controllers";
import { WebsocketFunctionNames } from "Constants";
import { ObjectTypeEnum, LoadingStatusEnum } from "Enums";
import { ObjectTypeHelperSingleton, LogHelperSingleton } from "Helpers";
import { TQuestionAndAnswerResult, TQuestionReference } from "Types";
import styles from "./askYourUniversePage.module.scss";
import { faChevronRight, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { CreateQueryWizard } from "Components/Shared/Topbar/CreateNewButton/CreateQueryWizard/CreateQueryWizard";
import { AuthContext } from "Providers";
import { useNavigate } from "react-router-dom";

export const AskYourUniversePage: FC = () => {
  const {
    auth: { userEmail },
  } = useContext(AuthContext);

  const [searchText, setSearchText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>("");
  const [references, setReferences] = useState<TQuestionReference[]>([]);
  const [questionId, setQuestionId] = useState<string>("");
  const [chatHistory, setChatHistory] = useState<{ type: "user" | "igor", text: string }[]>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleQuestionAnswer = (data: TQuestionAndAnswerResult | string) => {
      let result: TQuestionAndAnswerResult;
      if (typeof data === "string") {
        result = JSON.parse(data);
      } else {
        result = data;
      }

      setAnswer(result.answer);
      if (result.references) {
        setReferences(result.references);
      }
      setIsLoading(false);
    };

    const questionAndAnswerResultListenName = `${WebsocketFunctionNames.ReceiveQuestionAndAnswerResult}-${questionId}`;

    WebsocketControllerSingleton.addHandler(
      questionAndAnswerResultListenName,
      handleQuestionAnswer
    );

    return () => {
      WebsocketControllerSingleton.removeHandler(
        questionAndAnswerResultListenName,
        handleQuestionAnswer
      );
    };
  }, [questionId]);

  useEffect(() => {
    // Scroll to bottom of chat when history updates
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleSearch = async () => {
    if (!searchText.trim() || isLoading) return;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Add user question to chat history
    setChatHistory(prev => [...prev, { type: "user", text: searchText }]);

    abortControllerRef.current = new AbortController();
    setIsLoading(true);
    setAnswer("");
    setReferences([]);

    try {
      const response = await AiControllerSingleton.askYourUniverseAsync(
        searchText,
        abortControllerRef.current.signal
      );

      if (response) {
        setQuestionId(response.questionId);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // Update chat history when answer is received
  useEffect(() => {
    if (answer) {
      setChatHistory(prev => [...prev, { type: "igor", text: answer }]);
    }
  }, [answer]);

  const getReferencesForType = (types: ObjectTypeEnum[]) => {
    return references.filter((ref) => types.includes(ref.type));
  };

  const handleReferenceClick = useCallback((reference: TQuestionReference) => {
    ObjectTypeHelperSingleton.navigateBasedOnObjectType(
      reference.type,
      reference.id,
      navigate
    );

    LogHelperSingleton.log("OpenReferencePage");
  }, [navigate]);

  const renderReferenceItem = (reference: TQuestionReference) => {
    return (
      <ObjectItem
        key={reference.id}
        objectItem={{
          id: reference.id,
          name: reference.title,
          objectType: reference.type,
          type: ObjectTypeHelperSingleton.getObjectTypeClassName(reference.type)
        }}
        isEditable={false}
        isUserExternal={false}
        onClick={() => handleReferenceClick(reference)}
        extraClassName={styles.referenceItem}
      />
    );
  };

  const pageReferences = getReferencesForType([
    ObjectTypeEnum.Entity,
    ObjectTypeEnum.Study,
  ]);

  const documentReferences = getReferencesForType([
    ObjectTypeEnum.Document,
    ObjectTypeEnum.MagPatent,
    ObjectTypeEnum.ScienceArticle,
    ObjectTypeEnum.UsPatent,
    ObjectTypeEnum.Weblink,
  ]);

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsLoading(false);
    // Allow new questions by clearing the question ID
    setQuestionId("");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span>Search</span>
      </div>
      <div className={styles.searchSection}>
        <div className={styles.searchInputWrapper}>
          <FindestTextBox
            value={searchText}
            onChange={setSearchText}
            placeholder="Ask anything about your data..."
            extraClassName={styles.searchBox}
            onEnter={handleSearch}
          />
          <FindestButton
            onClick={handleSearch}
            extraClassName={styles.searchButton}
            isDisabled={!searchText.trim() || isLoading}
            rightIconName={faChevronRight}
          ></FindestButton>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.chatContainer} ref={chatContainerRef}>
          {chatHistory.map((message, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.messageWrapper}>
              <div className={`${styles.messageSection} ${styles[message.type]}`}>
                {message.type === "igor" && <span className={styles.contentLabel}>IGOR</span>}
                {message.type === "user" && <span className={styles.contentLabel}>YOU</span>}
                <p className={styles.messageText}>{message.text}</p>
              </div>
            </div>
          ))}
          {isLoading && (
            <div className={styles.loadingIndicatorContainer}>
              <LoadingStatusIndicator size={32} status={LoadingStatusEnum.Loading} />
              <FindestButton
                onClick={handleCancel}
                buttonType="secondary"
                title="Cancel search"
              />
            </div>
          )}
        </div>
        {(pageReferences.length > 0 || documentReferences.length > 0) && (
          <div className={styles.referencesSection}>
            {pageReferences.length > 0 && (
              <div className={styles.referencesBox}>
                <h3>REFERENCED PAGES</h3>
                <div className={styles.referencesList}>
                  {pageReferences.map(renderReferenceItem)}
                </div>
              </div>
            )}
            {documentReferences.length > 0 && (
              <div className={styles.referencesBox}>
                <h3>REFERENCED DOCUMENTS</h3>
                <div className={styles.referencesList}>
                  {documentReferences.map(renderReferenceItem)}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.outsideUniverseSection}>
        <h3>Search outside of the Universe</h3>
        <CreateQueryWizard
          userEmail={userEmail}
        />
      </div>
    </div>
  );
};
