import { faCheck, faDiceD6, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkCreatedEntityModal, LoadingStatusIndicator, ObjectChip } from "Components";
import { EntityTypeEnum, LoadingStatusEnum, ObjectTypeEnum } from "Enums";
import { ObjectTypeHelperSingleton, UserHelperSingleton } from "Helpers";
import { useLinkNewEntityToQuery, useObjectReferenceModal } from "Hooks";
import { useTechnologySearchResults } from "Hooks/useTechnologySearchResults";
import {
  fromIDocumentDetails,
  fromIDocumentSearchResult,
  IDocumentDetails,
  IDocumentSearchResult,
  IEntityDTO,
  IPage,
  IQueryDTO,
} from "Interfaces";
import { AuthContext } from "Providers";
import { FC, useCallback, useContext, useState } from "react";
import { TIdNameTypeObjectType } from "Types";
import styles from "./extractedTechnology.module.scss";

export interface IExtractedTechnologyProps {
  query: IQueryDTO;
  document?: IDocumentSearchResult;
  extractedTechnology?: string | null;
  updateDocument?: (document: IDocumentSearchResult) => void;
  addExtractedTechnologyAsEntityCallbackAsync?: (
    createdEntity: IEntityDTO
  ) => Promise<void>;
  extraClassNames?: { title?: string, chip?: string };
}

export const ExtractedTechnology: FC<IExtractedTechnologyProps> = ({
  query,
  document,
  extractedTechnology,
  updateDocument,
  addExtractedTechnologyAsEntityCallbackAsync,
  extraClassNames
}) => {
  const { auth } = useContext(AuthContext);

  const [isAddingExtractedTechnologyAsEntity, setIsAddingExtractedTechnologyAsEntity] =
    useState<boolean>(false);
  const [
    wasExtractedTechnologyAddedAsEntity,
    setWasExtractedTechnologyAddedAsEntity,
  ] = useState<boolean>(false);
  const [connectedObject, setConnectedObject] =
    useState<TIdNameTypeObjectType | undefined>(undefined);
  const [isLinkCreatedEntityModalOpen, setIsLinkCreatedEntityModalOpen] =
    useState<boolean>(false);
  const [entityToCreate, setEntityToCreate] =
    useState<IEntityDTO | undefined>(undefined);

  const { linkNewEntityToQueryAsync } = useLinkNewEntityToQuery(
    query,
    setIsLinkCreatedEntityModalOpen,
    document ? fromIDocumentSearchResult(document) : undefined,
    document
      ? ObjectTypeHelperSingleton.documentTypeToObjectType(
        document.documentType
      )
      : undefined,
    updateDocument
      ? (documentToUpdate: IDocumentDetails) => {
        updateDocument(fromIDocumentDetails(documentToUpdate));
      }
      : undefined
  );
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();


  const onGenerateDescriptionFromLinkedSourcesDone = useCallback(() => {
    setIsAddingExtractedTechnologyAsEntity(false);
    setWasExtractedTechnologyAddedAsEntity(true);
  }, []);
  const { generateDescriptionFromLinkedSourcesAsync } = useTechnologySearchResults(onGenerateDescriptionFromLinkedSourcesDone);

  if (!extractedTechnology) return null;

  const onConnectedObjectClick = (
    clickedConnectedObject: TIdNameTypeObjectType
  ): void => {
    // safety-checks
    if (!clickedConnectedObject) {
      return;
    }

    setReferenceModalProps((previousReferenceModalProps) => {
      return {
        ...previousReferenceModalProps,
        isOpen: true,
        id: clickedConnectedObject.id,
        type: clickedConnectedObject.objectType,
      };
    });
  };

  const onAddExtractedTechnologyAsEntityClick = async (): Promise<void> => {
    setEntityToCreate({
      title: extractedTechnology,
      type: EntityTypeEnum.Technology,
    } as IEntityDTO);

    setIsLinkCreatedEntityModalOpen(true);
  };

  const onCreateEntityClickAsync = async (
    entity: IEntityDTO,
    linkType: string,
    linkedObject?: TIdNameTypeObjectType
  ): Promise<IEntityDTO | undefined> => {
    setIsAddingExtractedTechnologyAsEntity(true);

    const createdEntity: IEntityDTO | undefined =
      await linkNewEntityToQueryAsync(entity, linkType, linkedObject);

    if (!createdEntity) return;

    setConnectedObject(linkedObject);

    await addExtractedTechnologyAsEntityCallbackAsync?.(createdEntity);

    const isSuccess = await generateDescriptionFromLinkedSourcesAsync([
      {
        id: createdEntity.id,
        objectType: ObjectTypeEnum.Entity,
        title: createdEntity.title,
      } as IPage
    ]);

    if (!isSuccess) {
      setIsAddingExtractedTechnologyAsEntity(false);
      setWasExtractedTechnologyAddedAsEntity(true);
    }
  };

  return (
    <div className={styles.extractedTechnology}>
      {!isAddingExtractedTechnologyAsEntity && <span className={extraClassNames?.title ?? ""}>{extractedTechnology}</span>}
      {wasExtractedTechnologyAddedAsEntity ? (
        <>
          <span className={styles.addAsEntityChip}>
            <FontAwesomeIcon icon={faDiceD6} />
            <FontAwesomeIcon icon={faCheck} />
          </span>
          {connectedObject && (
            <ObjectChip
              isChipClickable
              object={connectedObject}
              onClick={
                UserHelperSingleton.isSharingRestrictedToObject(auth)
                  ? undefined
                  : () => {
                    onConnectedObjectClick(connectedObject);
                  }
              }
            />
          )}
        </>
      ) : (
        isAddingExtractedTechnologyAsEntity ?
          <LoadingStatusIndicator status={LoadingStatusEnum.Loading} />
          :
          <button className={`${styles.addAsEntityChip} ${extraClassNames?.chip ?? ""}`} onClick={onAddExtractedTechnologyAsEntityClick} type="button">
            <FontAwesomeIcon icon={faPlus} />
            <FontAwesomeIcon icon={faDiceD6} />
          </button>
      )}
      {isLinkCreatedEntityModalOpen && (
        <LinkCreatedEntityModal
          creatingEntity={entityToCreate}
          isOpen={isLinkCreatedEntityModalOpen}
          setIsOpen={setIsLinkCreatedEntityModalOpen}
          onCreateEntityClickAsync={onCreateEntityClickAsync}
          initialLinkedObjects={query.connectedObjects}
        />
      )}
      {referenceModal}
    </div>
  );
};
