// node_modules
import { TableOfContentDataItem } from "@tiptap-pro/extension-table-of-contents";
import { Editor as TiptapEditor } from "@tiptap/core";
import { ReplaceStep, Step } from "@tiptap/pm/transform";
import { Content } from "@tiptap/react";
import debounce from "lodash.debounce";
import { Transaction } from "prosemirror-state";
import { FC, useCallback, useContext, useEffect, useMemo, useRef } from "react";
// Components
import {
  AddImageModal,
  AskIgorModal,
  EditorContent,
  EditorFloatingLinkMenu,
  EditorFloatingSelectMenu,
  EditorFloatingTableMenu,
  EditorLeftFloatingMenu,
  FileInput,
  OverviewTableModal
} from "Components";
// Constants
import { GeneralConstants } from "Constants";
// Providers
import { EditorContext } from "Providers";
// Styles
import "./editor.scss";
import styles from "./editorModule.module.scss";
// Interfaces
import { IPage, ISavedDocumentDTO } from "Interfaces";
// Enums
import { LogFeatureNameEnum, ObjectTypeEnum } from "Enums";
// Helpers
import {
  EditorHelperSingleton,
  INSERT_FILE_COMMAND,
  INSERT_IMAGE_COMMAND,
  IntakeSheetNodeExtension,
  LogHelperSingleton,
} from "Helpers";
// Types
import { TImageDTO, TSavedFileDTO } from "Types";
// Custom hooks
import { useFileUpload } from "Hooks";
// Controllers
import { ReferenceControllerSingleton } from "Controllers";

interface IEditorProps {
  onImageInsertedAsync: (
    image: File,
    caption?: string
  ) => Promise<TImageDTO | undefined>;
  onContentChangeAsync: (
    content: string,
    forceUpdate: boolean
  ) => Promise<void>;
  uploadFileAsync: (file: File) => Promise<TSavedFileDTO | null | undefined>;
  object: IPage;
  objectType: ObjectTypeEnum;
  type: string;
  content: string;
  selectedSavedDocuments?: ISavedDocumentDTO[];
}

export const Editor: FC<IEditorProps> = ({
  onImageInsertedAsync,
  onContentChangeAsync,
  uploadFileAsync,
  object,
  objectType,
  type,
  content,
  selectedSavedDocuments
}: IEditorProps) => {
  const {
    objectEdited,
    editor,
    isEditOn,
    setTableOfContents,
    askIgorModalOptions,
    setAskIgorModalOptions,
    setObjectEdited,
    isAddImageModalOpen,
    setIsAddImageModalOpen,
    setContent,
    fileInputRef,
  } = useContext(EditorContext);

  const editorContainerRef = useRef<HTMLDivElement>(null);

  const onFileSelectedAsync = async (file: File): Promise<void> => {
    if (!editor) return;

    const uploadedFile: TSavedFileDTO | null | undefined =
      await uploadFileAsync(file);

    if (!uploadedFile) return;

    INSERT_FILE_COMMAND.action(editor, {
      objectId: uploadedFile.id,
      objectType: ObjectTypeEnum.File,
      fileName: `${uploadedFile.title}.${uploadedFile.fileExtension}`,
      url: uploadedFile.url,
    });

    LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertFile`);
  };

  const { onFileInputChangeAsync } = useFileUpload({
    fileSizeLimit: 25_000_000,
    onFileSelectedAsync,
  });

  const debouncedOnContentChangeAsync = useMemo(
    () => debounce(onContentChangeAsync, GeneralConstants.DEFAULT_MS_DELAY),
    [onContentChangeAsync]
  );

  const updateReferencedByAsync = useCallback(
    async (newContent: Content) => {
      if (!objectEdited) return;

      ReferenceControllerSingleton.updateReferencedByAsync(
        objectEdited.id,
        objectEdited.objectType,
        Array.from(EditorHelperSingleton.getReferenceIds(newContent, new Set()))
      );
    },
    [objectEdited]
  );
  const debouncedUpdateReferencedByAsync = useMemo(
    () => debounce(updateReferencedByAsync, GeneralConstants.DEFAULT_MS_DELAY),
    [updateReferencedByAsync]
  );

  const onTransactionHandlerAsync = useCallback(
    async ({
      editor: updatedEditor,
      transaction,
    }: {
      editor: TiptapEditor;
      transaction: Transaction;
    }) => {
      setTableOfContents((prev) => {
        const previousActiveTocItem = prev?.content.find(
          (header: TableOfContentDataItem) => header.isActive
        );
        return {
          ...updatedEditor.extensionStorage.tableOfContents,
          content: updatedEditor.extensionStorage.tableOfContents.content.map(
            (tocItem: TableOfContentDataItem) => {
              return {
                ...tocItem,
                isActive:
                  previousActiveTocItem &&
                  previousActiveTocItem.id === tocItem.id,
              };
            }
          ),
        };
      });

      if (
        transaction.steps.length > 0 &&
        !transaction.getMeta("doNotTriggerUpdate")
      ) {
        await debouncedOnContentChangeAsync(
          JSON.stringify(updatedEditor.getJSON()),
          getIsIntakeSheetTransaction(transaction)
        );

        await debouncedUpdateReferencedByAsync(updatedEditor.getJSON());
      }
    },
    [
      debouncedOnContentChangeAsync,
      debouncedUpdateReferencedByAsync,
      setTableOfContents,
    ]
  );

  const getIsIntakeSheetTransaction = (transaction: Transaction): boolean => {
    let isIntakeSheetTransaction = false;

    transaction.steps.forEach((step: Step) => {
      const slice = (step as ReplaceStep).slice;
      if (slice && slice.content) {
        slice.content.forEach((node) => {
          if (node.type.name === IntakeSheetNodeExtension.name) {
            isIntakeSheetTransaction = true;
          }
        });
      }
    });

    return isIntakeSheetTransaction;
  };

  useEffect(() => {
    if (!editor) return;

    editor.on("transaction", onTransactionHandlerAsync);

    return () => {
      editor.off("transaction", onTransactionHandlerAsync);
    };
  }, [editor, onTransactionHandlerAsync]);

  useEffect(() => {
    setContent(content);

    // cleanup
    return () => {
      setContent("");
    };
  }, [content, setContent]);

  useEffect(() => {
    setObjectEdited({
      id: object.id,
      objectType,
      createdByUsername: object.createdByUsername,
      createdOnDate: object.createdOnDate,
    } as IPage);

    return () => {
      setObjectEdited(null);
    };
  }, [
    object.createdByUsername,
    object.createdOnDate,
    object.id,
    objectType,
    setObjectEdited,
  ]);

  if (!editor) return null;

  const onAddImageHandlerAsync = async (
    image: File,
    caption?: string
  ): Promise<void> => {
    const newImage: TImageDTO | undefined = await onImageInsertedAsync(
      image,
      caption
    );

    if (!newImage) return;

    INSERT_IMAGE_COMMAND.action(editor, {
      image,
      caption,
      imageId: newImage.id,
    });

    LogHelperSingleton.log(`${LogFeatureNameEnum.Reporting}-InsertImage`);
  };

  return (
    <div ref={editorContainerRef}>
      <EditorLeftFloatingMenu editor={editor} isEditOn={isEditOn} />
      <EditorContent
        editor={editor}
        savedDocuments={object.savedDocuments}
      />
      {editorContainerRef.current && (
        <>
          <EditorFloatingLinkMenu
            editor={editor}
            appendTo={editorContainerRef.current}
          />
          {isEditOn && (
            <EditorFloatingTableMenu
              editor={editor}
              appendTo={editorContainerRef.current}
            />
          )}
        </>
      )}
      {askIgorModalOptions.isOpen && <AskIgorModal
        options={askIgorModalOptions}
        object={{
          id: object.id,
          objectType,
          type,
          name: object.title,
        }}
        setOptions={setAskIgorModalOptions}
        editor={editor}
        selectedSavedDocuments={selectedSavedDocuments}
      />}
      <EditorFloatingSelectMenu />
      <AddImageModal
        isOpen={isAddImageModalOpen}
        setIsOpen={setIsAddImageModalOpen}
        onAddImage={onAddImageHandlerAsync}
        hasCaption={true}
      />
      <FileInput
        title={INSERT_FILE_COMMAND.label}
        inputRef={fileInputRef}
        onChange={onFileInputChangeAsync}
        extraClassNames={{
          input: styles.fileInput,
        }}
      />
      <OverviewTableModal />
    </div>
  );
};
