import axios from "axios";
import { AxiosHelperSingleton } from "Helpers";
import { TQuestionAndAnswerResponse } from "Types";

export class AiController {
  private readonly _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/ai`;

  public async askYourUniverseAsync(
    question: string,
    abortSignal?: AbortSignal
  ): Promise<TQuestionAndAnswerResponse | undefined> {
    try {
      const response = await axios.post<TQuestionAndAnswerResponse | undefined>(
        `${this._resourcePath}/ask-your-universe`,
        { question },
        { signal: abortSignal }
      );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch (error: unknown) {
      return undefined;
    }
  }
}

export const AiControllerSingleton = new AiController();
