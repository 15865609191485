import { Editor } from "@tiptap/react";
import { AiConstants } from "Constants";
import { AskIgorMenuItemEnum, DocumentObjectTypeEnums, ObjectTypeEnum, ToastTypeEnum } from "Enums";
import { AskIgorHelperSingleton, ExtensionsKit, GetCustomLink, ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
import { useBulkAskIgor, useEntity, useStudy } from "Hooks";
import { IAskIgorRequest, IAskIgorResult, IPage } from "Interfaces";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useTechnologySearchResults = (onGenerateDescriptionFromLinkedSourcesDone?: () => void) => {
    const [pages, setPages] = useState<IPage[]>([]);

    const navigate = useNavigate();
    const { appendContentToEntityDescriptionAsync } = useEntity();
    const { appendContentToStudyDescriptionAsync } = useStudy();

    const processGenerationSuccessAsync = useCallback(
        async (request: IAskIgorRequest, result: IAskIgorResult) => {
            const pageId = request.input.pageId;
            if (!pageId) {
                return;
            }

            const editor = new Editor({
                extensions: [...ExtensionsKit, GetCustomLink(navigate, true)],
                editable: false,
                content: AskIgorHelperSingleton.getAskIgorResultGenerationContent(result, AskIgorMenuItemEnum.DescriptionFromLinkedSources),
            });
            const content = editor.getJSON();
            const pageTitle = pages.find((p) => p.id === pageId)?.title;

            switch (request.input.pageType) {
                case ObjectTypeEnum.Entity:
                    if (!await appendContentToEntityDescriptionAsync(pageId, content?.content ?? [])) {
                        ToastHelperSingleton.showToast(
                            ToastTypeEnum.Error,
                            `${AiConstants.GENERATE_DESCRIPTION_FAILED} Entity: ${pageTitle}.`
                        );
                    } else {
                        ToastHelperSingleton.showToast(
                            ToastTypeEnum.Success,
                            `Description generated for ${pageTitle}.`
                        );
                    }
                    break;
                case ObjectTypeEnum.Study:
                    if (!await appendContentToStudyDescriptionAsync(pageId, content?.content ?? [])) {
                        ToastHelperSingleton.showToast(
                            ToastTypeEnum.Error,
                            `${AiConstants.GENERATE_DESCRIPTION_FAILED} Study: ${pageTitle}.`
                        );
                    } else {
                        ToastHelperSingleton.showToast(
                            ToastTypeEnum.Success,
                            `Description generated for ${pageTitle}.`
                        );
                    }
                    break;
            }


        }, [appendContentToEntityDescriptionAsync, appendContentToStudyDescriptionAsync, navigate, pages]);

    const processGenerationErrorAsync = useCallback(
        async (request: IAskIgorRequest, message: string) => {
            const page = pages.find((p) => p.id === request.input.pageId);

            ToastHelperSingleton.showToast(
                ToastTypeEnum.Error,
                `${AiConstants.GENERATE_DESCRIPTION_FAILED} ${page ? ObjectTypeHelperSingleton.getObjectTypeEndpointName(page.objectType) : "Page"}: ${page?.title}. ${message}`
            );
        }, [pages]);

    const onAllRequestsDone = useCallback(() => {
        setPages([]);
        onGenerateDescriptionFromLinkedSourcesDone?.();
    }, [onGenerateDescriptionFromLinkedSourcesDone]);

    const { cancelAllGenerationRequests, addGenerationRequested, requestGenerationAsync, deleteGenerationRequestedAsync } = useBulkAskIgor(processGenerationSuccessAsync, processGenerationErrorAsync, onAllRequestsDone);

    const generateDescriptionFromLinkedSourcesAsync = async (forPages: IPage[]): Promise<boolean> => {
        cancelAllGenerationRequests();
        setPages(forPages);

        const askIgorRequests: IAskIgorRequest[] = [];

        for (const forPage of forPages) {
            askIgorRequests.push({
                correlationId: window.crypto.randomUUID(),
                input: {
                    pageId: forPage.id,
                    pageType: forPage.objectType,
                    userInput: ""
                },
                sourceTypes: DocumentObjectTypeEnums,
                doIncludeSourceFullText: true,
            });
        }
        addGenerationRequested(askIgorRequests);

        let isSuccess = true;
        for (const askIgorRequest of askIgorRequests) {
            isSuccess = await requestGenerationAsync(askIgorRequest, AskIgorMenuItemEnum.DescriptionFromLinkedSources);
            const forPage = forPages.find((fp) => fp.id === askIgorRequest.input.pageId);
            const pageType = forPage ? ObjectTypeHelperSingleton.getObjectTypeEndpointName(forPage.objectType) : "Page";

            if (!isSuccess) {
                ToastHelperSingleton.showToast(
                    ToastTypeEnum.Error,
                    `${AiConstants.GENERATE_DESCRIPTION_FAILED} ${pageType}: ${forPage?.title}.`
                );
                deleteGenerationRequestedAsync(askIgorRequest.correlationId);
                break;
            } else {
                ToastHelperSingleton.showToast(
                    ToastTypeEnum.Information,
                    `Started generating description for ${forPage?.title}.`
                );
            }
        }
        return isSuccess;
    };

    return { cancelAllGenerateDescriptionFromLinkedSourcesRequests: cancelAllGenerationRequests, generateDescriptionFromLinkedSourcesAsync, processGenerationSuccessAsync, processGenerationErrorAsync };
};