export enum AskIgorMenuItemEnum {
  Answer = "Answer",
  Description = "Description",
  DescriptionFromLinkedSources = "DescriptionFromLinkedSources",
  DescriptionFromGeneralKnowledge = "DescriptionFromGeneralKnowledge",
  ExecutiveSummary = "ExecutiveSummary",
  Section = "Section",
  Report = "Report",
  InformationExtraction = "InformationExtraction",
}
