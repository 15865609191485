export const WebsocketFunctionNames = {
  ReceiveToast: "ReceiveToast",
  StartSearchQueryById: "StartSearchByQueryId",
  StartTechnologySearch: "StartTechnologySearch",
  StartTechnologySearchOnResults: "StartTechnologySearchOnResults",
  StopTechnologySearch: "StopTechnologySearch",
  ReceiveDocumentSearchResults: "DocumentSearchResults",
  RequestDocumentContentHighlight: "RequestDocumentContentHighlight",
  ReceiveDocumentCount: "ReceiveDocumentCount",
  HighlightingFinished: "HighlightingFinished",
  GroupedDocumentSearchResults: "GroupedDocumentSearchResults",
  ReceivePublicationsPerYearSearchResults:
    "ReceivePublicationsPerYearSearchResults",
  SearchEnded: "ReceiveEndProgress",
  ReceiveSearchTermHitCounts: "ReceiveSearchTermHitCounts",
  ReceiveGroupedDocuments: "ReceiveGroupedDocuments",
  ReceiveAffiliationSearchResults: "ReceiveAffiliationSearchResults",
  ReceiveSearchId: "ReceiveSearchID",
  CancelSearch: "CancelSearch",
  CancelCompletion: "CancelCompletion",
  StartQuestionAndAnswerCompletion: "StartQuestionAndAnswerCompletion",
  StartExecutiveSummaryCompletion: "StartExecutiveSummaryCompletion",
  StartDescriptionCompletion: "StartDescriptionCompletion",
  StartWriteSectionCompletion: "StartWriteSectionCompletion",
  StartTableCompletion: "StartTableCompletion",
  ReceiveTechnologySearchResults: "ReceiveTechnologySearchResults",
  ReceiveTechnologySearchProgress: "ReceiveTechnologySearchProgress",
  ReceiveHealthCheck: "ReceiveHealthCheck",
  ReceiveQuestionAndAnswerResult: "ReceiveQuestionAndAnswerResult",
  StartInformationExtractionCompletion: "StartInformationExtractionCompletion",
  ReceiveNotification: "ReceiveNotification",
} as const;
