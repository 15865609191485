import { LinkingConstants } from "Constants";
import { LinkingControllerSingleton } from "Controllers";
import { ObjectTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { TIdNameTypeObjectType } from "Types";

export class LinkingHelper {
  public linkAsync = async (
    objectId: string,
    objectType: ObjectTypeEnum,
    selectedObjects: TIdNameTypeObjectType[],
    linkType: string,
    callback: undefined | (() => void) = undefined
  ): Promise<boolean> => {
    // store each create link result in an array
    const createLinkResults: WebRequestStatusEnum[] = [];

    // for each selected object
    for (let i = 0; i < selectedObjects.length; i++) {
      // set from and to link id and type
      let fromLinkId = "";
      let fromLinkType = ObjectTypeEnum.Unknown;
      let toLinkId = "";
      let toLinkType = ObjectTypeEnum.Unknown;
      if (linkType === LinkingConstants.CHILD_LINK_TYPE.value) {
        fromLinkId = objectId;
        fromLinkType = objectType;
        toLinkId = selectedObjects[i].id;
        toLinkType = selectedObjects[i].objectType;
      } else if (linkType === LinkingConstants.PARENT_LINK_TYPE.value) {
        fromLinkId = selectedObjects[i].id;
        fromLinkType = selectedObjects[i].objectType;
        toLinkId = objectId;
        toLinkType = objectType;
      }

      // call server to create link
      const createLinkResult: WebRequestStatusEnum =
        await LinkingControllerSingleton.createToAsync(
          toLinkId,
          toLinkType,
          fromLinkId,
          fromLinkType
        );

      // add create link result to array
      createLinkResults.push(createLinkResult);

      // deal with create link result
      if (createLinkResult !== WebRequestStatusEnum.Success && createLinkResult !== WebRequestStatusEnum.AlreadyExists) {
        // failure, notify user
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not create link."
        );
      }
    }

    // if a callback was provided
    if (callback) {
      // call it
      callback();
    }

    // if all create link results are success or already exists
    if (
      createLinkResults.every(
        (createLinkResult) =>
          createLinkResult === WebRequestStatusEnum.Success ||
          createLinkResult === WebRequestStatusEnum.AlreadyExists
      )
    ) {
      // means linking is done
      return true;
    } else {
      // otherwise linking is not done
      return false;
    }
  };
}

export const LinkingHelperSingleton = new LinkingHelper();
