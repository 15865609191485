// Enums
import { AiConstants } from "Constants";
import { AskIgorMenuItemEnum } from "Enums";
// Types
import { TLogEventName } from "Types";

export class AskIgorMenuItemHelper {
  public askIgorMenuItemEnumToDisplayString(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): string {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.Answer:
        return "Question & Answer";
      case AskIgorMenuItemEnum.Description:
      case AskIgorMenuItemEnum.DescriptionFromLinkedSources:
      case AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge:
        return "General description";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Summary";
      case AskIgorMenuItemEnum.InformationExtraction:
        return "Extract detail information";
      case AskIgorMenuItemEnum.Section:
        return "Section";
      case AskIgorMenuItemEnum.Report:
        return "Standard report";
      default:
        return "";
    }
  }

  public askIgorMenuItemEnumToDisplayDescription(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): string {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.Description:
      case AskIgorMenuItemEnum.DescriptionFromLinkedSources:
      case AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge:
        return "Either based on general knowledge or the linked sources.";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Based on the linked sources.";
      case AskIgorMenuItemEnum.InformationExtraction:
        return "I can also extract specific data from the linked sources.";
      case AskIgorMenuItemEnum.Section:
        return "Give me a title and I will write the section.";
      case AskIgorMenuItemEnum.Report:
        return "Introduction, Main developments, Findings, Pros and cons, Conclusion, Next steps";
      default:
        return "";
    }
  }

  public getDoRequireSourcesOrHighlights(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): boolean {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.Answer:
      case AskIgorMenuItemEnum.DescriptionFromLinkedSources:
      case AskIgorMenuItemEnum.ExecutiveSummary:
      case AskIgorMenuItemEnum.Section:
      case AskIgorMenuItemEnum.Report:
      case AskIgorMenuItemEnum.InformationExtraction:
        return true;
      default:
        return false;
    }
  }

  public getRelatedStartLogEventName(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): TLogEventName {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.Answer:
        return "Start-Ai-Answer";
      case AskIgorMenuItemEnum.DescriptionFromLinkedSources:
        return "Start-Ai-DescriptionFromLinkedSources";
      case AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge:
        return "Start-Ai-DescriptionFromGeneralKnowledge";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Start-Ai-ExecutiveSummary";
      case AskIgorMenuItemEnum.Section:
        return "Start-Ai-Section";
      case AskIgorMenuItemEnum.Report:
        return "Start-Ai-Report";
      case AskIgorMenuItemEnum.InformationExtraction:
        return "Start-Ai-InformationExtraction";
      default:
        return "Start-Ai-Answer";
    }
  }

  public getRelatedInsertResultLogEventName(
    askIgorMenuItem: AskIgorMenuItemEnum
  ): TLogEventName {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.Answer:
        return "Insert-Ai-Answer-Result";
      case AskIgorMenuItemEnum.DescriptionFromLinkedSources:
        return "Insert-Ai-DescriptionFromLinkedSources-Result";
      case AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge:
        return "Insert-Ai-DescriptionFromGeneralKnowledge-Result";
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Insert-Ai-ExecutiveSummary-Result";
      case AskIgorMenuItemEnum.Section:
        return "Insert-Ai-Section-Result";
      case AskIgorMenuItemEnum.Report:
        return "Insert-Ai-Report-Result";
      case AskIgorMenuItemEnum.InformationExtraction:
        return "Insert-Ai-InformationExtraction-Result";
      default:
        return "Insert-Ai-Answer-Result";
    }
  }

  public getRelatedErrorMessage(askIgorMenuItem: AskIgorMenuItemEnum | undefined): string {
    switch (askIgorMenuItem) {
      case AskIgorMenuItemEnum.Description:
      case AskIgorMenuItemEnum.DescriptionFromLinkedSources:
      case AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge:
        return AiConstants.GENERATE_DESCRIPTION_FAILED;
      case AskIgorMenuItemEnum.ExecutiveSummary:
        return "Could not generate executive summary, please contact the support.";
      case AskIgorMenuItemEnum.InformationExtraction:
        return AiConstants.GENERATE_INFORMATION_EXTRACTION_FAILED;
      case AskIgorMenuItemEnum.Report:
        return "Could not generate report, please contact the support.";
      default:
        return "Something went wrong, please contact the support.";
    }
  }
}

export const AskIgorMenuItemHelperSingleton = new AskIgorMenuItemHelper();
