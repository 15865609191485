// node_modules
import { faMessageBot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tiptap/core";
import { FC, useCallback, useContext, useRef } from "react";
// Helpers
import {
  ASK_IGOR_COMMAND,
  EditorHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Styles
import styles from "../editorMenu.module.scss";
// Hooks
import { useSelectionNode } from "Hooks";
// Interfaces
import { ICommand } from "Interfaces";
// Providers
import { EditorContext } from "Providers";
// Enums
import {
  AskIgorMenuItemEnum,
  DocumentObjectTypeEnums,
  ObjectTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Constants
import { TextConstants } from "Constants";
// Controllers
import { LinkingControllerSingleton } from "Controllers";

interface IEditorMenuAIToolsCommandProps {
  editor: Editor;
  extraClassNames?: {
    editorMenuCommand?: string;
  };
  selectedCommand?: ICommand;
  isFromSlashMenu?: boolean;
}

export const EditorMenuAIToolsCommand: FC<IEditorMenuAIToolsCommandProps> = ({
  editor,
  extraClassNames = {},
  selectedCommand,
  isFromSlashMenu,
}: IEditorMenuAIToolsCommandProps) => {
  const { setAskIgorModalOptions, askIgorModalOptions, objectEdited } = useContext(EditorContext);

  const selectionNodeData = useSelectionNode({ editor });

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const commandOnClickAsync = useCallback(async (): Promise<void> => {
    if (!objectEdited || !editor) return;

    const selectedMenuItem = selectionNodeData?.selectedText
      ? AskIgorMenuItemEnum.Section
      : undefined;

    if (selectedMenuItem === AskIgorMenuItemEnum.Section) {
      const hasDocumentsOrHighlights =
        await LinkingControllerSingleton.hasLinksOfTypesAsync(objectEdited.id, [
          ObjectTypeEnum.Highlight,
          ...DocumentObjectTypeEnums,
        ]);

      if (!hasDocumentsOrHighlights) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS
        );
        return;
      }
    }

    const actionOptions = {
      nodePos: selectionNodeData ? selectionNodeData.fromNodePos : undefined,
      node: selectionNodeData ? selectionNodeData.fromNode : undefined,
      isFromSlashMenu,
    };

    ASK_IGOR_COMMAND.action(editor, actionOptions);

    let defaultInput: string = selectionNodeData?.selectedText ?? "";

    if (!defaultInput) {
      const headingText: string = EditorHelperSingleton.getHeadingText(
        editor,
        editor.$pos(editor.state.selection.$from.pos)
      );

      defaultInput = headingText ?? defaultInput;
    }

    if (askIgorModalOptions.isMinimized) {
      setAskIgorModalOptions((prevAskIgorModalOptions) => {
        return {
          ...prevAskIgorModalOptions,
          defaultInput,
          isMinimized: false,
        };
      });
    } else {
      setAskIgorModalOptions((prevAskIgorModalOptions) => {
        return {
          ...prevAskIgorModalOptions,
          selectedMenuItem: selectionNodeData?.selectedText
            ? AskIgorMenuItemEnum.Section
            : AskIgorMenuItemEnum.Answer,
          defaultInput,
          isOpen: true,
        };
      });
    }

  }, [
    editor,
    isFromSlashMenu,
    objectEdited,
    selectionNodeData,
    setAskIgorModalOptions,
    askIgorModalOptions
  ]);

  return (
    <button
      type="button"
      ref={buttonRef}
      onClick={commandOnClickAsync}
      className={`${styles.editorMenuCommand} ${styles.editorMenuAiToolsCommand
        } ${extraClassNames?.editorMenuCommand ?? ""} ${selectedCommand?.name === ASK_IGOR_COMMAND.name ? styles.selected : ""
        }`}
    >
      <div className={styles.iconContainer}>
        <FontAwesomeIcon icon={faMessageBot} />
      </div>
      <div className={styles.askIgorAiButton}>
        Ask IGOR<sup>AI</sup>
      </div>
    </button>
  );
};
