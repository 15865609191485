import {
  faArrowLeftLong,
  faArrowUpWideShort,
  faChevronDown,
  faChevronUp,
  faDownload,
  faRainbow,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  DocumentModal,
  ExportSearchResultsButton,
  FindestButton,
  LoadingStatusIndicator,
  Paginator,
  Popover,
  SearchProgressIndicator,
  SunburstChart,
} from "Components";
import { WebsocketFunctionNames } from "Constants";
import {
  ReadDocumentsControllerSingleton,
  SavedDocumentControllerSingleton,
} from "Controllers";
import {
  LogFeatureNameEnum,
  NotificationStatusEnum,
  NotificationTypeEnum,
  QueryResultViewTypeEnum,
  QuerySortOptionsEnum,
  QueryViewOptionsEnum,
  SearchQueryTypeEnum,
  SynonymTypeEnum,
  ToastTypeEnum,
} from "Enums";
import {
  LogHelperSingleton,
  QuerySortOptionsHelperSingleton,
  QueryViewOptionsHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
import { useClickOutsideRef, useDocument, useNotification, usePagination } from "Hooks";
import {
  fromIDocumentDetails,
  fromIDocumentSearchResult,
  IDocumentExtractedMetadata,
  IDocumentSearchResult,
  IQueryDTO,
  IQuerySearchingState,
  IDocumentDTO,
  ITechnologySearchResult,
  INotification,
} from "Interfaces";
import { QueryViewOptionsContext, WebsocketContext } from "Providers";
import {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  fromITechnologySearchResultsToTSunburstChart,
  TGroupedDocumentSearchResult,
  TGroupedDocumentSearchResults,
  TIdNameTypeObjectType,
  TMatchedSearchTerm,
  TPublicationsPerYearDTO,
  TQueryFiltersDTO,
  TReadDocumentDTO,
  TSearchTermHitsCountsDTO,
  TUniverseAffiliationResult,
  TUniverseDocumentSearchStrategyResult,
} from "Types";
import { ExecuteAQueryFirst } from "./ExecuteAQueryFirst/ExecuteAQueryFirst";
import { QuerySaveResults } from "./QuerySaveResults";
import { QueryAnswerResult } from "./QueryAnswerResult";
import styles from "./querySearchResults.module.scss";
import {
  AffiliationsGroupedDirectory,
  AffiliationsList,
  DocumentSearchResult,
  PublicationsPerYear,
} from "./SearchResults";
import { SearchMetadataBubble } from "./SearchResults/SearchMetadataBubble";
import { SortButton } from "./SortOptions/SortButton";
import { TechnologyOverviewWizard } from "./TechnologyOverviewWizard/TechnologyOverviewWizard";
import { TechnologySearchFlow } from "./TechnologySearchFlow/TechnologySearchFlow";
import { TechnologySearchResults } from "./TechnologySearchResults/TechnologySearchResults";
import { binaryPicker } from "Helpers/BinaryPicker";

export type TQuerySearchResultsProps = {
  query: IQueryDTO;
  querySearchingState: IQuerySearchingState;
  setQuerySearchingState: (
    newQuerySearchingState: IQuerySearchingState
  ) => void;
  searchResultAmount: number;
  pageNumber: number;
  onPaginate: (
    isToNextPage: boolean,
    alreadyPagedNames: string[],
    sortQuery?: string
  ) => void;
  setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>;
  isQueryShown: boolean;
  searchQueryType: SearchQueryTypeEnum;
  onSearchQueryTypeUpdate: (newSearchQueryType: SearchQueryTypeEnum) => void;
  setIsQueryShown: Dispatch<SetStateAction<boolean>>;
  onSearchClick: (
    searchQueryType: SearchQueryTypeEnum,
    showSearchResults: boolean,
    page: number,
    isGroupedSearchParam?: boolean,
    filteredResultNames?: string[],
    groupName?: string,
    sortQuery?: string,
    isTechnologySearchOnResults?: boolean,
    documents?: IDocumentSearchResult[]
  ) => void;
  setCorrelationId: (correlationId: string | undefined) => void;
  cancelSearch: () => void;
  updateQueryFilters: (queryFilters: TQueryFiltersDTO) => void;
  onNaturalLanguageQueryUpdateAsync: (naturalLanguageQuery: string) => Promise<void>;
  onSearchStarted: (
    correlationId: string,
    type: SearchQueryTypeEnum,
    isTechnologySearchOnResults?: boolean | null
  ) => void;
  focusOnTechSearchQueryTextArea: () => void;
  technologySearchResults: ITechnologySearchResult[];
  setTechnologySearchResults: Dispatch<
    SetStateAction<ITechnologySearchResult[]>
  >;
  documentResults: IDocumentSearchResult[];
  setDocumentResults: Dispatch<SetStateAction<IDocumentSearchResult[]>>;
  filteredDocumentResults: IDocumentSearchResult[];
  setFilteredDocumentResults: Dispatch<SetStateAction<IDocumentSearchResult[]>>;
  useChatResponse?: string;
  setUseChatResponse: Dispatch<SetStateAction<string | undefined>>;
  areReadResultsShown: boolean;
  setAreReadResultsShown: Dispatch<SetStateAction<boolean>>;
  onExecuteQueryClickAsync: (grouped?: boolean) => Promise<void>;
};

export const QuerySearchResults: FC<TQuerySearchResultsProps> = ({
  query,
  querySearchingState,
  setQuerySearchingState,
  pageNumber,
  searchResultAmount,
  onPaginate,
  setQuery,
  isQueryShown,
  searchQueryType,
  onSearchQueryTypeUpdate,
  setIsQueryShown,
  onSearchClick,
  setCorrelationId,
  cancelSearch,
  updateQueryFilters,
  onNaturalLanguageQueryUpdateAsync,
  onSearchStarted,
  focusOnTechSearchQueryTextArea,
  technologySearchResults,
  setTechnologySearchResults,
  documentResults,
  setDocumentResults,
  filteredDocumentResults,
  setFilteredDocumentResults,
  useChatResponse,
  setUseChatResponse,
  areReadResultsShown,
  setAreReadResultsShown,
  onExecuteQueryClickAsync,
}) => {

  const { webSocketController } = useContext(WebsocketContext);
  const { allQueryViewOptions, setSelectedViewOptions, selectedViewOptions } =
    useContext(QueryViewOptionsContext);

  // State
  const [groupedDocumentResults, setGroupedDocumentResults] = useState<
    TGroupedDocumentSearchResult[]
  >([]);
  const [publicationsPerYear, setPublicationsPerYear] =
    useState<TPublicationsPerYearDTO | undefined>(undefined);
  const [isViewOptionsDropdownShown, setIsViewOptionsDropdownShown] =
    useState<boolean>(false);
  const [alreadyPagedNames, setAlreadyPagedNames] = useState<string[]>([]);
  const [isScrollPositionTop, setIsScrollPositionTop] = useState<boolean>(true);
  const [affiliationSearchResults, setAffiliationSearchResults] =
    useState<TUniverseAffiliationResult | null>(null);
  const [title, setTitle] = useState<string>("Top 10 affiliations");
  const [queryResultView, setQueryResultView] =
    useState<QueryResultViewTypeEnum>(QueryResultViewTypeEnum.DocumentResult);
  const [selectedGroupName, setSelectedGroupName] = useState<string>("");
  const [isSortOptionsDropdownShown, setIsSortOptionsDropdownShown] =
    useState<boolean>(false);
  const [sort, setSort] = useState<string>(
    QuerySortOptionsHelperSingleton.getQuerySortOptionSearchValue(
      QuerySortOptionsEnum.Relevance
    )
  );
  const [querySearchSortTitle, setQuerySearchSortTitle] = useState<string>(
    QuerySortOptionsHelperSingleton.getQuerySortOptionDisplayValue(
      QuerySortOptionsEnum.Relevance
    )
  );
  const [isDocumentModalOpen, setIsDocumentModalOpen] =
    useState<boolean>(false);
  const [documentInModal, setDocumentInModal] =
    useState<
      | (IDocumentSearchResult & {
        extractedMetadata: IDocumentExtractedMetadata;
      })
      | undefined
    >(undefined);
  const [documentsSelected, setDocumentsSelected] = useState<
    IDocumentSearchResult[]
  >([]);
  const [
    selectedTechnologySearchResultItems,
    setSelectedTechnologySearchResultItems,
  ] = useState<string[]>([]);
  const [filteredTechnologySearchResults, setFilteredTechnologySearchResults] =
    useState<ITechnologySearchResult[]>([]);
  const [
    isTechnologySearchQueryOnResultsEditing,
    setIsTechnologySearchQueryOnResultsEditing,
  ] = useState<boolean>(false);
  const [referenceNumber, setReferenceNumber] = useState<
    { id: string; order: number }[]
  >([]);
  const [NLQDocumentsSortOption, setNLQDocumentsSortOption] =
    useState<QuerySortOptionsEnum>();
  const [documentsFromUseChatResponse, setDocumentsFromUseChatResponse] =
    useState<IDocumentSearchResult[]>([]);
  const [technologiesList, setTechnologiesList] = useState<ITechnologySearchResult[]>([]);

  // Ref
  const querySearchResultsViewOptionsContainer = useRef<HTMLDivElement>(null);
  const sortOptionsDropdownContainer = useRef<HTMLDivElement>(null);

  const {
    loadIsAlreadyReadOnDocumentResultsAsync,
    loadDocumentSearchResultMetadataAsync,
    sortDocumentByPublicationDate,
    onSaveElementClickAsync,
    sortDocuments,
  } = useDocument();

  // Constants
  // dropdown sort options
  const sortOptions = [
    QuerySortOptionsEnum.Relevance,
    QuerySortOptionsEnum.PublicationDate,
  ];

  if (
    searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles ||
    searchQueryType === SearchQueryTypeEnum.USEOnScience
  ) {
    sortOptions.push(QuerySortOptionsEnum.CitationScore);
  }

  const FOCUS_ON_TECH_SEARCH_QUERY_TEXT_AREA_MS_TIMEOUT = 2000;

  useEffect(() => {
    setQueryResultView(
      query.type === SearchQueryTypeEnum.UniverseTechnologies
        ? QueryResultViewTypeEnum.TechnologyResult
        : QueryResultViewTypeEnum.DocumentResult
    );
  }, [query.type]);

  useEffect(() => {
    if (
      querySearchingState.isRunningDocumentsSearch &&
      queryResultView === QueryResultViewTypeEnum.TechnologyResult
    ) {
      setQueryResultView(QueryResultViewTypeEnum.DocumentResult);
    }
  }, [querySearchingState.isRunningDocumentsSearch, queryResultView]);

  const isSearching = useMemo(() => {
    return (
      querySearchingState.isRunningDocumentsSearch ||
      querySearchingState.isRunningTechSearch ||
      querySearchingState.isRunningTechSearchOnResults
    );
  }, [
    querySearchingState.isRunningDocumentsSearch,
    querySearchingState.isRunningTechSearch,
    querySearchingState.isRunningTechSearchOnResults,
  ]);

  const shouldShowExecuteAQueryFirst = useMemo(() => {
    return (
      filteredDocumentResults.length < 1 &&
      technologySearchResults.length < 1 &&
      groupedDocumentResults.length < 1 &&
      !isSearching
    );
  }, [
    filteredDocumentResults.length,
    isSearching,
    technologySearchResults.length,
    groupedDocumentResults.length,
  ]);

  const isTOWizardEmpty = useMemo(() => {
    return documentResults.length > 0 && technologySearchResults.length === 0;
  }, [documentResults.length, technologySearchResults.length]);

  // Custom pagination hook
  const onPaginateResultsAsync = useCallback(
    async (isToNextPage: boolean): Promise<void> => {
      // Get the current page names
      let newAlreadyPagedNames = alreadyPagedNames;

      if (!isToNextPage) {
        // If we are going back, we need to drop the last paged names
        // If the amount of already paged names is a multiple of the search result amount, we need to drop the
        // search result amount, otherwise we need to drop the remainder
        const amountToDrop =
          alreadyPagedNames.length % searchResultAmount === 0
            ? searchResultAmount
            : alreadyPagedNames.length % searchResultAmount;
        newAlreadyPagedNames = newAlreadyPagedNames.slice(
          0,
          newAlreadyPagedNames.length - amountToDrop
        );
      } else {
        // If we are going forward, we need to add the new paged names
        newAlreadyPagedNames = [
          ...newAlreadyPagedNames,
          ...groupedDocumentResults.map(
            (groupedResult) => groupedResult.groupName
          ),
        ];
      }

      // Paginate and save the new paged names
      onPaginate(isToNextPage, newAlreadyPagedNames, sort);
      setAlreadyPagedNames(newAlreadyPagedNames);
    },
    [
      alreadyPagedNames,
      onPaginate,
      sort,
      searchResultAmount,
      groupedDocumentResults,
    ]
  );

  const {
    paginateToNextPageAsync,
    paginateToPreviousPageAsync,
    currentTotalCount,
    setTotalCount,
    currentPageNumber,
    isLastPage,
    paginationIndicator,
    setCurrentPageNumber,
    isLoading,
  } = usePagination(searchResultAmount, 0, onPaginateResultsAsync);
  // If the user presses the search button the page is reset to 1, so we need to reset the current page number
  useEffect(() => {
    if (pageNumber === 1) {
      setCurrentPageNumber(1);
    }
  }, [pageNumber, setCurrentPageNumber]);

  // Custom hooks
  useClickOutsideRef(querySearchResultsViewOptionsContainer, () => {
    setIsViewOptionsDropdownShown(false);
  });

  useClickOutsideRef(sortOptionsDropdownContainer, () => {
    setIsSortOptionsDropdownShown(false);
  });

  const resetQuerySearchingState = useCallback(() => {
    setQuerySearchingState({
      isRunningDocumentsSearch: false,
      isRunningTechSearch: false,
      isRunningTechSearchOnResults: false,
    });
  }, [setQuerySearchingState]);

  // Handlers
  const onReceiveDocumentSearchResults = useCallback(
    async (data: TUniverseDocumentSearchStrategyResult) => {
      setCorrelationId(undefined);
      if (!data.documentResults) {
        resetQuerySearchingState();
        return;
      }

      // set is already read on document results
      data.documentResults = await loadIsAlreadyReadOnDocumentResultsAsync(
        data.documentResults,
        await ReadDocumentsControllerSingleton.getMyAsync()
      );

      // set document results
      setDocumentResults(data.documentResults);
      // set filtered document results
      areReadResultsShown ? setFilteredDocumentResults(data.documentResults) : setFilteredDocumentResults(data.documentResults.filter((doc) => !doc.isAlreadyRead));

      resetQuerySearchingState();
    },
    [
      setCorrelationId,
      loadIsAlreadyReadOnDocumentResultsAsync,
      setDocumentResults,
      setFilteredDocumentResults,
      resetQuerySearchingState,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      areReadResultsShown
    ]
  );

  const onGetUseChatResponseDocuments = useCallback(
    (documents: IDocumentSearchResult[]) => {
      setDocumentsFromUseChatResponse((prev) => {
        const chatResponseDocuments: IDocumentSearchResult[] = [];
        documents.forEach((doc) => {
          if (
            !prev.some(
              (existingDoc) => existingDoc.documentId === doc.documentId
            )
          ) {
            chatResponseDocuments.unshift(doc);
          }
        });

        return [...chatResponseDocuments, ...prev];
      });
    },
    []
  );

  const documentsToExport = useMemo(() => {
    if (queryResultView !== QueryResultViewTypeEnum.TechnologyResult) {
      if (
        searchQueryType === SearchQueryTypeEnum.USEOnScience ||
        searchQueryType === SearchQueryTypeEnum.USEOnPatents
      ) {
        return [
          ...documentsFromUseChatResponse,
          ...filteredDocumentResults,
        ].reduce(
          (acc, doc) => {
            if (!acc.documentIds.has(doc.documentId)) {
              acc.documentIds.add(doc.documentId);
              acc.documents.push(doc);
            }
            return acc;
          },
          {
            documentIds: new Set<string>(),
            documents: [] as IDocumentSearchResult[],
          }
        ).documents;
      } else {
        return filteredDocumentResults;
      }
    } else {
      return filteredTechnologySearchResults
        .filter(
          (technologySearchResult) =>
            selectedTechnologySearchResultItems.includes(technologySearchResult.name)
        )
        .map((techResult) => techResult.documents)
        .flat();
    }
  }, [
    queryResultView,
    searchQueryType,
    filteredDocumentResults,
    documentsFromUseChatResponse,
    filteredTechnologySearchResults,
    selectedTechnologySearchResultItems,
  ]);

  const onReceiveGroupedSearchResults = useCallback(
    (data: TGroupedDocumentSearchResults) => {
      setCorrelationId(undefined);
      if (!data.groupedResults) {
        resetQuerySearchingState();
        return;
      }
      data.groupedResults.sort((a, b) => b.documentCount - a.documentCount);
      setGroupedDocumentResults(data.groupedResults);
      resetQuerySearchingState();
    },
    [resetQuerySearchingState, setCorrelationId]
  );

  useEffect(() => {
    if (
      querySearchingState.isRunningDocumentsSearch ||
      querySearchingState.isRunningTechSearch
    ) {
      setDocumentsFromUseChatResponse([]);
      setDocumentResults([]);
      setGroupedDocumentResults([]);
      setFilteredDocumentResults([]);
      setPublicationsPerYear(undefined);
      setAffiliationSearchResults(null);
      setTechnologySearchResults([]);
      setFilteredTechnologySearchResults([]);
      setTechnologiesList([]);
      setDocumentsSelected([]);
      setUseChatResponse(undefined);
    }
  }, [
    querySearchingState.isRunningDocumentsSearch,
    querySearchingState.isRunningTechSearch,
    setDocumentResults,
    setFilteredDocumentResults,
    setTechnologySearchResults,
    setUseChatResponse,
  ]);

  const updateDocument = (document: IDocumentSearchResult) => {
    const getDocumentSearchResult = (
      doc: IDocumentSearchResult,
      docId: string
    ) => {
      if (doc.documentId === docId) {
        return {
          ...document
        };
      }
      return {
        ...doc,
      };
    };

    const newDocumentResults = documentResults.map((doc) =>
      getDocumentSearchResult(doc, document.documentId)
    );

    setDocumentResults(newDocumentResults);

    const newFilteredDocumentResults = filteredDocumentResults.map((doc) =>
      getDocumentSearchResult(doc, document.documentId)
    );

    setFilteredDocumentResults(newFilteredDocumentResults);

    const getTechnologySearchResult = (
      techResult: ITechnologySearchResult,
      docId: string
    ) => {
      const newDocuments = techResult.documents.map((doc) => {
        if (doc.documentId === docId) {
          return {
            extractedMetadata: doc.extractedMetadata,
            ...document,
          };
        }
        return doc;
      });

      return {
        ...techResult,
        documents: newDocuments,
      };
    };

    const newTechnologySearchResults = technologySearchResults.map(
      (techResult) => getTechnologySearchResult(techResult, document.documentId)
    );

    setTechnologySearchResults(newTechnologySearchResults);

    const newFilteredTechnologySearchResults =
      filteredTechnologySearchResults.map((techResult) =>
        getTechnologySearchResult(techResult, document.documentId)
      );

    setFilteredTechnologySearchResults(newFilteredTechnologySearchResults);

    setTechnologiesList(newTechnologySearchResults);
  };

  const onReceiveDocumentCount = useCallback(
    (documentCount: number) => {
      setTotalCount(documentCount);
    },
    [setTotalCount]
  );

  const onReceiveSearchTermHitCounts = useCallback(
    (searchTermHitsCounts: TSearchTermHitsCountsDTO) => {
      // set search term hit counts on the query
      setQuery((prevQuery: IQueryDTO | undefined) => {
        // safety-checks
        if (!prevQuery) {
          return prevQuery;
        }
        // set the search term hit counts
        return {
          ...prevQuery,
          searchTermHitsCounts,
        };
      });
    },
    [setQuery]
  );

  const onReceiveAffiliationSearchResults = useCallback(
    (data: TUniverseAffiliationResult) => {
      setAffiliationSearchResults(data);
    },
    [setAffiliationSearchResults]
  );

  const onReceiveSearchId = useCallback(
    (searchId: string, isTechnologySearchOnResults?: boolean | null) => {
      setCorrelationId(searchId);
      onSearchStarted(searchId, searchQueryType, isTechnologySearchOnResults);
    },
    [onSearchStarted, searchQueryType, setCorrelationId]
  );

  const onReceivePublicationsPerYearSearchResults = useCallback(
    (data?: TPublicationsPerYearDTO) => {
      // set publications per year using the data
      setPublicationsPerYear(data);
    },
    [setPublicationsPerYear]
  );

  const filterDocumentResults = (doKeepReadResults: boolean) => {
    if (doKeepReadResults) {
      setFilteredDocumentResults(documentResults);
      setFilteredTechnologySearchResults(technologySearchResults);
      return;
    }

    setFilteredDocumentResults(
      documentResults.filter((doc) => !doc.isAlreadyRead)
    );
    setFilteredTechnologySearchResults(
      technologySearchResults.map((techResult) => {
        return {
          ...techResult,
          documents: techResult.documents.filter((doc) => !doc.isAlreadyRead),
        };
      })
    );
  };

  const getDocumentMatchedTerms = (
    document: IDocumentSearchResult & {
      extractedMetadata: IDocumentExtractedMetadata;
    }
  ) => {
    const matchedTerms: TMatchedSearchTerm[] = [];
    if (
      document.extractedMetadata?.igorHits &&
      document.extractedMetadata?.igorHits.length > 0
    ) {
      document.extractedMetadata?.igorHits.forEach((igorHit: string) => {
        matchedTerms.push({
          searchTerm: igorHit,
          termType: SynonymTypeEnum.Environment,
        });
      });
    }
    return matchedTerms;
  };

  const findDocumentInCurrentDocumentResults = (
    currentList: IDocumentSearchResult[],
    documentId: string
  ) => {
    return currentList.find((doc) => doc.documentId === documentId);
  };

  const onNotificationAsync = useCallback(async (notification: INotification) => {
    if (notification.type === NotificationTypeEnum.TechnologySearchAutomation && notification.status === NotificationStatusEnum.Progress) {
      ToastHelperSingleton.showToast(ToastTypeEnum.Success, "Technology list received.");
      const parsedResults = JSON.parse(notification.message);
      setTechnologiesList(parsedResults.results.children);
      setSelectedTechnologySearchResultItems(parsedResults.results.children.map((tech: { name: string }) => tech.name));
    }
  }, []);

  useNotification(onNotificationAsync);

  const onReceiveTechnologySearchResultsAsync = useCallback(
    async (newTechnologySearchResults: ITechnologySearchResult[]) => {

      setCorrelationId(undefined);

      setTechnologySearchResults(newTechnologySearchResults);

      setFilteredTechnologySearchResults(newTechnologySearchResults);

      setTechnologiesList(newTechnologySearchResults);

      const updatedTechnologySearchResults: {
        [key: string]: ITechnologySearchResult;
      } = {};

      const readDocuments: TReadDocumentDTO[] =
        await ReadDocumentsControllerSingleton.getMyAsync();

      await Promise.all(
        newTechnologySearchResults.map(async (techResult) => {
          let documentSearchResults =
            await loadDocumentSearchResultMetadataAsync(techResult.documents);

          documentSearchResults = await loadIsAlreadyReadOnDocumentResultsAsync(
            documentSearchResults,
            readDocuments
          );

          techResult.documents = techResult.documents
            .map((document) => {
              const documentSearchResult = findDocumentInCurrentDocumentResults(
                documentSearchResults,
                document.documentId
              );
              if (documentSearchResult) {
                const newDocument = {
                  extractedMetadata: document.extractedMetadata,
                  ...documentSearchResult,
                };

                if (newDocument.searchInformation) {
                  newDocument.searchInformation.matchedTerms =
                    getDocumentMatchedTerms(document);
                }

                return newDocument;
              } else {
                return document;
              }
            })
            .sort(sortDocumentByPublicationDate);

          updatedTechnologySearchResults[techResult.name] = techResult;
        })
      );

      const updatedNewTechnologySearchResults: ITechnologySearchResult[] =
        newTechnologySearchResults.map(
          (techResult) =>
            updatedTechnologySearchResults[techResult.name] || techResult
        );

      setTechnologySearchResults(updatedNewTechnologySearchResults);

      areReadResultsShown
        ? setFilteredTechnologySearchResults(updatedNewTechnologySearchResults)
        : setFilteredTechnologySearchResults(updatedNewTechnologySearchResults.map((techResult) => {
          return {
            ...techResult,
            documents: techResult.documents.filter((doc) => !doc.isAlreadyRead),
          };
        }));

      setTechnologiesList(updatedNewTechnologySearchResults);

      resetQuerySearchingState();

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loadDocumentSearchResultMetadataAsync,
      loadIsAlreadyReadOnDocumentResultsAsync,
      setCorrelationId,
      resetQuerySearchingState,
      setTechnologySearchResults,
      sortDocumentByPublicationDate,
    ]
  );

  useEffect(() => {
    const documentSearchResultsListenName = `${WebsocketFunctionNames.ReceiveDocumentSearchResults}-${query.guid}`;
    const documentCountListenName = `${WebsocketFunctionNames.ReceiveDocumentCount}-${query.guid}`;
    const groupedDocumentSearchResultsListenName = `${WebsocketFunctionNames.GroupedDocumentSearchResults}-${query.guid}`;
    const searchTermHitCountsListenName = `${WebsocketFunctionNames.ReceiveSearchTermHitCounts}-${query.guid}`;
    const receiveSearchIdListenName = `${WebsocketFunctionNames.ReceiveSearchId}-${query.guid}`;
    const receivePublicationsPerYearSearchResults = `${WebsocketFunctionNames.ReceivePublicationsPerYearSearchResults}-${query.guid}`;
    const affiliationSearchResultListenName = `${WebsocketFunctionNames.ReceiveAffiliationSearchResults}-${query.guid}`;
    const technologySearchResultsListenName = `${WebsocketFunctionNames.ReceiveTechnologySearchResults}-${query.guid}`;

    webSocketController.addHandler(
      documentSearchResultsListenName,
      onReceiveDocumentSearchResults
    );
    webSocketController.addHandler(
      documentCountListenName,
      onReceiveDocumentCount
    );
    webSocketController.addHandler(
      groupedDocumentSearchResultsListenName,
      onReceiveGroupedSearchResults
    );
    webSocketController.addHandler(
      searchTermHitCountsListenName,
      onReceiveSearchTermHitCounts
    );
    webSocketController.addHandler(
      receiveSearchIdListenName,
      onReceiveSearchId
    );
    webSocketController.addHandler(
      receivePublicationsPerYearSearchResults,
      onReceivePublicationsPerYearSearchResults
    );
    webSocketController.addHandler(
      affiliationSearchResultListenName,
      onReceiveAffiliationSearchResults
    );
    webSocketController.addHandler(
      technologySearchResultsListenName,
      onReceiveTechnologySearchResultsAsync
    );

    return () => {
      webSocketController.removeHandler(
        documentSearchResultsListenName,
        onReceiveDocumentSearchResults
      );
      webSocketController.removeHandler(
        documentCountListenName,
        onReceiveDocumentCount
      );
      webSocketController.removeHandler(
        groupedDocumentSearchResultsListenName,
        onReceiveGroupedSearchResults
      );
      webSocketController.removeHandler(
        searchTermHitCountsListenName,
        onReceiveSearchTermHitCounts
      );
      webSocketController.removeHandler(
        receiveSearchIdListenName,
        onReceiveSearchId
      );
      webSocketController.removeHandler(
        receivePublicationsPerYearSearchResults,
        onReceivePublicationsPerYearSearchResults
      );
      webSocketController.removeHandler(
        affiliationSearchResultListenName,
        onReceiveAffiliationSearchResults
      );
      webSocketController.removeHandler(
        technologySearchResultsListenName,
        onReceiveTechnologySearchResultsAsync
      );
    };
  }, [
    onReceiveDocumentCount,
    onReceiveDocumentSearchResults,
    onReceiveGroupedSearchResults,
    onReceiveAffiliationSearchResults,
    onReceivePublicationsPerYearSearchResults,
    onReceiveSearchId,
    onReceiveSearchTermHitCounts,
    query.guid,
    webSocketController,
    onReceiveTechnologySearchResultsAsync,
  ]);

  // when searchQueryType changes
  useEffect(() => {
    // set title based on search query type
    switch (searchQueryType) {
      case SearchQueryTypeEnum.UniverseScienceArticles:
        setTitle("Top 10 affiliations");
        break;
      case SearchQueryTypeEnum.UniversePatents:
        setTitle("Top 10 assignees");
        break;
      default:
        break;
    }
  }, [searchQueryType]);

  const toggleViewOptions = () => {
    setIsViewOptionsDropdownShown(!isViewOptionsDropdownShown);
  };

  const toggleSortOptions = () => {
    setIsSortOptionsDropdownShown(!isSortOptionsDropdownShown);
  };

  const sortBy = useCallback(
    (sortOption: QuerySortOptionsEnum) => {
      if (
        [
          SearchQueryTypeEnum.USEOnScience,
          SearchQueryTypeEnum.USEOnPatents,
        ].includes(searchQueryType)
      ) {
        setDocumentResults(sortDocuments(sortOption, documentResults));
        setFilteredDocumentResults(
          sortDocuments(sortOption, filteredDocumentResults)
        );
        setNLQDocumentsSortOption(sortOption);
      } else {
        onSearchClick(
          searchQueryType,
          true,
          pageNumber,
          undefined,
          undefined,
          undefined,
          QuerySortOptionsHelperSingleton.getQuerySortOptionSearchValue(
            sortOption
          )
        );
      }

      setSort(
        QuerySortOptionsHelperSingleton.getQuerySortOptionSearchValue(
          sortOption
        )
      );
      setIsSortOptionsDropdownShown(false);
      setQuerySearchSortTitle(
        QuerySortOptionsHelperSingleton.getQuerySortOptionDisplayValue(
          sortOption
        )
      );
    },
    [
      documentResults,
      filteredDocumentResults,
      onSearchClick,
      pageNumber,
      searchQueryType,
      setDocumentResults,
      setFilteredDocumentResults,
      sortDocuments,
    ]
  );

  const setViewOptions = useCallback(
    (isChecked: boolean, viewOption: QueryViewOptionsEnum) => {
      const newViewOptions = new Set(selectedViewOptions);
      if (isChecked) {
        newViewOptions.add(viewOption);
      } else {
        newViewOptions.delete(viewOption);
      }

      QueryViewOptionsHelperSingleton.saveActivatedViewOptions(
        Array.from(newViewOptions)
      );
      setSelectedViewOptions(newViewOptions);
    },
    [selectedViewOptions, setSelectedViewOptions]
  );

  const getNewScienceSearchQueryType = (
    currentSearchQueryType: SearchQueryTypeEnum
  ): SearchQueryTypeEnum => {
    if (currentSearchQueryType === SearchQueryTypeEnum.UniverseTechnologies) {
      return SearchQueryTypeEnum.USEOnScience;
    }

    const isIncludedType = [
      SearchQueryTypeEnum.USEOnScience,
      SearchQueryTypeEnum.USEOnPatents,
    ].includes(currentSearchQueryType);

    if (isIncludedType) {
      return SearchQueryTypeEnum.USEOnScience;
    }

    return SearchQueryTypeEnum.UniverseScienceArticles;
  };

  const getNewPatentsSearchQueryType = (currentSearchQueryType: SearchQueryTypeEnum): SearchQueryTypeEnum => {
    if (currentSearchQueryType === SearchQueryTypeEnum.UniverseTechnologies) {
      return SearchQueryTypeEnum.USEOnPatents;
    }

    const isIncludedType = [
      SearchQueryTypeEnum.USEOnScience,
      SearchQueryTypeEnum.USEOnPatents,
    ].includes(currentSearchQueryType);

    if (isIncludedType) {
      return SearchQueryTypeEnum.USEOnPatents;
    }

    return SearchQueryTypeEnum.UniversePatents;
  };

  const toggleSearchResultsDocumentType = (
    newSearchQueryType: SearchQueryTypeEnum
  ) => {
    if (
      (queryResultView === QueryResultViewTypeEnum.TechnologyResult
        || queryResultView === QueryResultViewTypeEnum.GroupedDocumentResult)
      && documentResults.length > 0) {
      setQueryResultView(QueryResultViewTypeEnum.DocumentResult);
      return;
    }

    const isUseSearch = [
      SearchQueryTypeEnum.USEOnScience,
      SearchQueryTypeEnum.USEOnPatents,
    ].includes(newSearchQueryType);

    setQueryResultView(QueryResultViewTypeEnum.DocumentResult);
    onSearchQueryTypeUpdate(newSearchQueryType);

    onSearchClick(
      newSearchQueryType,
      true,
      isUseSearch ? 1 : pageNumber,
      undefined,
      undefined,
      undefined,
      sort
    );
  };

  const showTechnologyOverviewResults = () => {
    setQueryResultView(QueryResultViewTypeEnum.TechnologyResult);
  };

  const showAllClicked = async () => {
    setQuerySearchingState({
      isRunningDocumentsSearch: true,
      isRunningTechSearch: false,
      isRunningTechSearchOnResults: false,
    });
    setQueryResultView(QueryResultViewTypeEnum.AffiliationResult);
    onSearchClick(
      searchQueryType,
      true,
      pageNumber,
      true,
      undefined,
      undefined,
      sort
    );
  };

  const scrollEvent = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollTop > 0) {
      setIsScrollPositionTop(false);
    } else {
      setIsScrollPositionTop(true);
    }
  };

  /** get query dates based on the search query type */
  const getQueryDates = (): { startDate: string; endDate: string } => {
    switch (searchQueryType) {
      case SearchQueryTypeEnum.UniverseScienceArticles:
        return {
          startDate: query.filters.scienceFilters.startDate ?? "",
          endDate: query.filters.scienceFilters.endDate ?? "",
        };
      case SearchQueryTypeEnum.UniversePatents:
        return {
          startDate: query.filters.patentFilters.startDate ?? "",
          endDate: query.filters.patentFilters.endDate ?? "",
        };
      default:
        return {
          startDate: "",
          endDate: "",
        };
    }
  };

  const onBackClicked = () => {
    if (queryResultView === QueryResultViewTypeEnum.GroupedDocumentResult) {
      setQueryResultView(QueryResultViewTypeEnum.AffiliationResult);
      showAllClicked();
    } else {
      setQueryResultView(QueryResultViewTypeEnum.DocumentResult);
      onSearchClick(searchQueryType, true, pageNumber);
    }
  };

  const onGroupNameClicked = async (groupName: string) => {
    //create an array of resultNames without selected
    setQuerySearchingState({
      isRunningDocumentsSearch: true,
      isRunningTechSearch: false,
      isRunningTechSearchOnResults: false,
    });
    setQueryResultView(QueryResultViewTypeEnum.GroupedDocumentResult);
    const resultNames = groupedDocumentResults?.map(
      (result) => result.groupName
    );
    const index = resultNames?.indexOf(groupName);
    const filteredResultNames = resultNames?.filter((name, i) => i !== index);
    setSelectedGroupName(groupName);
    const documentSearchResultsListenName = `${WebsocketFunctionNames.ReceiveGroupedDocuments}-${groupName}-${query.guid}`;
    await webSocketController.addHandler(
      documentSearchResultsListenName,
      onReceiveDocumentSearchResults
    );
    onSearchClick(
      searchQueryType,
      true,
      pageNumber,
      false,
      filteredResultNames,
      groupName,
      undefined
    );

    return () => {
      webSocketController.removeHandler(
        documentSearchResultsListenName,
        onReceiveDocumentSearchResults
      );
    };
  };

  const onDocumentSearchClick = () => {
    setIsQueryShown(true);
  };

  const onFirstResultsClick = () => {
    setQueryResultView(QueryResultViewTypeEnum.DocumentResult);
  };

  const onEditNaturalLanguageQueryClick = () => {
    if (documentResults.length > 0) {
      setQueryResultView(QueryResultViewTypeEnum.DocumentResult);
      setIsTechnologySearchQueryOnResultsEditing(true);
    } else {
      setIsQueryShown(true);
      setTimeout(() => {
        focusOnTechSearchQueryTextArea();
      }, FOCUS_ON_TECH_SEARCH_QUERY_TEXT_AREA_MS_TIMEOUT);
    }
  };

  const onTechnologyOverviewWizardSearchClick = (): void => {
    onSearchClick(
      SearchQueryTypeEnum.UniverseTechnologies,
      true,
      1,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      documentsToExport
    );
  };

  const openDocumentModal = useCallback(
    async (id: string) => {
      let relatedDocument = {} as IDocumentSearchResult & {
        extractedMetadata: IDocumentExtractedMetadata;
      };

      relatedDocument =
        technologySearchResults
          ?.flatMap((techResult) => techResult.documents)
          .find((document) => document.documentId === id) ?? relatedDocument;

      const documentWithImagesAndHighlights: IDocumentDTO | undefined =
        await SavedDocumentControllerSingleton.getByIdAsyncV2(id);

      if (documentWithImagesAndHighlights) {
        relatedDocument = {
          ...relatedDocument,
          highlights: documentWithImagesAndHighlights.highlights,
          images: documentWithImagesAndHighlights.images,
          createdByUsername: documentWithImagesAndHighlights.createdByUsername,
          dateAdded: documentWithImagesAndHighlights.dateAdded,
        };
      }

      const readDocument: TReadDocumentDTO | undefined =
        await ReadDocumentsControllerSingleton.addAsync(id);

      if (readDocument) {
        relatedDocument.isAlreadyRead = true;

        const getReadTechnologySearchResult = (
          techSearchResult: ITechnologySearchResult,
          docId: string
        ) => {
          const newDocuments = techSearchResult.documents.map((doc) => {
            if (doc.documentId === docId) {
              return {
                ...doc,
                isAlreadyRead: true,
              };
            }
            return doc;
          });
          return {
            ...techSearchResult,
            documents: newDocuments,
          };
        };

        const newTechnologySearchResults = technologySearchResults.map(
          (techResult) => getReadTechnologySearchResult(techResult, id)
        );

        setTechnologySearchResults(newTechnologySearchResults);

        const newFilteredTechnologySearchResults =
          filteredTechnologySearchResults.map((techResult) =>
            getReadTechnologySearchResult(techResult, id)
          );

        setFilteredTechnologySearchResults(newFilteredTechnologySearchResults);

        setTechnologiesList(newTechnologySearchResults);
      }

      setDocumentInModal(relatedDocument);

      // safety-checks
      if (!readDocument) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not add document to read documents."
        );
      }

      LogHelperSingleton.logWithProperties("OpenDocument", {
        DocumentId: id,
        ...(query ? { ActionOrigin: LogFeatureNameEnum.AdvancedSearch } : {}),
      });

      // set is modal open to true
      setIsDocumentModalOpen(true);
    },
    [
      filteredTechnologySearchResults,
      query,
      setTechnologySearchResults,
      technologySearchResults,
    ]
  );

  const onAddToSelected = (doc: IDocumentSearchResult) => {
    if (
      documentsSelected.some(
        (selectedDoc) => selectedDoc.documentId === doc.documentId
      )
    ) {
      setDocumentsSelected(
        documentsSelected.filter(
          (selectedDoc) => selectedDoc.documentId !== doc.documentId
        )
      );
    } else {
      setDocumentsSelected([...documentsSelected, doc]);
    }
  };

  const onEntityLinkToAll = (isChecked: boolean) => {
    if (isChecked) {
      setDocumentsSelected((prevSelected) => [
        ...prevSelected,
        ...getNewDocuments(prevSelected, filteredDocumentResults),
      ]);
      return;
    }

    setDocumentsSelected((prevSelected) =>
      removeFilteredDocuments(prevSelected, filteredDocumentResults)
    );
  };

  const getNewDocuments = (
    prevSelected: IDocumentSearchResult[],
    filteredDocResults: IDocumentSearchResult[]
  ) => {
    return filteredDocResults.filter(
      (doc) =>
        !prevSelected.some(
          (selectedDoc) => selectedDoc.documentId === doc.documentId
        )
    );
  };

  const removeFilteredDocuments = (
    prevSelected: IDocumentSearchResult[],
    filteredDocResults: IDocumentSearchResult[]
  ) => {
    return prevSelected.filter(
      (selectedDoc) =>
        !filteredDocResults.some(
          (doc) => doc.documentId === selectedDoc.documentId
        )
    );
  };

  const changeSelectedItem = (item: string, depth?: number) => {
    if (depth && depth === 2) {
      openDocumentModal(item);
      return;
    }
    if (item) {
      LogHelperSingleton.logWithProperties("ClickOnTechnology", {
        ActionOrigin: "SunburstChart",
      });
    }
  };

  const editNaturalLanguageQuery = () => {
    setIsTechnologySearchQueryOnResultsEditing(true);
  };

  return (
    <div
      className={[
        styles.querySearchResultsContainer,
        binaryPicker(isQueryShown, "", styles.active),
      ].join(" ")}
    >
      {queryResultView === QueryResultViewTypeEnum.AffiliationResult && (
        <div className={styles.queryAffiliationsResult}>
          <div className={styles.queryAffiliationHeader}>
            <FindestButton
              title="Back"
              buttonType="quarternary"
              extraClassName={styles.backButton}
              leftIconName={faArrowLeftLong}
              onClick={onBackClicked}
            />
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>Top Affiliations</h1>
              {!querySearchingState.isRunningDocumentsSearch && (
                <SearchMetadataBubble
                  text={groupedDocumentResults.length}
                  isSmall
                />
              )}
            </div>
          </div>
          <div className={styles.divider}></div>
          {querySearchingState.isRunningDocumentsSearch && (
            <SearchProgressIndicator
              queryResultView={queryResultView}
              cancelSearch={cancelSearch}
            />
          )}
          <AffiliationsGroupedDirectory
            groupedDocumentSearchResult={groupedDocumentResults}
            handleGroupedDocumentClick={onGroupNameClicked}
          />
        </div>
      )}
      {(queryResultView === QueryResultViewTypeEnum.TechnologyResult ||
        queryResultView === QueryResultViewTypeEnum.DocumentResult) && (
          <div
            className={[
              styles.querySearchResultsContentHeader,
              binaryPicker(isSearching, styles.isSearching, ""),
            ].join(" ")}
          >
            <div
              className={[
                styles.scrollingShadow,
                binaryPicker(isScrollPositionTop, "", styles.isScrolling),
              ].join(" ")}
            ></div>
            <div className={styles.querySearchResultsTypeTabs}>
              <button
                type="button"
                className={[
                  styles.querySearchResultsTypeTab,
                  binaryPicker(
                    (searchQueryType ===
                      SearchQueryTypeEnum.UniverseScienceArticles ||
                      searchQueryType === SearchQueryTypeEnum.USEOnScience) &&
                    queryResultView !==
                    QueryResultViewTypeEnum.TechnologyResult,
                    styles.active,
                    ""
                  ),
                ].join(" ")}
                onClick={() => {
                  toggleSearchResultsDocumentType(
                    getNewScienceSearchQueryType(searchQueryType)
                  );
                }}
              >
                <h4>Science</h4>
              </button>
              <button
                type="button"
                className={[
                  styles.querySearchResultsTypeTab,
                  binaryPicker(
                    (searchQueryType === SearchQueryTypeEnum.UniversePatents ||
                      searchQueryType === SearchQueryTypeEnum.USEOnPatents) &&
                    queryResultView !==
                    QueryResultViewTypeEnum.TechnologyResult,
                    styles.active,
                    ""
                  ),
                ].join(" ")}
                onClick={() => {
                  toggleSearchResultsDocumentType(
                    getNewPatentsSearchQueryType(searchQueryType)
                  );
                }}
              >
                <h4>Patents</h4>
              </button>
              <button
                type="button"
                className={[
                  styles.querySearchResultsTypeTab,
                  binaryPicker(
                    queryResultView === QueryResultViewTypeEnum.TechnologyResult,
                    styles.active,
                    ""
                  ),
                ].join(" ")}
                onClick={showTechnologyOverviewResults}
              >
                <h4>Technology overview</h4>
                {querySearchingState.isRunningTechSearch ||
                  (querySearchingState.isRunningTechSearchOnResults && (
                    <LoadingStatusIndicator
                      extraClassNames={{
                        container: styles.loadingIndicatorSmall,
                      }}
                      size={14}
                      status={1}
                    />
                  ))}
              </button>
            </div>
            <div className={styles.querySearchResultsContentOptions}>
              <ExportSearchResultsButton
                icon={faDownload}
                searchResults={documentsToExport}
                buttonType={"tertiary"}
                tooltipText={"Export search results to CSV"}
                filename={"search_results.csv"}
              />
              <div
                ref={querySearchResultsViewOptionsContainer}
                className={styles.querySearchResultsViewOptions}
              >
                <FindestButton
                  title="View options"
                  buttonType={"tertiary"}
                  rightIconName={binaryPicker(
                    isViewOptionsDropdownShown,
                    faChevronUp,
                    faChevronDown
                  )}
                  onClick={toggleViewOptions}
                />
                <Popover
                  referenceEl={querySearchResultsViewOptionsContainer.current}
                  isOpen={isViewOptionsDropdownShown}
                  placement="bottom-end"
                >
                  <div className={styles.querySearchResultsViewOptionsDropdown}>
                    <h6>General</h6>
                    <Checkbox
                      text="Matched terms"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isMatchedTermsViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.MatchedTerms
                        );
                      }}
                    />
                    <Checkbox
                      text="Relevance Score"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isRelevanceScoreViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.RelevanceScore
                        );
                      }}
                    />
                    <Checkbox
                      text="Publication date"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isPublicationDateViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.PublicationDate
                        );
                      }}
                    />
                    <Checkbox
                      text="Read results"
                      theme="textLabel"
                      isChecked={areReadResultsShown}
                      onCheckboxChange={(isChecked: boolean) => {
                        setAreReadResultsShown(isChecked);
                        filterDocumentResults(isChecked);
                      }}
                    />
                    <h6>Science</h6>
                    <Checkbox
                      text="Citation score"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isCitationScoreViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.CitationScore
                        );
                      }}
                    />
                    <Checkbox
                      text="Affiliation"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isAffiliationViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.Affiliation
                        );
                      }}
                    />
                    <Checkbox
                      text="Author"
                      theme="textLabel"
                      isChecked={allQueryViewOptions.isAuthorViewOptionChecked}
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(isChecked, QueryViewOptionsEnum.Author);
                      }}
                    />
                    <h6>Patents</h6>
                    <Checkbox
                      text="Filing date"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isFilingDateViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.FilingDate
                        );
                      }}
                    />
                    <Checkbox
                      text="Patent number"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isPatentNumberViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.PatentNumber
                        );
                      }}
                    />
                    <Checkbox
                      text="Patent country"
                      theme="textLabel"
                      isChecked={
                        allQueryViewOptions.isPatentCountryViewOptionChecked
                      }
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(
                          isChecked,
                          QueryViewOptionsEnum.PatentCountry
                        );
                      }}
                    />
                    <Checkbox
                      text="Assignee"
                      theme="textLabel"
                      isChecked={allQueryViewOptions.isAssigneeViewOptionChecked}
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(isChecked, QueryViewOptionsEnum.Assignee);
                      }}
                    />
                    <Checkbox
                      text="Inventor"
                      theme="textLabel"
                      isChecked={allQueryViewOptions.isInventorViewOptionChecked}
                      onCheckboxChange={(isChecked: boolean) => {
                        setViewOptions(isChecked, QueryViewOptionsEnum.Inventor);
                      }}
                    />
                  </div>
                </Popover>
              </div>
              {queryResultView !== QueryResultViewTypeEnum.TechnologyResult && (
                <>
                  <div
                    ref={sortOptionsDropdownContainer}
                    className={styles.querySearchResultSortBy}
                  >
                    <FindestButton
                      title={querySearchSortTitle}
                      leftIconName={faArrowUpWideShort}
                      rightIconName={binaryPicker(
                        isSortOptionsDropdownShown,
                        faChevronUp,
                        faChevronDown
                      )}
                      buttonType="tertiary"
                      onClick={toggleSortOptions}
                    />
                    <Popover
                      referenceEl={sortOptionsDropdownContainer.current}
                      isOpen={isSortOptionsDropdownShown}
                      placement="bottom-end"
                    >
                      <div>
                        <h6>Sort by</h6>
                        {sortOptions.map((sortOption) => (
                          <SortButton
                            key={sortOption}
                            sortOption={sortOption}
                            currentSort={sort}
                            onSortBy={sortBy}
                          />
                        ))}
                      </div>
                    </Popover>
                  </div>
                  {![
                    SearchQueryTypeEnum.USEOnScience,
                    SearchQueryTypeEnum.USEOnPatents,
                  ].includes(searchQueryType) && (
                      <Paginator
                        isPaginating={isLoading}
                        paginationIndicator={paginationIndicator}
                        currentPageNumber={currentPageNumber}
                        isLastPage={isLastPage}
                        paginateToNextPage={paginateToNextPageAsync}
                        paginateToPreviousPage={paginateToPreviousPageAsync}
                      />
                    )}
                </>
              )}
            </div>
          </div>
        )}
      {technologiesList.length > 0 &&
        queryResultView === QueryResultViewTypeEnum.TechnologyResult && (
          <TechnologySearchFlow
            query={query}
            isTechnologySearchOnResults={documentResults.length > 0}
            onDocumentSearchClick={onDocumentSearchClick}
            onFirstResultsClick={onFirstResultsClick}
            onEditNaturalLanguageQueryClick={onEditNaturalLanguageQueryClick}
          />
        )}
      {shouldShowExecuteAQueryFirst && (
        <ExecuteAQueryFirst
          queryResultView={queryResultView}
          type={searchQueryType}
          onSearchQueryTypeUpdate={onSearchQueryTypeUpdate}
          setIsQueryShown={setIsQueryShown}
        />
      )}
      {queryResultView === QueryResultViewTypeEnum.DocumentResult &&
        !shouldShowExecuteAQueryFirst && (
          <div className={styles.querySearchResultLayout}>
            <div
              className={styles.querySearchResultsContentContainer}
              onScroll={scrollEvent}
            >
              {querySearchingState.isRunningDocumentsSearch && (
                <SearchProgressIndicator
                  queryResultView={queryResultView}
                  cancelSearch={cancelSearch}
                />
              )}
              {useChatResponse && (
                <QueryAnswerResult
                  useChatResponse={useChatResponse}
                  query={query}
                  onAddUSEResultsOrder={setReferenceNumber}
                  sortOption={NLQDocumentsSortOption}
                  onGetUseChatResponseDocuments={onGetUseChatResponseDocuments}
                  fullArticlesResults={documentResults}
                />
              )}
              {filteredDocumentResults.length > 0 && (
                <>
                  {useChatResponse && (
                    <h4 className={styles.querySearchResultsContentTitle}>
                      Full articles results
                    </h4>
                  )}
                  <div className={styles.querySearchResultsContent}>
                    <QuerySaveResults
                      documentsSelected={documentsSelected}
                      filteredDocumentResults={filteredDocumentResults}
                      query={query}
                      documentCount={
                        [
                          SearchQueryTypeEnum.USEOnScience,
                          SearchQueryTypeEnum.USEOnPatents,
                        ].includes(searchQueryType)
                          ? filteredDocumentResults.length
                          : currentTotalCount
                      }
                      onEntityLinkToAll={onEntityLinkToAll}
                      onDeselectAllDocuments={() => setDocumentsSelected([])}
                    />
                    <div className={styles.querySearchResultsDocuments}>
                      {filteredDocumentResults.map((document) => {
                        return (
                          <DocumentSearchResult
                            checked={documentsSelected.some(
                              (selectedDoc) =>
                                selectedDoc.documentId === document.documentId
                            )}
                            key={document.documentId}
                            document={document}
                            doIncludeCheckbox={true}
                            updateDocument={updateDocument}
                            queryViewOptions={allQueryViewOptions}
                            onAddToSelected={onAddToSelected}
                            referenceNumber={
                              referenceNumber.find(
                                (order) => order.id === document.documentId
                              )?.order
                            }
                            hideZeroScoreMetadata={[
                              SearchQueryTypeEnum.USEOnScience,
                              SearchQueryTypeEnum.USEOnPatents,
                            ].includes(searchQueryType)}
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.querySearchResultsExtraContentContainer}>
              {documentResults.length > 0 &&
                (technologySearchResults.length === 0 ||
                  isTechnologySearchQueryOnResultsEditing ? (
                  <div className={styles.technologyOverviewWizardContainer}>
                    <TechnologyOverviewWizard
                      query={query}
                      isEditing={isTechnologySearchQueryOnResultsEditing}
                      setIsEditing={setIsTechnologySearchQueryOnResultsEditing}
                      isExpanded={isTechnologySearchQueryOnResultsEditing}
                      updateQueryFilters={updateQueryFilters}
                      querySearchingState={querySearchingState}
                      setQuerySearchingState={setQuerySearchingState}
                      onSearchClick={onTechnologyOverviewWizardSearchClick}
                      cancelSearch={cancelSearch}
                      onNaturalLanguageQueryUpdateAsync={onNaturalLanguageQueryUpdateAsync}
                    />
                  </div>
                ) : (
                  <div className={styles.chartContainer}>
                    <div className={styles.header}>
                      <FontAwesomeIcon icon={faRainbow} />
                      <h4 className={styles.technologyOverviewHeader}>
                        Technology overview
                      </h4>
                    </div>
                    <SunburstChart
                      data={fromITechnologySearchResultsToTSunburstChart(
                        technologySearchResults
                      )}
                      changeSelectedDataItems={changeSelectedItem}
                      extraClassNames={{ container: styles.chart }}
                    />
                    <div className={styles.wizardFooter}>
                      <FindestButton
                        title="Go to technology overview"
                        buttonType="secondary"
                        onClick={showTechnologyOverviewResults}
                      />
                      <FindestButton
                        title="Edit query"
                        buttonType="secondary"
                        onClick={editNaturalLanguageQuery}
                      />
                    </div>
                  </div>
                ))}
              <PublicationsPerYear
                startDate={getQueryDates().startDate}
                endDate={getQueryDates().endDate}
                publicationsPerYear={publicationsPerYear}
              />
              <AffiliationsList
                affiliationSearchResults={affiliationSearchResults}
                title={title}
                showAllClicked={showAllClicked}
              />
            </div>
          </div>
        )}
      {queryResultView === QueryResultViewTypeEnum.GroupedDocumentResult && (
        <div className={styles.queryAffiliationsResult}>
          <div className={styles.queryAffiliationHeader}>
            <FindestButton
              title="Back"
              buttonType="quarternary"
              extraClassName={styles.backButton}
              leftIconName={faArrowLeftLong}
              onClick={onBackClicked}
            />
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>{selectedGroupName}</h1>
              {!querySearchingState.isRunningDocumentsSearch && (
                <SearchMetadataBubble
                  text={`${filteredDocumentResults.length} ${binaryPicker(
                    filteredDocumentResults.length === 1,
                    "document",
                    "documents"
                  )}`}
                  isSmall
                />
              )}
            </div>
          </div>
          <div className={styles.querySearchResultLayout}>
            <div
              className={`${styles.querySearchResultsContentContainer} ${styles.groupedDocumentResults}`}
              onScroll={scrollEvent}
            >
              {querySearchingState.isRunningDocumentsSearch && (
                <SearchProgressIndicator
                  queryResultView={queryResultView}
                  cancelSearch={cancelSearch}
                />
              )}
              {filteredDocumentResults.map((document) => {
                return (
                  <DocumentSearchResult
                    key={document.documentId}
                    document={document}
                    doIncludeSaveButton={true}
                    updateDocument={updateDocument}
                    queryViewOptions={allQueryViewOptions}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {queryResultView === QueryResultViewTypeEnum.TechnologyResult &&
        !shouldShowExecuteAQueryFirst && (
          <div
            className={`${styles.technologySearchResults} ${binaryPicker(
              isTOWizardEmpty,
              styles.isEmpty,
              ""
            )}`}
          >
            {isTOWizardEmpty &&
              !querySearchingState.isRunningTechSearchOnResults && (
                <div className={styles.emptyTechnologyOverviewWizardContainer}>
                  <div className={styles.wizardContainer}>
                    <TechnologyOverviewWizard
                      query={query}
                      updateQueryFilters={updateQueryFilters}
                      isExpanded
                      querySearchingState={querySearchingState}
                      setQuerySearchingState={setQuerySearchingState}
                      onSearchClick={onTechnologyOverviewWizardSearchClick}
                      cancelSearch={cancelSearch}
                      onNaturalLanguageQueryUpdateAsync={onNaturalLanguageQueryUpdateAsync}
                    />
                  </div>
                </div>
              )}
            {technologiesList.length > 0 && (
              <TechnologySearchResults
                query={query}
                technologySearchResults={filteredTechnologySearchResults}
                openDocumentModal={openDocumentModal}
                updateDocument={updateDocument}
                allQueryViewOptions={allQueryViewOptions}
                selectedItems={selectedTechnologySearchResultItems}
                setSelectedItems={setSelectedTechnologySearchResultItems}
                technologiesList={technologiesList}
                onExecuteQueryClickAsync={onExecuteQueryClickAsync}
                isTechnologySearchOnResults={documentResults.length > 0}
              />
            )}
            {(querySearchingState.isRunningTechSearch || querySearchingState.isRunningTechSearchOnResults) && (
              <SearchProgressIndicator
                queryResultView={queryResultView}
                cancelSearch={() => {
                  cancelSearch();
                  setTechnologiesList([]);
                }}
              />
            )}
          </div>
        )}
      {documentInModal && (
        <DocumentModal
          isOpen={isDocumentModalOpen}
          setIsOpen={setIsDocumentModalOpen}
          document={fromIDocumentSearchResult(documentInModal)}
          updateDocument={(doc) => {
            updateDocument(fromIDocumentDetails(doc));
          }}
          onSaveElementClick={async (
            element: TIdNameTypeObjectType,
            closeSavePopupCallback?: (() => void) | undefined
          ) => {
            await onSaveElementClickAsync(
              element,
              documentInModal,
              updateDocument,
              query.guid
            );
            if (closeSavePopupCallback) {
              closeSavePopupCallback();
            }
          }}
          keywordsToHighlight={documentInModal.extractedMetadata.igorHits ?? []}
        />
      )}
    </div>
  );
};
