// node_modules
import { FC } from "react";
// Styles
import styles from "./documentGeneratedSummary.module.scss";

// Component props type
type TDocumentGeneratedSummaryProps = {
  summary?: string | null;
};

// Component
export const DocumentGeneratedSummary: FC<TDocumentGeneratedSummaryProps> = ({
  summary,
}) => {

  if (!summary) return <></>;

  // Render
  return (
    <div className={styles.documentGeneratedSummaryContainer}>
      <div className={styles.summary}>
        <h3>Generated summary</h3>
        <p>{summary}</p>
      </div>
    </div>
  );
};
