// node_modules
import {
  faAngleLeft,
  faAngleRight,
  faFilter
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
// Components
import { AskIgorButton, Checkbox, Filters, Popover } from "Components";
// Styles
import styles from "./listHeader.module.scss";
// Custom hooks
import { usePagination } from "Hooks";
// Enums
import { SortTypeEnum } from "Enums";
// Types
import { TButtonDefinition, TOption, TOptions } from "Types";
// Providers
import { EditorContext } from "Providers";

type TListHeaderProps<T> = {
  isAnyListItemSelected?: boolean;
  isAllListItemsSelected?: boolean;
  onSelectAllCheckboxChange?: (isChecked: boolean) => void;
  selectedFilterOptions?: TOption<T>[];
  filterOptions?: TOptions<T>[];
  updateFilterOptions?: (action: "add" | "remove", option: TOption<T>) => void;
  sortType?: string;
  updateSortType?: (newSortType: SortTypeEnum) => Promise<void>;
  totalListItemCount: number;
  onPaginatePrevious: () => Promise<void>;
  onPaginateNext: () => Promise<void>;
  listItemCountInterval: number;
  isObjectDetails?: boolean;
  openAskIgorModal?: () => void;
  buttonDefinitions?: TButtonDefinition[];
};

export function ListHeader<T>({
  onSelectAllCheckboxChange,
  isAnyListItemSelected,
  isAllListItemsSelected,
  selectedFilterOptions,
  updateFilterOptions,
  sortType,
  updateSortType,
  totalListItemCount,
  onPaginatePrevious,
  onPaginateNext,
  filterOptions,
  listItemCountInterval,
  isObjectDetails,
  openAskIgorModal,
  buttonDefinitions,
}: TListHeaderProps<T>) {
  // Logic
  const onPaginate = async (isNextPage: boolean): Promise<void> => {
    if (isNextPage) {
      await onPaginateNext();
    } else {
      await onPaginatePrevious();
    }
  };

  // Context
  const { isEditOn } = useContext(EditorContext);

  // State
  const [isSortPopupShown, setIsSortPopupShown] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  // Custom hooks
  const {
    paginationIndicator,
    paginateToNextPageAsync,
    paginateToPreviousPageAsync,
    currentPageNumber,
    isLastPage,
    isLoading,
    resetPagination,
    setTotalCount,
  } = usePagination(listItemCountInterval, totalListItemCount, onPaginate);

  useEffect(() => {
    setTotalCount(totalListItemCount);
  }, [setTotalCount, totalListItemCount]);

  const onAskIgorButtonClick = () => {
    openAskIgorModal?.();
  };

  // Render
  return (
    <div className={styles.savedDocumentListHeader}>
      <div className={styles.savedDocumentListHeaderLeft}>
        {onSelectAllCheckboxChange && (
          <Checkbox
            theme="black"
            isPartiallySelected={isAnyListItemSelected}
            isChecked={isAllListItemsSelected}
            onCheckboxChange={onSelectAllCheckboxChange}
          />
        )}
        {buttonDefinitions?.map((buttonDefinition) => {
          if (!buttonDefinition.icon) {
            return null;
          }

          return (
            <FontAwesomeIcon
              key={buttonDefinition.title}
              title={buttonDefinition.title}
              icon={buttonDefinition.icon}
              className={buttonDefinition.className}
              onClick={buttonDefinition.onClick}
            />
          );
        })}
        {isEditOn &&
          isObjectDetails &&
          (isAnyListItemSelected || isAllListItemsSelected) && (
            <AskIgorButton onClick={onAskIgorButtonClick} />
          )}
      </div>
      <div className={styles.savedDocumentListHeaderRight}>
        {filterOptions && selectedFilterOptions && updateFilterOptions && (
          <Filters
            title="Add Filter"
            leftIcon={faFilter}
            filterOptions={filterOptions}
            selectedFilterOptions={selectedFilterOptions}
            handleOptionSelect={(option) => {
              resetPagination();
              updateFilterOptions("add", option);
            }}
            handleOptionUnselect={(option) => {
              resetPagination();
              updateFilterOptions("remove", option);
            }}
            extraClassNames={{
              addFilterButtonContainer: styles.addFilterButtonContainer,
            }}
          />
        )}
        <div className={styles.countIndicatorContainer}>
          <div
            onMouseEnter={() => {
              setIsSortPopupShown(true);
            }}
            onMouseLeave={() => {
              setIsSortPopupShown(false);
            }}
            ref={setReferenceElement}
            className={styles.countIndicator}
          >
            {paginationIndicator}
          </div>
          {isSortPopupShown && updateSortType && sortType && (
            <Popover
              onMouseEnter={() => {
                setIsSortPopupShown(true);
              }}
              onMouseLeave={() => {
                setIsSortPopupShown(false);
              }}
              extraClassName={styles.listHeaderPopover}
              referenceEl={referenceElement}
            >
              <ul>
                <li
                  onClick={() => {
                    resetPagination();
                    if (updateSortType) updateSortType(SortTypeEnum.Newest);
                  }}
                  className={
                    sortType === SortTypeEnum.Newest ? styles.active : ""
                  }
                >
                  {SortTypeEnum.Newest.toString()}
                </li>
                <li
                  onClick={() => {
                    resetPagination();
                    if (updateSortType) updateSortType(SortTypeEnum.Oldest);
                  }}
                  className={
                    sortType === SortTypeEnum.Oldest ? styles.active : ""
                  }
                >
                  {SortTypeEnum.Oldest.toString()}
                </li>
              </ul>
            </Popover>
          )}
        </div>
        <div
          className={`${currentPageNumber === 1 || isLoading ? styles.disabled : ""
            } ${styles.arrow}`}
          onClick={paginateToPreviousPageAsync}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div
          className={`${isLastPage || isLoading ? styles.disabled : ""} ${styles.arrow
            }`}
          onClick={paginateToNextPageAsync}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
    </div>
  );
}
