import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { Checkbox, FindestButton, SunburstChart } from "Components";
import { LogFeatureNameEnum } from "Enums";
import {
  EntityHelperSingleton,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
} from "Helpers";
import {
  fromIDocumentDetails,
  fromIDocumentSearchResult,
  IDocumentDetails,
  IDocumentExtractedMetadata,
  IDocumentSearchResult,
  IEntityDTO,
  IQueryDTO,
  ITechnologySearchResult,
} from "Interfaces";
import {
  fromITechnologySearchResultsToTSunburstChart,
  TQueryViewOptions,
} from "Types";
import { DocumentSearchResult } from "../SearchResults";
import { ExtractedTechnology } from "../SearchResults/ExtractedTechnology/ExtractedTechnology";
import styles from "./technologySearchResults.module.scss";
import { QuerySaveResults } from "../QuerySaveResults";

type TTechnologySearchResultsProps = {
  query: IQueryDTO;
  technologySearchResults: ITechnologySearchResult[];
  openDocumentModal: (dataItem: string) => void;
  updateDocument: (document: IDocumentSearchResult) => void;
  allQueryViewOptions: TQueryViewOptions;
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  technologiesList: ITechnologySearchResult[];
  onExecuteQueryClickAsync: (grouped?: boolean) => Promise<void>;
  isTechnologySearchOnResults: boolean;
};

type TSelectedTechnologyDocumentsPairs = {
  technology: string;
  documents: (IDocumentSearchResult & {
    extractedMetadata: IDocumentExtractedMetadata;
  })[];
}[];

export const TechnologySearchResults: FC<TTechnologySearchResultsProps> = ({
  query,
  technologySearchResults,
  openDocumentModal,
  updateDocument,
  allQueryViewOptions,
  selectedItems,
  setSelectedItems,
  technologiesList,
  onExecuteQueryClickAsync,
  isTechnologySearchOnResults,
}: TTechnologySearchResultsProps) => {
  const [
    selectedTechnologyDocumentsPairs,
    setSelectedTechnologyDocumentsPairs,
  ] = useState<TSelectedTechnologyDocumentsPairs>([]);
  const [saveBulk, setSaveBulk] = useState<boolean | undefined>(undefined);

  const documentsCount = useMemo(
    () =>
      technologySearchResults.filter((technologySearchResult) =>
        selectedItems.includes(technologySearchResult.name)
      ).reduce(
        (acc, technologySearchResult) =>
          acc + technologySearchResult.documents.length,
        0
      ),
    [selectedItems, technologySearchResults]
  );

  const allDocuments = useMemo(
    () =>
      technologySearchResults
        .filter((technologySearchResult) =>
          selectedItems.includes(technologySearchResult.name)
        ).reduce(
          (acc, technologySearchResult) =>
            acc.concat(technologySearchResult.documents),
          [] as (IDocumentSearchResult & {
            extractedMetadata: IDocumentExtractedMetadata;
          })[]
        ),
    [technologySearchResults, selectedItems]
  );

  const onEntityLinkToAll = (
    isChecked: boolean,
    documents?: (IDocumentSearchResult & {
      extractedMetadata: IDocumentExtractedMetadata;
    })[]
  ) => {
    if (isChecked) {
      const allSelectedPairs = technologySearchResults.map((technology) => ({
        technology: technology.name,
        documents: technology.documents,
      }));
      let currentSelectedPairs = allSelectedPairs;
      if (documents && selectedItems) {
        currentSelectedPairs = [{ technology: selectedItems[0] || "", documents }];
      }
      setSelectedTechnologyDocumentsPairs(currentSelectedPairs);
      return;
    }
    setSelectedTechnologyDocumentsPairs([]);
  };

  const changeSelectedTechnology = (item: string, depth?: number) => {
    // If the user clicks on a document, open the document modal
    if (depth && depth === 2) {
      openDocumentModal(item);
      return;
    }

    // If the user clicks on a technology, update the selected item
    setSelectedItems((prevSelectedItem) => {
      if (prevSelectedItem.includes(item)) {
        return prevSelectedItem.filter((el) => el !== item);
      }
      return [...(prevSelectedItem || []), item];
    });
    setSelectedTechnologyDocumentsPairs([]);

    // Log the click on the technology
    if (item) {
      LogHelperSingleton.logWithProperties("ClickOnTechnology", {
        ActionOrigin: depth === 1 ? "SunburstChart" : "TechnologyList",
      });
    }
  };



  const addExtractedTechnologyAsEntityCallbackAsync = async (
    technologySearchResult: ITechnologySearchResult,
    createdEntity: IEntityDTO
  ): Promise<void> => {
    let selectedDocumentsInTechnology: (IDocumentSearchResult & {
      extractedMetadata: IDocumentExtractedMetadata;
    })[] = [];
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      selectedDocumentsInTechnology = selectedPair.documents;
    }

    if (selectedDocumentsInTechnology.length === 0) {
      selectedDocumentsInTechnology = technologySearchResult.documents;
    }

    for (const document of selectedDocumentsInTechnology) {
      await EntityHelperSingleton.addDocumentToEntityAsync(
        fromIDocumentSearchResult(document),
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          document.documentType
        ),
        createdEntity,
        {
          ActionOrigin: LogFeatureNameEnum.AdvancedSearch,
          QueryGuid: query.guid,
        },
        LogFeatureNameEnum.AdvancedSearch,
        (documentToUpdate: IDocumentDetails) => {
          updateDocument(fromIDocumentDetails(documentToUpdate));
        }
      );
    }
    setSelectedTechnologyDocumentsPairs([]);
  };

  const selectAllDocumentsInATechnology = (
    technologySearchResult: ITechnologySearchResult
  ) => {
    if (isAllDocumentsUnderTechnologySelected(technologySearchResult)) {
      setSelectedTechnologyDocumentsPairs(
        selectedTechnologyDocumentsPairs.filter(
          (pair) => pair.technology !== technologySearchResult.name
        )
      );
    } else if (isSomeDocumentsUnderTechnologySelected(technologySearchResult)) {
      setSelectedTechnologyDocumentsPairs(
        selectedTechnologyDocumentsPairs.map((pair) => {
          if (pair.technology === technologySearchResult.name) {
            return {
              technology: pair.technology,
              documents: technologySearchResult.documents,
            };
          }
          return pair;
        })
      );
    } else {
      setSelectedTechnologyDocumentsPairs([
        ...selectedTechnologyDocumentsPairs,
        {
          technology: technologySearchResult.name,
          documents: technologySearchResult.documents,
        },
      ]);
    }
  };

  const isAllDocumentsUnderTechnologySelected = (
    technologySearchResult: ITechnologySearchResult
  ) => {
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      return technologySearchResult.documents.every((document) =>
        selectedPair.documents.some(
          (doc) => doc.documentId === document.documentId
        )
      );
    }
    return false;
  };

  const isSomeDocumentsUnderTechnologySelected = (
    technologySearchResult: ITechnologySearchResult
  ) => {
    if (isAllDocumentsUnderTechnologySelected(technologySearchResult)) {
      return false;
    }
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      return technologySearchResult.documents.some((document) =>
        selectedPair.documents.some(
          (doc) => doc.documentId === document.documentId
        )
      );
    }
    return false;
  };

  const onAddOrRemoveDocumentFromSelected = (
    document: IDocumentSearchResult & {
      extractedMetadata: IDocumentExtractedMetadata;
    },
    technologySearchResult: ITechnologySearchResult
  ) => {
    const selectedPair = selectedTechnologyDocumentsPairs.find(
      (pair) => pair.technology === technologySearchResult.name
    );
    if (selectedPair) {
      if (
        selectedPair.documents.some(
          (doc) => doc.documentId === document.documentId
        )
      ) {
        // remove the document from the selectedTechnologyDocumentsPairs
        const updatedPairs = selectedTechnologyDocumentsPairs
          .map((pair) => {
            if (pair.technology === technologySearchResult.name) {
              const updatedDocuments = pair.documents.filter(
                (doc) => doc.documentId !== document.documentId
              );
              return {
                technology: pair.technology,
                documents: updatedDocuments,
              };
            }
            return pair;
          })
          .filter((pair) => pair.documents.length > 0); // remove the pair if no documents left

        setSelectedTechnologyDocumentsPairs(updatedPairs);
      } else {
        setSelectedTechnologyDocumentsPairs(
          selectedTechnologyDocumentsPairs.map((pair) => {
            if (pair.technology === technologySearchResult.name) {
              return {
                technology: pair.technology,
                documents: [...pair.documents, document],
              };
            }
            return pair;
          })
        );
      }
    } else {
      setSelectedTechnologyDocumentsPairs([
        ...selectedTechnologyDocumentsPairs,
        { technology: technologySearchResult.name, documents: [document] },
      ]);
    }
  };

  const isDocumentSelected = (
    selectedPairs: TSelectedTechnologyDocumentsPairs,
    technologyName: string,
    documentId: string
  ) => {
    return selectedPairs.some(
      (pair) =>
        pair.technology === technologyName &&
        pair.documents.some((doc) => doc.documentId === documentId)
    );
  };

  return (
    <div className={`${styles.container} ${technologySearchResults.length > 0 ? styles.fullWidth : ""
      }`}>
      <div className={styles.leftContainer}>
        <div className={styles.chartContainer}>
          <SunburstChart
            data={fromITechnologySearchResultsToTSunburstChart(
              technologiesList
            )}
            selectedDataItems={selectedItems}
            changeSelectedDataItems={changeSelectedTechnology}
          />
        </div>
        <div className={styles.entitiesList}>
          <div className={styles.entitiesListActions}>
            {isTechnologySearchOnResults && <FindestButton
              title="Group"
              buttonType="secondary"
              onClick={() => {
                onExecuteQueryClickAsync(true);
              }}
            />}
            <FindestButton
              title="Save"
              buttonType="secondary"
              onClick={() => setSaveBulk(saveBulk === undefined ? true : !saveBulk)}
            />
          </div>
          <button
            type="button"
            className={styles.showAll}
            onClick={() => setSelectedItems(technologiesList.map((child) => child.name))}
          >
            Show all
          </button>
          <div className={styles.entitiesListContainer}>
            {technologiesList.map((child) => (
              <div key={child.name} className={styles.entityItemContainer}>
                <Checkbox
                  theme="blue"
                  isChecked={selectedItems.includes(child.name)}
                  onCheckboxChange={() => changeSelectedTechnology(child.name)}
                />
                <button
                  type="button"
                  className={styles.entityItem}
                  onClick={() => changeSelectedTechnology(child.name)}
                >
                  {child.name}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      {allDocuments.length > 0 && <div className={styles.rightContainer}>
        <div>
          <QuerySaveResults
            filteredDocumentResults={allDocuments}
            documentCount={documentsCount}
            documentsSelected={selectedTechnologyDocumentsPairs
              .filter((pair) => selectedItems.includes(pair.technology))
              .map((pair) => pair.documents)
              .flat()}
            query={query}
            onEntityLinkToAll={onEntityLinkToAll}
            onDeselectAllDocuments={() => {
              setSelectedTechnologyDocumentsPairs([]);
            }}
            saveBulk={saveBulk}
          />
          {technologySearchResults
            .filter(
              (technologySearchResult) =>
                selectedItems.includes(technologySearchResult.name)
            )
            .map((technologySearchResult) => (
              <div
                key={technologySearchResult.name}
                className={styles.entityContainer}
              >
                <div className={styles.entityTitleContainer}>
                  <Checkbox
                    theme="black"
                    isPartiallySelected={isSomeDocumentsUnderTechnologySelected(
                      technologySearchResult
                    )}
                    isChecked={isAllDocumentsUnderTechnologySelected(
                      technologySearchResult
                    )}
                    onCheckboxChange={() =>
                      selectAllDocumentsInATechnology(technologySearchResult)
                    }
                  />
                  <ExtractedTechnology
                    query={query}
                    extractedTechnology={technologySearchResult.name}
                    addExtractedTechnologyAsEntityCallbackAsync={async (
                      createdEntity: IEntityDTO
                    ) => {
                      await addExtractedTechnologyAsEntityCallbackAsync(
                        technologySearchResult,
                        createdEntity
                      );
                    }}
                    extraClassNames={{ title: styles.technologyTitle }}
                  />
                </div>
                {technologySearchResult.explanation.length > 0 &&
                  <p className={styles.explanation}>{technologySearchResult.explanation}</p>
                }
                {technologySearchResult.documents.map((document) => (
                  <DocumentSearchResult
                    checked={isDocumentSelected(
                      selectedTechnologyDocumentsPairs,
                      technologySearchResult.name,
                      document.documentId
                    )}
                    key={document.documentId}
                    document={document}
                    updateDocument={updateDocument}
                    hideZeroScoreMetadata
                    queryViewOptions={allQueryViewOptions}
                    extractedMetadata={document.extractedMetadata}
                    doIncludeCheckbox
                    onAddToSelected={() =>
                      onAddOrRemoveDocumentFromSelected(
                        document,
                        technologySearchResult
                      )
                    }
                  />
                ))}
              </div>
            ))}
        </div>
      </div>}
    </div>
  );
};
