import { FC } from "react";
import {
    faMessageBot
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./askIgorButton.module.scss";

interface IAskIgorButtonProps {
    onClick: () => void;
}

export const AskIgorButton: FC<IAskIgorButtonProps> = ({
    onClick,
}: IAskIgorButtonProps) => {
    return (
        <div
            className={styles.container}
            onKeyDown={onClick}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faMessageBot} />
            <p>
                Ask IGOR<sup>AI</sup>
            </p>
        </div>
    );
};
