// node_modules
import { Editor } from "@tiptap/core";
import Link from "@tiptap/extension-link";
import { Mark, Node as TiptapNode } from "@tiptap/pm/model";
import { useEditor } from "@tiptap/react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Components
import {
  EditorContent,
  FindestButton,
  LoadingStatusIndicator,
  Modal,
  SaveToPage,
  Tabs
} from "Components";
import { AskIgorMenu } from "./AskIgorMenu/AskIgorMenu";
import { ExtractDetailInformation } from "./ExtractDetailInformation/ExtractDetailInformation";
// Styles
import styles from "./askIgorModal.module.scss";
// Enums
import {
  AskIgorMenuItemEnum,
  DocumentObjectTypeEnums,
  ObjectTypeEnum,
  OnboardingTaskNameEnum,
  ToastTypeEnum,
} from "Enums";
// Hooks
import { useAskIgor, useClickOutsideRef, useEntity, useObserveScrolling, useStudy } from "Hooks";
// Types
import {
  TIdNameTypeObjectType,
  TLogEventName,
  TTab,
} from "Types";
// Constants
import { AiConstants } from "Constants";
// Helpers
import { faInfoCircle, faLink, faLinkSlash, faWindowMinimize } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AskIgorMenuItemHelperSingleton,
  DocumentTypeHelperSingleton,
  ExtensionsKit,
  GetCustomLink,
  INSERT_CONTENT_COMMAND,
  LogHelperSingleton,
  SET_CONTENT_COMMAND,
  ToastHelperSingleton,
} from "Helpers";
// Interfaces
import { convertFromISavedDocumentDTO, IAskIgorModalOptions, IDocumentSearchResult, IQueryDTO, ISavedDocumentDTO } from "Interfaces";
// Controllers
import { UserOnboardingTasksControllerSingleton } from "Controllers";
// Providers
import { EditorContext } from "Providers";
interface IAskIgorModalProps {
  query?: IQueryDTO;
  editor?: Editor;
  object?: TIdNameTypeObjectType;
  options: IAskIgorModalOptions;
  setOptions: Dispatch<SetStateAction<IAskIgorModalOptions>>;
  selectedSavedDocuments: ISavedDocumentDTO[] | undefined;
}

export const AskIgorModal: FC<IAskIgorModalProps> = ({
  query,
  editor,
  object,
  options,
  setOptions,
  selectedSavedDocuments,
}: IAskIgorModalProps) => {
  const [documentTypes, setDocumentTypes] = useState<ObjectTypeEnum[]>([
    ObjectTypeEnum.MagPatent,
    ObjectTypeEnum.ScienceArticle,
    ObjectTypeEnum.UsPatent,
    ObjectTypeEnum.Weblink,
  ]);
  const [doIncludeSourceFullText, setDoIncludeSourceFullText] = useState<boolean>(true);
  const [documentsSelected, setDocumentsSelected] = useState<string[]>([]);
  const [noSources, setNoSources] = useState<boolean>(false);
  const [showTableBuilder, setShowTableBuilder] = useState<boolean>(false);
  const [linkedDocuments, setLinkedDocuments] =
    useState<ISavedDocumentDTO[] | undefined>(undefined);
  const [
    doCancelExtractDetailInformation,
    setDoCancelExtractDetailInformation,
  ] = useState<boolean>(false);
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [isSaveToPagePopupOpen, setIsSaveToPagePopupOpen] =
    useState<boolean>(false);
  const [referencedSources, setReferencedSources] = useState<IDocumentSearchResult[]>([]);

  // Ref
  const bodyRef = useRef<HTMLDivElement>(null);
  const didTriggerWriteSection = useRef<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const { setIsLinkingModalOpen, setIsObjectToDocumentLinkingModalOpen } =
    useContext(EditorContext);

  const navigate = useNavigate();
  const askIgorModalEditor = useEditor({
    extensions: [...ExtensionsKit, GetCustomLink(navigate, true)],
    editable: false,
  });
  const {
    correlationId,
    aiGeneratedExplanation,
    isGeneratingText,
    aiGeneratedText,
    onSubmitHandlerAsync: askIgorOnSubmitHandlerAsync,
    stopGeneration,
    setAIGeneratedText,
    setAIGeneratedExplanation,
    setIsGeneratingText,
  } = useAskIgor({
    askIgorMenuItem: options.selectedMenuItem,
    documentsSelected,
  });
  const observeScrolling = useObserveScrolling({
    ref: bodyRef,
    doStartObserving: isGeneratingText,
  });
  const { appendContentToEntityDescriptionAsync } = useEntity();
  const { appendContentToStudyDescriptionAsync } = useStudy();

  const generalDescriptionTabs = useMemo((): TTab[] => {
    const tabs: TTab[] = [
      { name: "General knowledge" },
      { name: "Linked sources" },
    ];

    return tabs;
  }, []);
  const isAIGeneratedTextEmpty = useMemo(
    () => !aiGeneratedText,
    [aiGeneratedText]
  );
  const textToShowWhileGenerating = useMemo(
    () =>
      "I am generating the answer, it could take a few minutes, please wait...",
    []
  );
  const doShowLoadingIndicator = useMemo(
    () => (isGeneratingText || options.selectedMenuItem === AskIgorMenuItemEnum.Description) && aiGeneratedText.length === 0,
    [isGeneratingText, aiGeneratedText, options.selectedMenuItem]
  );
  const doShowText = useMemo(
    () => aiGeneratedText.length > 0,
    [aiGeneratedText.length]
  );
  const didStreamErrorHappen = useMemo(
    () => aiGeneratedText.includes(AiConstants.ERROR_START_MESSAGE),
    [aiGeneratedText]
  );
  const aiGeneratedTextWithoutError = useMemo(
    () => aiGeneratedText.replace(AiConstants.ERROR_START_MESSAGE, ""),
    [aiGeneratedText]
  );

  useEffect(() => {
    if (options.isOpen && !options.isMinimized) {
      LogHelperSingleton.log("AskIgorModalOpened");
    }
  }, [options.isMinimized, options.isOpen]);

  useEffect(() => {
    if (!askIgorModalEditor) return;

    if (doShowText) {
      if (didStreamErrorHappen) {
        SET_CONTENT_COMMAND.action(askIgorModalEditor, {
          content: aiGeneratedTextWithoutError,
        });
      } else {
        SET_CONTENT_COMMAND.action(askIgorModalEditor, {
          content: aiGeneratedText,
        });
      }
    }
  }, [
    aiGeneratedTextWithoutError,
    aiGeneratedText,
    askIgorModalEditor,
    didStreamErrorHappen,
    doShowText,
  ]);

  useEffect(() => {
    if (!askIgorModalEditor) return;

    const referencedSourceUrls: string[] = [];

    askIgorModalEditor.state.doc.descendants((node: TiptapNode) => {
      const sourceLinkMark: Mark | undefined = node.marks.find(
        (mark: Mark) =>
          mark.type.name === Link.name &&
          mark.attrs.href
      );

      if (sourceLinkMark) {
        referencedSourceUrls.push(sourceLinkMark.attrs.href.trim());
      }
    });

    setReferencedSources(linkedDocuments
      ? linkedDocuments
        .filter((doc) => {
          const url = doc.url ? doc.url.trim() : "";
          const fullUrl = doc.fullUrl ? doc.fullUrl.trim() : "";

          return (url && referencedSourceUrls.includes(url)) ||
            (fullUrl && referencedSourceUrls.includes(fullUrl));
        })
        .map((doc) => convertFromISavedDocumentDTO(doc))
      : []);
  }, [aiGeneratedText, askIgorModalEditor, linkedDocuments]);

  const onClose = (): void => {
    didTriggerWriteSection.current = false;

    resetAskIgor();

    setOptions({
      defaultInput: undefined,
      selectedMenuItem: undefined,
      isOpen: false,
      isMinimized: false,
      documentsSelected: [],
    });

    observeScrolling.disconnectObserver();
  };

  const onMinimize = (): void => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      isMinimized: true,
    }));
  };

  useClickOutsideRef(modalRef, () => {
    if (modalRef.current && event && !modalRef.current.contains(event.target as Node)) {
      if (typeof (event.target as HTMLElement).className === "string" && (event.target as HTMLElement).className.includes("ReactModal__Overlay")) {
        onMinimize();
      }
    }
  });

  const resetAskIgor = useCallback(() => {
    stopGeneration(correlationId);

    setAIGeneratedText("");
    setAIGeneratedExplanation("");
    setShowExplanation(false);

    setIsGeneratingText(false);

    setDoCancelExtractDetailInformation(false);
  }, [correlationId, stopGeneration, setAIGeneratedText, setIsGeneratingText, setAIGeneratedExplanation]);

  const onSelectedItemUpdateHandler = useCallback(
    (newSelectedItem: AskIgorMenuItemEnum): void => {
      didTriggerWriteSection.current = true;
      resetAskIgor();
      setOptions({
        defaultInput: !options.selectedMenuItem ? options.defaultInput : "",
        selectedMenuItem: options.selectedMenuItem === AskIgorMenuItemEnum.Description ? AskIgorMenuItemEnum.Description : newSelectedItem,
        isOpen: true,
        isMinimized: false,
        documentsSelected,
      });
    },
    [documentsSelected, options.defaultInput, options.selectedMenuItem, resetAskIgor, setOptions]
  );

  const onSubmitHandlerAsync = async (
    item: AskIgorMenuItemEnum,
    text: string,
  ): Promise<void> => {
    onSelectedItemUpdateHandler(item);

    await askIgorOnSubmitHandlerAsync(
      item,
      text,
      documentTypes,
      doIncludeSourceFullText,
      object,
    );
  };

  const onAcceptClickHandler = async (
    currentAIGeneratedText: string
  ): Promise<void> => {
    if (!currentAIGeneratedText || !options.selectedMenuItem || !editor) return;

    await UserOnboardingTasksControllerSingleton.completeUserOnboardingTaskAsync(
      OnboardingTaskNameEnum.GenerateAskIgorContent
    );

    const insertResultLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedInsertResultLogEventName(
        options.selectedMenuItem
      );

    LogHelperSingleton.log(insertResultLogEventName);

    INSERT_CONTENT_COMMAND.action(editor, {
      content: askIgorModalEditor?.getJSON(),
    });

    onMinimize();
  };

  const onClearHandler = (): void => {
    setAIGeneratedText("");
    setAIGeneratedExplanation("");
    setShowTableBuilder(false);
    if (options.selectedMenuItem === AskIgorMenuItemEnum.Description) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        selectedMenuItem: AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge,
      }));
    }
  };

  const onCancelHandler = (): void => {
    stopGeneration(correlationId);
    setIsGeneratingText(false);
    setDoCancelExtractDetailInformation(true);
    let newSelectedMenuItem: AskIgorMenuItemEnum | undefined;
    if (options.selectedMenuItem === AskIgorMenuItemEnum.InformationExtraction) {
      newSelectedMenuItem = AskIgorMenuItemEnum.InformationExtraction;
    } else if (
      options.selectedMenuItem === AskIgorMenuItemEnum.Description ||
      options.selectedMenuItem === AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge ||
      options.selectedMenuItem === AskIgorMenuItemEnum.DescriptionFromLinkedSources
    ) {
      newSelectedMenuItem = AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge;
    } else {
      newSelectedMenuItem = undefined;
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      selectedMenuItem: newSelectedMenuItem,
    }));
  };

  // TODO: find a better way to only trigger this once without using the hacky didTriggerWriteSection ref
  useEffect(() => {
    if (
      object &&
      options.isOpen &&
      options.defaultInput &&
      options.selectedMenuItem === AskIgorMenuItemEnum.Section &&
      !didTriggerWriteSection.current
    ) {
      didTriggerWriteSection.current = true;

      const documentTypesForWriteSection =
        selectedSavedDocuments && selectedSavedDocuments.length > 0
          ? DocumentTypeHelperSingleton.getObjectDocumentTypesFromSavedDocuments(
            selectedSavedDocuments
          )
          : DocumentObjectTypeEnums;

      askIgorOnSubmitHandlerAsync(
        options.selectedMenuItem,
        options.defaultInput,
        documentTypesForWriteSection,
        doIncludeSourceFullText,
        object,
      );
    }
  }, [
    askIgorOnSubmitHandlerAsync,
    documentTypes,
    doIncludeSourceFullText,
    isGeneratingText,
    object,
    options.defaultInput,
    options.isOpen,
    options.selectedMenuItem,
    setOptions,
    selectedSavedDocuments,
  ]);

  useEffect(() => {
    if (options.selectedMenuItem === AskIgorMenuItemEnum.Description) {
      onSubmitHandlerAsync(
        AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge,
        ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.selectedMenuItem]);

  useEffect(() => {
    if (doShowText && options.selectedMenuItem === AskIgorMenuItemEnum.InformationExtraction) {
      setAIGeneratedExplanation(showTableBuilder ? "" : "The token size is calculated per source. If a source exceeds a certain limit we have to use a different method in order for it to make it work. You can see this information per document in the table. This information will not be inserted with the table in your document.");
    }
  }, [doShowText, options.selectedMenuItem, setAIGeneratedExplanation, showTableBuilder]);

  const onCopyAnswerAsync = async (): Promise<void> => {
    await navigator.clipboard.writeText(askIgorModalEditor?.state.doc.textContent || "");
    ToastHelperSingleton
      .showToast(ToastTypeEnum.Success, "Answer copied to clipboard");
  };

  const onDocumentsLinkedAsync = async (toObject: TIdNameTypeObjectType): Promise<void> => {
    const content = askIgorModalEditor?.getJSON();
    switch (toObject.objectType) {
      case ObjectTypeEnum.Entity:
        if (!await appendContentToEntityDescriptionAsync(toObject.id, content?.content ?? [])) {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Could not append answer to entity."
          );
        } else {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Success,
            `Inserted content to ${toObject.title}.`
          );
        }
        break;
      case ObjectTypeEnum.Study:
        if (!await appendContentToStudyDescriptionAsync(toObject.id, content?.content ?? [])) {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Could not append answer to study."
          );
        } else {
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Success,
            `Inserted content to ${toObject.title}.`
          );
        }
        break;
    }
  };

  return (
    <Modal
      isOpen={options.isOpen}
      extraClassNames={{
        overlay: `${styles.overlay} ${options.isMinimized ? styles.hidden : ""
          }`,
        container: styles.container,
        header: styles.header,
        minimizeButton: styles.minimizeButton,
        closeButton: styles.closeButton,
      }}
      onClose={onClose}
      onMinimize={onMinimize}
      shouldCloseOnOverlayClick={false}
    >
      <div
        className={styles.modalContainer}
        ref={modalRef}
      >
        <div className={styles.leftSection}>
          {/** Guillaume: Re-render Ask Igor menu when the modal is opened or minimized to ensure correct document selection. 
              This approach isn't ideal, but it's a temporary solution due to code complexity and time constraints.
          **/}
          {!options.isMinimized && (
            <AskIgorMenu
              object={object}
              selectedSavedDocumentsFromList={selectedSavedDocuments}
              selectedDocumentsOfIgor={documentsSelected}
              defaultInput={options.defaultInput}
              selectedItem={options.selectedMenuItem}
              onDocumentTypesChange={setDocumentTypes}
              doIncludeSourceFullText={doIncludeSourceFullText}
              setDoIncludeSourceFullText={setDoIncludeSourceFullText}
              onSelectedItemUpdate={onSelectedItemUpdateHandler}
              onSubmit={onSubmitHandlerAsync}
              loading={isGeneratingText}
              onEmptyDocumentList={() => {
                setNoSources(true);
              }}
              onSetAskIgorModalOptions={setDocumentsSelected}
              isGeneratingText={isGeneratingText}
              onFetchLinkedDocuments={(
                documents: ISavedDocumentDTO[] | undefined
              ) => {
                setLinkedDocuments(documents);
              }}
            />
          )}
        </div>
        <div className={styles.rightSection}>
          {noSources && !doShowLoadingIndicator && !doShowText ? (
            <div className={styles.noSourcesContainer}>
              <FontAwesomeIcon icon={faLinkSlash} />
              <h4>No sources linked to this page</h4>
              <p>
                To use Ask IGOR to its full potential, you have to connect
                documents and/or highlights to this page. IGOR needs these
                sources in order to generate an answer or extract information
                from that is trustworthy.
              </p>
              <p>
                For now, you will only be able to generate a General description
                based on general knowledge, but be mindful to double check its
                result.
              </p>
              <FindestButton
                title="Link documents"
                leftIconName={faLink}
                buttonType="secondary"
                onClick={() => {
                  onMinimize();
                  setIsObjectToDocumentLinkingModalOpen(true);
                  setIsLinkingModalOpen(true);
                }}
              />
            </div>
          ) : null}
          <div
            className={styles.body}
            ref={bodyRef}
            onScroll={observeScrolling.onScroll}
          >
            {!noSources &&
              (options.selectedMenuItem ===
                AskIgorMenuItemEnum.Description ||
                options.selectedMenuItem ===
                AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge ||
                options.selectedMenuItem ===
                AskIgorMenuItemEnum.DescriptionFromLinkedSources) && (
                <Tabs
                  tabs={generalDescriptionTabs}
                  theme="blue"
                  extraClassNames={{
                    disabled:
                      isGeneratingText && !didStreamErrorHappen
                        ? styles.disabled
                        : "",
                  }}
                  defaultSelectedTab={generalDescriptionTabs[0].name}
                  onSelectedTabChange={(tab: string) => {
                    onSubmitHandlerAsync(
                      tab === "General knowledge"
                        ? AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge
                        : AskIgorMenuItemEnum.DescriptionFromLinkedSources,
                      ""
                    );
                  }}
                />
              )}
            {options.selectedMenuItem ===
              AskIgorMenuItemEnum.InformationExtraction && (
                <ExtractDetailInformation
                  aboutObject={object}
                  documentsSelected={documentsSelected}
                  doIncludeSourceFullText={doIncludeSourceFullText}
                  aiGeneratedText={aiGeneratedText}
                  setAIGeneratedText={setAIGeneratedText}
                  linkedDocuments={linkedDocuments}
                  setIsGeneratingText={setIsGeneratingText}
                  doCancel={doCancelExtractDetailInformation}
                  setDoCancel={setDoCancelExtractDetailInformation}
                  showTableBuilder={showTableBuilder}
                  setShowTableBuilder={setShowTableBuilder}
                />
              )}
            {doShowLoadingIndicator && (
              <div className={styles.loadingIndicatorContainer}>
                <LoadingStatusIndicator size={40} status={1} />
                {textToShowWhileGenerating &&
                  textToShowWhileGenerating.trim().length > 0 && (
                    <p>{textToShowWhileGenerating}</p>
                  )}
              </div>
            )}
            {doShowText && options.selectedMenuItem !== AskIgorMenuItemEnum.InformationExtraction && (
              <div
                className={`${styles.generatedContentContainer} ${showTableBuilder ? styles.displayNone : ""}`}
              >
                {didStreamErrorHappen ? (
                  <div
                    className={`${styles.errorMessage} ${styles.generatedContent}`}
                  >
                    <h3>Error</h3>
                    <EditorContent
                      className="compactEditorContent"
                      editor={askIgorModalEditor}
                    />
                  </div>
                ) : (
                  <EditorContent
                    editor={askIgorModalEditor}
                    className={`${styles.generatedContent} ${options.selectedMenuItem ===
                      AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge ||
                      options.selectedMenuItem ===
                      AskIgorMenuItemEnum.DescriptionFromLinkedSources ||
                      options.selectedMenuItem ===
                      AskIgorMenuItemEnum.Description
                      ? styles.hasTabs
                      : ""
                      }`}
                    savedDocuments={linkedDocuments}
                  />
                )}
                <EditorContent
                  editor={askIgorModalEditor}
                  savedDocuments={linkedDocuments}
                />
              </div>
            )}
          </div>
          {aiGeneratedExplanation.length > 0 && !didStreamErrorHappen && <div className={`${styles.explanation} ${showExplanation ? styles.active : ""}`}>
            <button type="button" className={styles.explanationQuestion} onClick={() => !showExplanation && setShowExplanation(true)}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <p>{options.selectedMenuItem ===
                AskIgorMenuItemEnum.InformationExtraction
                ? "How were these answers generated?"
                : "How was this answer generated?"}
              </p>
              {showExplanation && <FontAwesomeIcon icon={faWindowMinimize} onClick={() => setShowExplanation(false)} />}
            </button>
            {showExplanation && <div className={styles.explanationAnswer}>
              <p>{aiGeneratedExplanation}</p>
              <a href={process.env.REACT_APP_FINDEST_DOCS_URL} target="_blank" rel="noopener noreferrer">Read more</a>
            </div>}
          </div>}
          {!didStreamErrorHappen &&
            ((!isGeneratingText && !isAIGeneratedTextEmpty) ||
              isGeneratingText) ? (
            <div className={styles.footer}>
              {!isGeneratingText &&
                !isAIGeneratedTextEmpty &&
                !showTableBuilder && (
                  <>
                    {object ?
                      <FindestButton
                        title={
                          options.selectedMenuItem ===
                            AskIgorMenuItemEnum.InformationExtraction
                            ? "Insert table"
                            : "Insert"
                        }
                        onClick={() => {
                          onAcceptClickHandler(aiGeneratedText);
                        }}
                        extraClassName={styles.acceptButton}
                      />
                      : <>
                        <FindestButton
                          title={"Insert in page"}
                          onClick={() => setIsSaveToPagePopupOpen(true)}
                        />
                        <FindestButton
                          title={"Copy answer"}
                          buttonType="secondary"
                          onClick={onCopyAnswerAsync}
                        />
                      </>
                    }
                    {options.selectedMenuItem ===
                      AskIgorMenuItemEnum.InformationExtraction && (
                        <FindestButton
                          title={"Edit table"}
                          buttonType="secondary"
                          onClick={() => {
                            setShowTableBuilder(true);
                          }}
                        />
                      )}
                    <FindestButton
                      title="Clear"
                      buttonType="secondary"
                      onClick={onClearHandler}
                    />
                  </>
                )}
              {isGeneratingText && (
                <FindestButton
                  buttonType="secondary"
                  title="Cancel"
                  onClick={onCancelHandler}
                  extraClassName={styles.cancelButton}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
      {query && (
        <SaveToPage
          isSavePopupOpen={isSaveToPagePopupOpen}
          setIsSavePopupOpen={setIsSaveToPagePopupOpen}
          query={query}
          onDocumentsLinkedAsync={onDocumentsLinkedAsync}
          documentsSelected={referencedSources} />
      )}
    </Modal>
  );
};