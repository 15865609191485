// node_modules
import debounce from "lodash.debounce";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components
import { DocumentView } from "Components";
// Constants
import { EventConstants } from "Constants";
// Controllers
import { DocumentControllerSingleton } from "Controllers";
// Types
import {
  THighlightDTO,
  TIdNameTypeObjectType,
  TImageDTO,
  TUseFetch,
} from "Types";
// Enums
import { IndexingStatusEnum, ObjectTypeEnum, SavedDocumentTypeEnum, ToastTypeEnum } from "Enums";
// Helpers
import {
  ConnectedObjectsHelperSingleton,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";
// Custom hooks
import { useFetch } from "Hooks";
// Contexts
import {
  AuthContext,
  ElementVisibilityContext,
  PubSubContext,
} from "Providers";
// Interfaces
import { IDocumentDTO, fromIDocumentDTO } from "Interfaces";

export const DocumentDetails: FC = () => {
  // Hooks
  const { documentId } = useParams();
  const navigate = useNavigate();

  // Context
  const { isUserExternal } = useContext(AuthContext);
  const { canUserEdit } = useContext(ElementVisibilityContext);
  const { pubSubHandler } = useContext(PubSubContext);

  // State
  const [currentDocument, setCurrentDocument] =
    useState<IDocumentDTO | null>(null);

  // Retrieve document information
  const { fetchedData: fetchedDocument }: TUseFetch<IDocumentDTO> = useFetch(
    `api/v2/saved-sources/${documentId}`
  );

  useEffect(() => {
    if (fetchedDocument) {
      setCurrentDocument(fetchedDocument);
    }
  }, [fetchedDocument]);

  // Memos
  const isLocalFile = useMemo(() => {
    if (!currentDocument || (!currentDocument.fullUrl && !currentDocument.url)) {
      return false;
    }
    const currentUrl = currentDocument.fullUrl
      ? currentDocument.fullUrl
      : currentDocument.url;
    return currentUrl.includes("file://");
  }, [currentDocument]);

  const doShowGoToUrlButton = useMemo(() => {
    return (
      !!(fetchedDocument && (fetchedDocument.fullUrl || fetchedDocument.url)) &&
      !(isLocalFile && isUserExternal)
    );
  }, [fetchedDocument, isLocalFile, isUserExternal]);

  const updateHighlights = useCallback(
    async (newHighlights: THighlightDTO[]) => {
      // If the user is readonly then do nothing
      if (!canUserEdit || !currentDocument) return;
      // Update the current document with new highlights
      setCurrentDocument({
        ...currentDocument,
        linkedObjectCounts: {
          ...currentDocument.linkedObjectCounts,
          highlightCount: newHighlights.length,
        },
        highlights: [...newHighlights],
      });
    },
    [currentDocument, canUserEdit]
  );

  const handleNewDocumentTitleAsync = useCallback(
    async (newTitle: string): Promise<void> => {
      if (
        !currentDocument ||
        currentDocument.savedDocumentType !== SavedDocumentTypeEnum.Weblink
      ) {
        return;
      }

      setCurrentDocument({
        ...currentDocument,
        title: newTitle,
      });

      await DocumentControllerSingleton.updateDocumentTitle(
        currentDocument.id,
        newTitle
      );
    },
    [currentDocument]
  );

  // debounce the handleNewDocumentTitleAsync function
  const debouncedHandleNewDocumentTitleAsync = useMemo(
    () =>
      debounce(
        handleNewDocumentTitleAsync,
        EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY
      ),
    [handleNewDocumentTitleAsync]
  );

  if (!documentId) {
    navigate("/library/documents/");
    return null;
  }

  if (!currentDocument) return <></>;

  const onAddImage = (newImage: TImageDTO) => {
    // If the image was added successfully then add it to the current document
    setCurrentDocument({
      ...currentDocument,
      linkedObjectCounts: {
        ...currentDocument.linkedObjectCounts,
        imageCount: currentDocument.linkedObjectCounts.imageCount + 1,
      },
      images: [...currentDocument.images, newImage],
    });
  };

  const onDeleteImage = (image: TImageDTO) => {
    setCurrentDocument({
      ...currentDocument,
      linkedObjectCounts: {
        ...currentDocument.linkedObjectCounts,
        imageCount: currentDocument.linkedObjectCounts.imageCount - 1,
      },
      images: currentDocument.images.filter((i) => i.id !== image.id),
    });
  };

  const onSaveElementClickAsync = async (
    element: TIdNameTypeObjectType,
    closeSavePopupCallback?: () => void
  ): Promise<void> => {
    // if fetchedDocument is not set
    if (!fetchedDocument) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
          element.objectType
        ).toLowerCase()} to document.`
      );
      // stop execution, return
      return;
    }

    // call close save popup callback if it set
    if (closeSavePopupCallback) closeSavePopupCallback();

    // get document object type from fetched document
    const documentObjectType: ObjectTypeEnum =
      ObjectTypeHelperSingleton.documentTypeToObjectType(
        fetchedDocument.savedDocumentType
      );

    await ConnectedObjectsHelperSingleton.connectObjectAsync(
      {
        id: fetchedDocument.id,
        objectType: documentObjectType,
      } as TIdNameTypeObjectType,
      element,
      pubSubHandler
    );
  };

  const onUpdateConnectedObjectsCounts = (
    connectedObjects: TIdNameTypeObjectType[]
  ) => {
    setCurrentDocument({
      ...currentDocument,
      linkedObjectCounts: {
        ...currentDocument.linkedObjectCounts,
        studyCount: connectedObjects.filter(
          (o) => (o.objectType ?? o.type) === ObjectTypeEnum.Study
        ).length,
        entityCount: connectedObjects.filter(
          (o) => (o.objectType ?? o.type) === ObjectTypeEnum.Entity
        ).length,
      },
    });
  };

  const onUpdateCommentCount = (commentCount: number) => {
    setCurrentDocument({
      ...currentDocument,
      linkedObjectCounts: {
        ...currentDocument.linkedObjectCounts,
        commentCount: commentCount,
      },
    });
  };

  const onUpdateFileCount = (has: boolean) => {
    setCurrentDocument({
      ...currentDocument,
      linkedObjectCounts: {
        ...currentDocument.linkedObjectCounts,
        fileCount: has ? 1 : 0,
      },
    });
  };

  const onFullTextIndexingUpdate = (indexingStatus: IndexingStatusEnum, result?: string) => {
    if (!currentDocument.documentCustomInformation) {
      return;
    }

    const parsedResult = JSON.parse(result ?? "{}");
    setCurrentDocument({
      ...currentDocument,
      documentCustomInformation: {
        ...currentDocument.documentCustomInformation,
        summary: parsedResult?.summary,
        author: parsedResult?.author,
        affiliation: parsedResult?.affiliation,
        publicationDate: parsedResult?.publication_date,
        indexingStatus: indexingStatus,
      },
    });
  };

  return (
    <DocumentView
      document={fromIDocumentDTO(currentDocument)}
      onSaveElementClick={async (
        element: TIdNameTypeObjectType,
        closeSavePopupCallback?: () => void
      ) => {
        await onSaveElementClickAsync(element, closeSavePopupCallback);
      }}
      doShowGoToUrlButton={doShowGoToUrlButton}
      isMainTitleEditable={
        canUserEdit &&
        currentDocument.savedDocumentType === SavedDocumentTypeEnum.Weblink
      }
      onUpdateTitle={debouncedHandleNewDocumentTitleAsync}
      onAddImage={onAddImage}
      onDeleteImage={onDeleteImage}
      updateHighlights={updateHighlights}
      onUpdateConnectedObjectsCounts={onUpdateConnectedObjectsCounts}
      onUpdateCommentCount={onUpdateCommentCount}
      onUpdateFileCount={onUpdateFileCount}
      onFullTextIndexingUpdate={onFullTextIndexingUpdate}
    />
  );
};
