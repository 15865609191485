// node_modules
import { FC, useContext, useRef, useState } from "react";
// Constants
import { GeneralConstants } from "Constants";
// Enums
import { EntityTypeEnum, ObjectTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
// Helpers
import {
    EntityHelperSingleton,
    ToastHelperSingleton
} from "Helpers";
// Hooks
import { useClickOutsideRef, useLinkNewEntityToQuery } from "Hooks";
// Contexts
import { PubSubContext } from "Providers";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import {
    LinkCreatedEntityModal,
    ObjectSearchPopupContent
} from "Components";
// Interfaces
import {
    IDocumentSearchResult,
    IEntityDTO,
    IQueryDTO
} from "Interfaces";
// Styles
import styles from "./saveToPage.module.scss";
// Controllers
import { LinkingControllerSingleton } from "Controllers";

interface ISaveToPageProps {
    isSavePopupOpen: boolean;
    setIsSavePopupOpen: (isSavePopupOpen: boolean) => void;
    documentsSelected: IDocumentSearchResult[];
    query: IQueryDTO;
    onDeselectAllDocuments?: () => void;
    onDocumentsLinkedAsync?: (toObject: TIdNameTypeObjectType) => Promise<void>;
}

export const SaveToPage: FC<ISaveToPageProps> = ({
    isSavePopupOpen,
    setIsSavePopupOpen,
    documentsSelected,
    query,
    onDeselectAllDocuments,
    onDocumentsLinkedAsync,
}) => {
    const [isCreatedEntityModalOpen, setIsCreatedEntityModalOpen] =
        useState<boolean>(false);
    const [creatingEntity, setCreatingEntity] =
        useState<IEntityDTO | undefined>(undefined);

    const saveContainerElementRef = useRef<HTMLDivElement>(null);

    // Contexts
    const { pubSubHandler } = useContext(PubSubContext);

    // Hooks
    useClickOutsideRef(
        saveContainerElementRef,
        () => {
            setIsSavePopupOpen(false);
        },
        [],
        GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
    );

    const onSaveElementClickAsync = async (
        element: TIdNameTypeObjectType,
        skipFirst?: boolean
    ): Promise<void> => {
        // close save popup
        setIsSavePopupOpen(false);

        for (let index = 0; index < documentsSelected.length; index++) {
            const document = documentsSelected[index];
            if (skipFirst && index === 0) {
                return;
            }
            await EntityHelperSingleton.createEntityFromQuery(
                element,
                document,
                pubSubHandler,
                query
            );
        }

        onDeselectAllDocuments?.();
    };

    const onCreateNewEntity = async (text: string) => {
        setIsSavePopupOpen(false);
        setIsCreatedEntityModalOpen(true);
        setCreatingEntity({
            title: text,
            type: EntityTypeEnum.Undefined,
        } as IEntityDTO);
    };

    const { linkNewEntityToQueryAsync } =
        useLinkNewEntityToQuery(
            query,
            setIsCreatedEntityModalOpen
        );

    const onCreateEntityClickAsync = async (
        entity: IEntityDTO,
        linkType: string,
        linkedObject?: TIdNameTypeObjectType
    ): Promise<IEntityDTO | undefined> => {
        const newEntity: IEntityDTO | undefined = await linkNewEntityToQueryAsync(
            entity,
            linkType,
            linkedObject
        );
        if (!newEntity) return;

        const toObject = {
            id: newEntity.id,
            objectType: ObjectTypeEnum.Entity,
            title: newEntity.title,
        } as TIdNameTypeObjectType;

        await onSaveElementClickAsync(toObject);

        await onDocumentsLinkedAsync?.(toObject);

        return newEntity;
    };

    const onElementClickAsync = async (
        result: TIdNameTypeObjectType
    ): Promise<void> => {
        // link document to entity
        await onSaveElementClickAsync(result);

        // link query to entity
        const objectEntityResponse = await LinkingControllerSingleton.createToAsync(
            query.guid,
            ObjectTypeEnum.Query,
            result.id,
            result.objectType,
        );

        if (objectEntityResponse !== WebRequestStatusEnum.Success &&
            objectEntityResponse !== WebRequestStatusEnum.AlreadyExists) {
            ToastHelperSingleton.showToast(
                ToastTypeEnum.Error,
                "Failed to link query to object."
            );
        }

        // update documents
        await onDocumentsLinkedAsync?.(result);
    };

    return (
        <div className={styles.querySaveResultsContent}>
            {isSavePopupOpen && (
                <div className={styles.querySaveResultsPopover}>
                    <div
                        className={styles.querySaveResultsPopoverOverlay}
                        ref={saveContainerElementRef}
                    >
                        <ObjectSearchPopupContent
                            onElementClick={onElementClickAsync}
                            doShowRecentActivity={true}
                            doShowCreateButton={true}
                            onCreateClick={onCreateNewEntity}
                            initialLinkedObjects={query?.connectedObjects}
                            initialLinkedObjectsTitle="Query Connections"
                            moreActionsDropdownPopoverDataIdentifier={
                                GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
                            }
                        />
                    </div>
                </div>
            )}
            {isCreatedEntityModalOpen && (
                <LinkCreatedEntityModal
                    isOpen={isCreatedEntityModalOpen}
                    setIsOpen={setIsCreatedEntityModalOpen}
                    creatingEntity={creatingEntity}
                    onCreateEntityClickAsync={onCreateEntityClickAsync}
                />
            )}
        </div>
    );
};
