// node_modules
import { FC, useEffect, useState } from "react";
// Icons
import {
  faExclamationCircle,
  faPlus,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Cotrollers
import { RequirementsTableControllerSingleton } from "Controllers";
// Enums
import { ToastTypeEnum } from "Enums";
// Helpers
import { ToastHelperSingleton } from "Helpers";
// Interfaces
import { IAskIgorDetailInformation } from "Interfaces";
// Types
import { TIdNameTypeObjectType } from "Types";
// Constants
import { AiConstants } from "Constants";
// Styles
import styles from "./detailInformation.module.scss";

interface IDetailInformationProps {
  aboutObject?: TIdNameTypeObjectType;
  onDetailInformationChange: (
    newDetailInformation: IAskIgorDetailInformation[]
  ) => void;
}

export const DetailInformation: FC<IDetailInformationProps> = ({
  aboutObject,
  onDetailInformationChange,
}: IDetailInformationProps) => {
  // State
  const [detailInformation, setDetailInformation] = useState<
    IAskIgorDetailInformation[]
  >([]);
  const [newDetailInformation, setNewDetailInformation] =
    useState<IAskIgorDetailInformation | undefined>(undefined);

  // Logic

  const onColumnHeaderChange = (
    detailInformationId: string,
    newColumnHeader: string
  ) => {
    const existingColumnHeader: IAskIgorDetailInformation | undefined =
      detailInformation.find(
        (d) =>
          d.columnHeader === newColumnHeader && d.id !== detailInformationId
      );
    if (existingColumnHeader) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Column header is already in the table."
      );
    }

    setDetailInformation((prevDetailInformation) => {
      return prevDetailInformation.map((d) => {
        if (d.id === detailInformationId) {
          return { ...d, columnHeader: newColumnHeader };
        }

        return d;
      });
    });
  };

  const onDataToExtractChange = (
    detailInformationId: string,
    newDataToExtract: string
  ) => {
    const existingDataToExtract: IAskIgorDetailInformation | undefined =
      detailInformation.find(
        (d) =>
          d.dataToExtract === newDataToExtract && d.id !== detailInformationId
      );
    if (existingDataToExtract) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Data to extract is already in the table."
      );
    }

    setDetailInformation((prevDetailInformation) => {
      return prevDetailInformation.map((d) => {
        if (d.id === detailInformationId) {
          return { ...d, dataToExtract: newDataToExtract };
        }

        return d;
      });
    });
  };

  const onDetailInformationDelete = (detailInformationId: string) => {
    setDetailInformation((prevDetailInformation) => {
      return prevDetailInformation.filter((d) => d.id !== detailInformationId);
    });
  };

  const onNewColumnHeaderChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newHeader = event.target.value;
    setNewDetailInformation((prevNewDetailInformation) => {
      if (!prevNewDetailInformation) {
        return {
          id: window.crypto.randomUUID(),
          columnHeader: newHeader,
          dataToExtract: "",
        };
      }

      return { ...prevNewDetailInformation, columnHeader: newHeader };
    });
  };

  const onNewDataToExtractChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newDataToExtract = event.target.value;

    setNewDetailInformation((prevNewDetailInformation) => {
      if (!prevNewDetailInformation) {
        return {
          id: window.crypto.randomUUID(),
          columnHeader: "",
          dataToExtract: newDataToExtract,
        };
      }

      return { ...prevNewDetailInformation, dataToExtract: newDataToExtract };
    });
  };

  const onAddNewDetailInformation = () => {
    if (!newDetailInformation) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not add new detail information."
      );
      return;
    }

    const trimedNewDetailInformation: IAskIgorDetailInformation = {
      id: newDetailInformation.id,
      dataToExtract: newDetailInformation.dataToExtract.trim(),
      columnHeader: newDetailInformation.columnHeader.trim(),
    };

    const existingDataToExtract: IAskIgorDetailInformation | undefined =
      detailInformation.find(
        (d) =>
          d.dataToExtract === trimedNewDetailInformation.dataToExtract ||
          d.columnHeader === trimedNewDetailInformation.columnHeader
      );
    if (existingDataToExtract) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Detail information is already in the table."
      );
      return;
    }

    setDetailInformation((prevDetailInformation) => {
      return [...prevDetailInformation, trimedNewDetailInformation];
    });

    setNewDetailInformation(undefined);
    const tableBuilder = document.querySelector(`.${styles.tableBuilder}`);
    if (tableBuilder) {
      setTimeout(() => {
        tableBuilder.scrollTop = tableBuilder.scrollHeight;
      }, 100);
    }

  };

  const onDetailInformationDeleteKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Enter") {
      onDetailInformationDelete(e.currentTarget.id);
    }
  };

  const onAddNewDetailInformationKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Enter") {
      onAddNewDetailInformation();
    }
  };

  useEffect(() => {
    onDetailInformationChange(detailInformation);
  }, [onDetailInformationChange, detailInformation]);

  useEffect(() => {
    (async () => {
      if (!aboutObject) {
        return;
      }

      const extractedDataToExtract: string[] =
        await RequirementsTableControllerSingleton.extractAsync(
          aboutObject.id,
          aboutObject.objectType
        );

      setDetailInformation(
        extractedDataToExtract.map((currExtractedDataToExtract: string) => {
          return {
            id: window.crypto.randomUUID(),
            dataToExtract: currExtractedDataToExtract,
            columnHeader: "",
          };
        })
      );
    })();
  }, [aboutObject]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.informationHeaderContainer}>
            <h6 className={styles.informationHeader}>Column header</h6>
          </div>
          <div className={styles.informationHeaderContainer}>
            <h6 className={styles.informationData}>
              {AiConstants.INFORMATION_EXTRACTION_PLACEHOLDER}
            </h6>
            <p>{AiConstants.INFORMATION_EXTRACTION_INSTRUCTION}</p>
          </div>
        </div>
      </div>
      <div className={`${styles.container} ${styles.tableBuilder}`}>
        {detailInformation.map(
          (currDetailInformation: IAskIgorDetailInformation) => {
            return (
              <div key={currDetailInformation.id}>
                <div className={styles.section}>
                  <textarea
                    placeholder="Header"
                    className={`${styles.informationInput} ${styles.informationHeader
                      } ${currDetailInformation.columnHeader.length
                        ? ""
                        : styles.error
                      }`}
                    value={currDetailInformation.columnHeader}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const newColumnHeader = event.target.value;
                      onColumnHeaderChange(
                        currDetailInformation.id,
                        newColumnHeader
                      );
                    }}
                  />
                  <textarea
                    placeholder="What to extract?"
                    className={`${styles.informationInput} ${styles.informationData
                      }  ${currDetailInformation.dataToExtract.length
                        ? ""
                        : styles.error
                      }`}
                    value={currDetailInformation.dataToExtract}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const newDataToExtract = event.target.value;
                      onDataToExtractChange(
                        currDetailInformation.id,
                        newDataToExtract
                      );
                    }}
                  />
                  <div
                    className={styles.deleteInformation}
                    onClick={() =>
                      onDetailInformationDelete(currDetailInformation.id)
                    }
                    role="button"
                    tabIndex={0}
                    onKeyDown={onDetailInformationDeleteKeyDown}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                </div>
                {(!currDetailInformation.dataToExtract ||
                  !currDetailInformation.columnHeader) && (
                    <div
                      className={`${styles.errorContainer} ${!currDetailInformation.dataToExtract &&
                          currDetailInformation.columnHeader
                          ? styles.right
                          : ""
                        }`}
                    >
                      <FontAwesomeIcon icon={faExclamationCircle} />
                      {!currDetailInformation.dataToExtract &&
                        !currDetailInformation.columnHeader && (
                          <p>These fields can’t be empty</p>
                        )}
                      {!currDetailInformation.dataToExtract &&
                        currDetailInformation.columnHeader && (
                          <p>This field can’t be empty</p>
                        )}
                      {!currDetailInformation.columnHeader &&
                        currDetailInformation.dataToExtract && (
                          <p>The header can’t be empty</p>
                        )}
                    </div>
                  )}
              </div>
            );
          }
        )}
        <div className={styles.section}>
          <textarea
            value={newDetailInformation?.columnHeader ?? ""}
            className={`${styles.informationInput} ${styles.informationHeader}`}
            placeholder="Header"
            onChange={onNewColumnHeaderChange}
          />
          <textarea
            value={newDetailInformation?.dataToExtract ?? ""}
            className={`${styles.informationInput} ${styles.informationData}`}
            placeholder="What to extract?s"
            onChange={onNewDataToExtractChange}
          />
          <div
            onClick={onAddNewDetailInformation}
            className={`${styles.addInformation} ${!newDetailInformation ||
                !newDetailInformation.columnHeader ||
                !newDetailInformation.dataToExtract
                ? styles.informationButtonDisabled
                : ""
              }`}
            role="button"
            tabIndex={0}
            onKeyDown={onAddNewDetailInformationKeyDown}
          >
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>
      </div>
    </>
  );
};
