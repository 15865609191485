// node_modules
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
// Components
import { Checkbox, FindestButton, LoadingStatusIndicator, SuggestingTextbox, Tooltip } from "Components";
import { DetailInformation } from "./DetailInformation";
// Icons
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Constants
import { AiConstants, TextConstants } from "Constants";
// Controllers
import {
  ActivityControllerSingleton,
  SearchControllerSingleton,
} from "Controllers";
// Enums
import {
  AskIgorMenuItemEnum,
  DocumentObjectTypeEnums,
  ObjectTypeEnum,
  ToastTypeEnum,
} from "Enums";
// Helpers
import { AskIgorHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Hooks
import { useBulkAskIgor } from "Hooks";
// Interfaces
import {
  IAskIgorDetailInformation,
  IAskIgorRequest,
  IAskIgorResult,
  ISavedDocumentDTO,
} from "Interfaces";
// Types
import { TIdNameTypeObjectType } from "Types";
// Styles
import styles from "./extractDetailInformation.module.scss";

interface IExtractDetailInformationProps {
  aboutObject?: TIdNameTypeObjectType;
  documentsSelected: string[];
  linkedDocuments: ISavedDocumentDTO[] | undefined;
  doIncludeSourceFullText: boolean;
  aiGeneratedText: string;
  setAIGeneratedText: (generatedText: string) => void;
  setIsGeneratingText: (isGeneratingText: boolean) => void;
  doCancel: boolean;
  setDoCancel: (doCancel: boolean) => void;
  showTableBuilder: boolean;
  setShowTableBuilder: (showTableBuilder: boolean) => void;
}

export const ExtractDetailInformation: FC<IExtractDetailInformationProps> = ({
  aboutObject,
  documentsSelected,
  linkedDocuments,
  doIncludeSourceFullText,
  aiGeneratedText,
  setAIGeneratedText,
  setIsGeneratingText,
  doCancel,
  setDoCancel,
  showTableBuilder,
  setShowTableBuilder,
}: IExtractDetailInformationProps) => {
  // State
  const [suggestions, setSuggestions] = useState<TIdNameTypeObjectType[]>(aboutObject ? [
    aboutObject,
  ] : []);
  const [selectedLinkToObject, setSelectedLinkToObject] =
    useState<TIdNameTypeObjectType | undefined>(aboutObject);
  const [isRecentActivityVisible, setIsRecentActivityVisible] =
    useState<boolean>(false);
  const [detailInformation, setDetailInformation] = useState<
    IAskIgorDetailInformation[]
  >([]);
  const [tableContent, setTableContent] = useState<{
    id: string; title: string; questions: string[], explanation: string; answers: string[] | JSX.Element[]; isChecked: boolean;
  }[]>([]);
  const [tooltipRef, setTooltipRef] = useState<HTMLSpanElement | null>(null);
  const [tooltipText, setTooltipText] = useState<string>("");

  // Refs
  const currentObjectNameRef = useRef<string>("");

  // Memo
  const isSubmitButtonDisabled = useMemo(() => {
    if (!detailInformation || detailInformation.length === 0) return true;

    const hasInvalidEntry = detailInformation.some(
      (info) => !info.columnHeader?.trim() || !info.dataToExtract?.trim()
    );

    if (hasInvalidEntry) return true;

    const hasDuplicate = (arr: string[]) => new Set(arr).size !== arr.length;

    const columnHeaders = detailInformation.map((info) => info.columnHeader.trim());
    const dataToExtracts = detailInformation.map((info) => info.dataToExtract.trim());

    return hasDuplicate(columnHeaders) || hasDuplicate(dataToExtracts);
  }, [detailInformation]);

  // Logic
  const processCompletionResultAsync = useCallback(
    async (
      request: IAskIgorRequest,
      result: IAskIgorResult
    ): Promise<void> => {
      setTableContent((prevTableContent) => {
        return prevTableContent.map((item) => {
          if (item.id === request.sourceIds?.[0]) {
            const questionIndex = item.questions.findIndex(
              (question) => question === request.input.userInput
            );
            if (questionIndex !== -1) {
              const updatedAnswers = item.answers.map((answer, index) =>
                index === questionIndex
                  ? AskIgorHelperSingleton.generateContentFromAskIgorResult(
                    "HTML_PARAGRAPH",
                    result.informationExtraction?.InformationExtraction?.Documents || {},
                    result.informationExtraction?.InformationExtraction?.Highlights || {},
                    request.input.userInput
                  )
                  : answer
              );

              return {
                ...item,
                answers: updatedAnswers as string[] | JSX.Element[],
                explanation: result.explanation ?? item.explanation
              };
            }
          }
          return item;
        });
      });
    },
    []
  );

  const onAllInformationRequestsDone = useCallback(() => {
    setIsGeneratingText(false);
  }, [setIsGeneratingText]);

  const {
    generationRequested,
    addGenerationRequested,
    requestGenerationAsync,
    cancelAllGenerationRequests,
  } = useBulkAskIgor(
    processCompletionResultAsync,
    undefined,
    onAllInformationRequestsDone
  );

  const retrieveRecentActivityAsync = async () => {
    const recentActivity: TIdNameTypeObjectType[] =
      await ActivityControllerSingleton.getMySimpleActivityAsync();

    if (!recentActivity) {
      setSuggestions([]);
      return;
    }

    setSuggestions(recentActivity);
  };

  const runSuggestionsSearchAsync = async (
    suggestionValue: string,
    currentSearchTypes: ObjectTypeEnum[]
  ): Promise<void> => {
    setIsRecentActivityVisible(false);

    currentObjectNameRef.current = suggestionValue;

    if (!suggestionValue) {
      retrieveRecentActivityAsync();
      setIsRecentActivityVisible(true);
      return;
    }

    const foundSuggestions: TIdNameTypeObjectType[] =
      await SearchControllerSingleton.searchMultipleObjectsAsync(
        suggestionValue,
        currentSearchTypes
      );

    if (!foundSuggestions) {
      setSuggestions([]);
      return;
    }

    setSuggestions(foundSuggestions);
  };

  const resetGeneratedInformation = useCallback((doShowLoading: boolean) => {
    setTableContent(() => {
      const newTableContent = [];
      for (const documentId of documentsSelected) {
        const docName = linkedDocuments?.find((doc) => doc.id === documentId)?.title ?? "";
        newTableContent.push({
          id: documentId,
          title: docName,
          questions: detailInformation.map((de) => de.dataToExtract),
          explanation: "",
          // eslint-disable-next-line react/jsx-key
          answers: detailInformation.map(() => doShowLoading ? <LoadingStatusIndicator size={20} status={1} /> : "") as string[] | JSX.Element[],
          isChecked: true,
        });
      }

      return newTableContent;
    });
  }, [detailInformation, documentsSelected, linkedDocuments]);

  const onSubmitClickAsync = useCallback(async (): Promise<void> => {
    if (!documentsSelected || documentsSelected.length === 0) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS
      );
      return;
    }

    cancelAllGenerationRequests();
    setAIGeneratedText("");
    setIsGeneratingText(true);

    const extractDetailInformationRequests: IAskIgorRequest[] = [];
    for (const currentDetailInformation of detailInformation) {
      for (const documentId of documentsSelected) {
        const savedDocumentType = linkedDocuments?.find(
          (doc) => doc.id === documentId
        )?.savedDocumentType;
        extractDetailInformationRequests.push({
          correlationId: window.crypto.randomUUID(),
          input: {
            pageId: selectedLinkToObject?.id || aboutObject?.id,
            pageType: selectedLinkToObject?.objectType || aboutObject?.objectType,
            userInput: currentDetailInformation.dataToExtract
          },
          sourceTypes: savedDocumentType
            ? [
              ObjectTypeHelperSingleton.documentTypeToObjectType(
                savedDocumentType
              ),
            ]
            : DocumentObjectTypeEnums,
          doIncludeSourceFullText: doIncludeSourceFullText,
          sourceIds: [documentId],
        });
      }
    }
    addGenerationRequested(extractDetailInformationRequests);

    resetGeneratedInformation(true);
    setShowTableBuilder(false);

    let isSuccess = true;
    let failedOnDataToExtract = "";
    let failedOnDocumentTitle = "";
    for (const request of extractDetailInformationRequests) {
      isSuccess = await requestGenerationAsync(request, AskIgorMenuItemEnum.InformationExtraction);

      if (!isSuccess) {
        failedOnDataToExtract = request.input.userInput;
        failedOnDocumentTitle =
          linkedDocuments?.find((doc) => doc.id === request.sourceIds?.[0])
            ?.title ||
          request.sourceIds?.[0] ||
          "";
        break;
      }
    }
    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `${AiConstants.GENERATE_INFORMATION_EXTRACTION_FAILED} Information to extract: ${failedOnDataToExtract}, document: ${failedOnDocumentTitle}.`
      );
      cancelAllGenerationRequests(false);

      resetGeneratedInformation(false);
    }
  }, [aboutObject, cancelAllGenerationRequests, setAIGeneratedText, setIsGeneratingText, addGenerationRequested, resetGeneratedInformation, setShowTableBuilder, detailInformation, documentsSelected, linkedDocuments, selectedLinkToObject, doIncludeSourceFullText, requestGenerationAsync]);

  useEffect(() => {
    if (doCancel && generationRequested.size > 0) {
      cancelAllGenerationRequests(false);
      setDoCancel(false);
      setTableContent((prevTableContent) =>
        prevTableContent.map((item) => ({
          ...item,
          answers: item.answers.map((answer) =>
            typeof answer === "string" ? answer : ""
          ),
        }))
      );
    }
  }, [
    cancelAllGenerationRequests,
    doCancel,
    generationRequested.size,
    setDoCancel,
  ]);

  const renderTiptapTable = useCallback((): string => {
    let tableHtml = "<table>";
    tableHtml += "<thead><tr><th>Document</th>";
    detailInformation.forEach((info) => {
      tableHtml += `<th>${info.columnHeader}</th>`;
    });
    tableHtml += "</tr></thead>";
    tableHtml += "<tbody>";
    tableContent.forEach((row) => {
      if (row.isChecked) {
        tableHtml += `<tr><td>${row.title}</td>`;
        row.answers.forEach((answer) => {
          tableHtml += `<td>${answer}</td>`;
        });
        tableHtml += "</tr>";
      }
    });
    tableHtml += "</tbody></table>";
    return tableHtml;
  }, [tableContent, detailInformation]);

  const renderTable = useCallback(() => {
    return (
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Document</th>
              {detailInformation.map((info) => (
                <th key={info.dataToExtract}>{info.columnHeader}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableContent.map((row) => (
              <tr key={row.id}>
                <td className={styles.tableDocumentTitle}>
                  <div>
                    <Checkbox
                      theme="blue"
                      isChecked={row.isChecked}
                      onCheckboxChange={(isChecked: boolean) => {
                        setTableContent((prevTableContent) => {
                          return prevTableContent.map((item) => {
                            if (item.id === row.id) {
                              return {
                                ...item,
                                isChecked,
                              };
                            }
                            return item;
                          });
                        });
                      }}
                    />
                    <p>
                      <span
                        onMouseOver={(e) => {
                          setTooltipText(row.title);
                          setTooltipRef(e.currentTarget);
                        }}
                        onMouseOut={() => {
                          setTooltipText("");
                          setTooltipRef(null);
                        }}
                        onFocus={(e) => {
                          setTooltipText(row.title);
                          setTooltipRef(e.currentTarget);
                        }}
                        onBlur={() => {
                          setTooltipText("");
                          setTooltipRef(null);
                        }}
                      >
                        {row.title}
                      </span>
                    </p>
                    {row.explanation && row.explanation.length > 0 && <span
                      onMouseOver={(e) => {
                        setTooltipText(row.explanation);
                        setTooltipRef(e.currentTarget);
                      }}
                      onMouseOut={() => {
                        setTooltipText("");
                        setTooltipRef(null);
                      }}
                      onFocus={(e) => {
                        setTooltipText(row.explanation);
                        setTooltipRef(e.currentTarget);
                      }}
                      onBlur={() => {
                        setTooltipText("");
                        setTooltipRef(null);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                      />
                    </span>}
                  </div>
                </td>
                {row.answers.map((answer, index) => (
                  (typeof answer === "string" ? (
                    // eslint-disable-next-line react/no-danger, react/no-array-index-key
                    <td key={index} dangerouslySetInnerHTML={{ __html: answer }} className={styles.tableDocumentInformation}></td>
                  ) : (typeof answer === "object") ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <td key={index} className={styles.tableDocumentInformation}>
                      <LoadingStatusIndicator size={20} status={1} />
                    </td>
                  ) : null)
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  useEffect(() => {
    if (!tableContent || tableContent.length === 0) return;
    setAIGeneratedText(renderTiptapTable());
  }, [tableContent]);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.tableBuilder} ${!showTableBuilder && aiGeneratedText.length ? styles.displayNone : ""
          }`}
      >
        {aboutObject && (
          <div className={styles.existingObjectSuggestions}>
            <h6 className={styles.title}>Object</h6>
            <SuggestingTextbox
              forcedSuggestionValue={
                selectedLinkToObject?.name || aboutObject?.name
              }
              placeholder="Search for entity or study"
              title={
                isRecentActivityVisible
                  ? "Recently updated objects"
                  : "Universe suggestions"
              }
              onValueChangeHandler={(value) =>
                (currentObjectNameRef.current = value)
              }
              refreshSuggestionsAsync={async (newValue: string) => {
                runSuggestionsSearchAsync(newValue, [
                  ObjectTypeEnum.Entity,
                  ObjectTypeEnum.Study,
                ]);
              }}
              suggestions={suggestions}
              handleSuggestionClick={(suggestion: TIdNameTypeObjectType) => {
                setSelectedLinkToObject(suggestion);
              }}
              selectedOption={selectedLinkToObject}
              setSelectedOption={setSelectedLinkToObject}
            />
          </div>
        )}
        <DetailInformation
          aboutObject={selectedLinkToObject || aboutObject}
          onDetailInformationChange={setDetailInformation}
        />
        <div className={styles.footer}>
          <FindestButton
            title={showTableBuilder ? "Update table" : "Extract information"}
            onClick={onSubmitClickAsync}
            extraClassName={styles.submitButton}
            isDisabled={isSubmitButtonDisabled}
          />
          {showTableBuilder && (
            <FindestButton
              title="Back to table"
              buttonType="secondary"
              onClick={() => setShowTableBuilder(false)}
            />
          )}
        </div>
      </div>
      {!showTableBuilder && aiGeneratedText && (
        <div className={styles.tableDisplay}>
          <div className={styles.existingObjectSuggestions}>
            <h6 className={styles.title}>Extract detail information</h6>
          </div>
          {renderTable()}
        </div>
      )}
      <Tooltip
        referenceEl={tooltipRef}
        isOpen={tooltipText.length > 0}
        tooltipText={tooltipText}
        placement="right"
      />
    </div>
  );
};
