// Enums
import { SavedDocumentTypeEnum } from "Enums";
// Interfaces
import { IAuthorships, IConnectedObjects, IDocumentCustomInformation, IDocumentSearchResult, IEntityDTO, IHighlights, IImages, IIsOpenAccess, ISearchInformation } from "Interfaces";
// Types
import { THeaderContentDTO, TLinkedCountsDTO } from "Types";

export interface ISavedDocumentDTO extends IConnectedObjects, ISearchInformation, IAuthorships, IIsOpenAccess, IImages, IHighlights, IDocumentCustomInformation {
    id: string,
    title: string,
    abstract?: string,
    claims?: string,
    url: string,
    fullUrl: string,
    dateAdded: Date,
    savedDocumentType: SavedDocumentTypeEnum,
    entities: IEntityDTO[],
    linkedCounts?: TLinkedCountsDTO,
    linkedObjectCounts?: TLinkedCountsDTO,
    createdByUsername?: string
    mainContents: THeaderContentDTO[] | null | undefined;
}

export const fromIDocumentSearchResultTOISavedDocumentDTO = (documentSearchResult: IDocumentSearchResult): ISavedDocumentDTO => {
    return {
        id: documentSearchResult.documentId,
        savedDocumentType: documentSearchResult.documentType,
        title: documentSearchResult.title,
        url: documentSearchResult.url,
        fullUrl: documentSearchResult.url,
        mainContents: documentSearchResult.mainContents,
        searchInformation: documentSearchResult.searchInformation,
        authorships: documentSearchResult.authorships,
        connectedObjects: documentSearchResult.connectedObjects,
        isOpenAccess: documentSearchResult.isOpenAccess,
        highlights: documentSearchResult.highlights,
        images: documentSearchResult.images,
        dateAdded: new Date(),
        entities: []
    };
};