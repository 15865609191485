// node_modules
import { faList, faSitemap } from "@fortawesome/pro-solid-svg-icons";
import { faChartNetwork } from "@fortawesome/pro-solid-svg-icons";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ReactFlowProvider } from "reactflow";
// Components
import {
  LinkGraph,
  LinksListView,
  LinksWindowMenu,
  LinksWindowSearchBar,
  Tabs,
} from "Components";
import { ForceDirectedGraphView } from "../../../UniverseOverview/ForceDirectedGraphView";
// Types
import {
  TCheckedLinks,
  TIdNameTypeObjectType,
  TOption,
  TTab,
  TUseDragAndDrop,
  fromIEntityDTO,
  fromIStudyDTO,
} from "Types";
// Enums
import {
  EntityTypeEnum,
  GraphViewContainerTypeEnum,
  LinksWindowTabsEnum,
  ObjectTypeEnum,
  StudyTypeEnum,
  ToastTypeEnum,
  WebRequestStatusEnum,
} from "Enums";
// Custom hooks
import {
  useDragAndDrop,
  useForceDirectedLinkGraph,
  useObjectReferenceModal,
} from "Hooks";
// Styles
import styles from "./linksWindow.module.scss";
// Contexts
import {
  AuthContext,
  ElementVisibilityContext,
  LinkGraphContext,
  WindowingContext,
} from "Providers";
// Interfaces
import { IEntityDTO } from "Interfaces";
// Controllers
import {
  EntityControllerSingleton,
  LinkingControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
import { ToastHelperSingleton } from "Helpers";

type TLinksWindowProps = {
  defaultSelectedTab?: LinksWindowTabsEnum;
};

export const LinksWindow: FC<TLinksWindowProps> = ({
  defaultSelectedTab,
}: TLinksWindowProps) => {
  // Contexts
  const {
    doShowReanchorButton,
    onReanchorClick,
    isReferenceModalOpen,
    closeReferenceModal,
    referenceModalObjectId,
    referenceModalObjectType,
  } = useContext(LinkGraphContext);
  const { canUserEdit } = useContext(ElementVisibilityContext);
  const { minimizeAllWindows, graphViewSelectedTab, setGraphViewSelectedTab } =
    useContext(WindowingContext);
  const { isUserExternal } = useContext(AuthContext);

  // State
  const [selectedTab, setSelectedTab] = useState<LinksWindowTabsEnum>(
    defaultSelectedTab ?? graphViewSelectedTab ?? LinksWindowTabsEnum.GraphView
  );
  const [checkedLinks, setCheckedLinks] = useState<TCheckedLinks[]>([]);
  const [
    isLinksWindowSearchBarResultsElementActive,
    setIsLinksWindowSearchBarResultsElementActive,
  ] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<boolean>(false);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    TOption<EntityTypeEnum | StudyTypeEnum>[]
  >([]);

  useEffect(() => {
    if (isUserExternal) {
      setGraphViewSelectedTab(LinksWindowTabsEnum.TreeView);
    }
  }, [isUserExternal, setGraphViewSelectedTab]);

  useEffect(() => {
    if (graphViewSelectedTab) {
      setSelectedTab(graphViewSelectedTab);
    }
  }, [graphViewSelectedTab]);

  // On reference modal close
  const onReferenceModalClose = () => {
    // set is reference modal open to false
    closeReferenceModal();
  };

  // Hooks
  const { referenceModal, setReferenceModalProps } = useObjectReferenceModal(
    undefined,
    onReferenceModalClose
  );
  const forceDirectedLinkGraph = useForceDirectedLinkGraph();

  // Memo
  const linksWindowTabs = useMemo((): TTab[] => {
    // init new links window tabs
    const newLinksWindowTabs: TTab[] = [
      { name: LinksWindowTabsEnum.GraphView, icon: faChartNetwork },
      { name: LinksWindowTabsEnum.TreeView, icon: faSitemap },
      { name: LinksWindowTabsEnum.ListView, icon: faList },
    ];

    // if user is external
    if (isUserExternal) {
      // remove graph view tab
      newLinksWindowTabs.splice(0, 1);
    }

    // return new links window tabs
    return newLinksWindowTabs;
  }, [isUserExternal]);

  // UseEffects
  useEffect(() => {
    if (isReferenceModalOpen) {
      setReferenceModalProps({
        id: referenceModalObjectId,
        type: referenceModalObjectType,
        isOpen: true,
        doIgnoreIsDeleted: false,
      });
    }
  }, [
    isReferenceModalOpen,
    referenceModalObjectId,
    referenceModalObjectType,
    setReferenceModalProps,
  ]);

  // Logic
  const onDropHandlerAsync = useCallback(
    async (
      draggedObject: TIdNameTypeObjectType,
      draggedOverObject: TIdNameTypeObjectType,
      draggedObjectParent: TIdNameTypeObjectType | undefined,
      cb?: (createdObject: TIdNameTypeObjectType) => void
    ) => {
      // init dragged object id and type
      let draggedObjectId: string = draggedObject.id;
      let draggedObjectType: ObjectTypeEnum = draggedObject.objectType;

      // if dragged object parent is defined
      if (draggedObjectParent) {
        // call server to remove link between dragged object parent and dragged object
        await LinkingControllerSingleton.deleteAsync(
          draggedObjectParent.id,
          draggedObject.id
        );
      } else {
        // otherwise, if dragged object parent is not defined
        // we might have to create a new object before linking it
        // if dragged object does not have an id and is of type Entity or Study
        if (
          !draggedObjectId &&
          (draggedObjectType === ObjectTypeEnum.Entity ||
            draggedObjectType === ObjectTypeEnum.Study)
        ) {
          let createdObject: TIdNameTypeObjectType | undefined = undefined;
          // if dragged object is of type Entity
          if (draggedObject.objectType === ObjectTypeEnum.Entity) {
            // create entity dto
            const newEntity = {
              title: draggedObject.name,
              type: EntityTypeEnum.Undefined,
            } as IEntityDTO;

            // create entity in db
            const createdEntity = await EntityControllerSingleton.createAsync(
              newEntity
            );

            // safety-checks
            if (!createdEntity) {
              // stop execution
              return;
            }

            // set dragged object id and type
            draggedObjectId = createdEntity.id;
            draggedObjectType = ObjectTypeEnum.Entity;

            createdObject = fromIEntityDTO(createdEntity);
          } else if (draggedObjectType === ObjectTypeEnum.Study) {
            // otherwise, if dragged object is of type Study

            const name = draggedObject.name ?? draggedObject.title ?? "";

            // create study in db
            const createdStudy = await StudyControllerSingleton.createAsync(
              name,
              "",
              StudyTypeEnum.Undefined,
              undefined
            );

            // safety-checks
            if (!createdStudy) {
              // stop execution
              return;
            }

            // set dragged object id and type
            draggedObjectId = createdStudy.id;
            draggedObjectType = ObjectTypeEnum.Study;

            createdObject = fromIStudyDTO(createdStudy);
          }
          if (cb && createdObject) {
            cb(createdObject);
          }
        }
      }

      // call server to add link between dragged over object and dragged object
      if (draggedOverObject.id !== "linkGraph") {
        const webRequestStatus: WebRequestStatusEnum =
        await LinkingControllerSingleton.createToAsync(
          draggedObjectId,
          draggedObjectType,
          draggedOverObject.id,
          draggedOverObject.objectType
        );

        // safety-checks on the result
        if (
          webRequestStatus !== WebRequestStatusEnum.Success &&
          webRequestStatus !== WebRequestStatusEnum.AlreadyExists
        ) {
          // show error message
          ToastHelperSingleton.showToast(
            ToastTypeEnum.Error,
            "Failed to link."
          );
        }

      } else {
        if (cb) {
          cb(draggedObject);
        }
      }
    },
    []
  );

  // on selected tab change handler
  const onSelectedTabChange = useCallback(
    (newSelectedTab: string) => {
      // set selected tab
      setGraphViewSelectedTab(newSelectedTab as LinksWindowTabsEnum);

      // empty checked links
      setCheckedLinks([]);

      // if search keyword is not empty, set it to empty string
      if (searchKeyword) {
        setSearchKeyword("");
      }

      // if selected filter options is not empty, set it to empty array
      if (selectedFilterOptions.length > 0) {
        setSelectedFilterOptions([]);
      }
    },
    [searchKeyword, selectedFilterOptions, setGraphViewSelectedTab]
  );

  // Hooks
  const useDragAndDropProps: TUseDragAndDrop =
    useDragAndDrop(onDropHandlerAsync);

  return (
    <div className={styles.linksWindowContainer}>
      <div className={styles.linksWindowTopbar}>
        {canUserEdit &&
          selectedTab &&
          selectedTab !== LinksWindowTabsEnum.GraphView && (
            <LinksWindowSearchBar
              linksWindowSelectedTab={selectedTab}
              useDragAndDropProps={useDragAndDropProps}
              isLinksWindowSearchBarResultsElementActive={
                isLinksWindowSearchBarResultsElementActive
              }
              setIsLinksWindowSearchBarResultsElementActive={
                setIsLinksWindowSearchBarResultsElementActive
              }
            />
          )}
        <Tabs
          tabs={linksWindowTabs}
          disabledTabs={[]}
          defaultSelectedTab={selectedTab}
          onSelectedTabChange={onSelectedTabChange}
          extraClassNames={{
            container: styles.linksWindowTabsContainer,
            tab: styles.linksWindowTab,
          }}
        />
      </div>
      <div className={styles.linksWindowContentContainer}>
        {selectedTab && (
          <LinksWindowMenu
            linksWindowSelectedTab={selectedTab}
            useDragAndDropProps={useDragAndDropProps}
            doShowReanchorButton={doShowReanchorButton}
            onReanchorClick={onReanchorClick}
            checkedLinks={checkedLinks}
            canUserEdit={canUserEdit}
            setSearchKeyword={setSearchKeyword}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            minimizeAllWindows={minimizeAllWindows}
          />
        )}
        <div
          className={`${styles.linksWindowContent} ${canUserEdit ? "" : styles.fullWidth} ${(!canUserEdit && selectedTab === LinksWindowTabsEnum.ListView) ? styles.paddingLeft : ""}`}
        >
          {selectedTab === LinksWindowTabsEnum.GraphView &&
            forceDirectedLinkGraph && (
              <ForceDirectedGraphView
                data={forceDirectedLinkGraph}
                searchKeyword={searchKeyword}
                currentPage={currentPage}
                selectedFilterOptions={selectedFilterOptions}
                containerType={GraphViewContainerTypeEnum.LinksWindow}
                minimizeAllWindows={minimizeAllWindows}
              />
            )}
          {selectedTab === LinksWindowTabsEnum.TreeView && (
            <ReactFlowProvider>
              <LinkGraph
                isFullscreen={true}
                useDragAndDropProps={useDragAndDropProps}
                setIsLinksWindowSearchBarResultsElementActive={
                  setIsLinksWindowSearchBarResultsElementActive
                }
              />
            </ReactFlowProvider>
          )}
          {selectedTab === LinksWindowTabsEnum.ListView && (
            <LinksListView
              useDragAndDropProps={useDragAndDropProps}
              setCheckedLinks={setCheckedLinks}
              onReanchorClick={onReanchorClick}
            />
          )}
        </div>
      </div>
      {referenceModal}
    </div>
  );
};
