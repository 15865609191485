import { FC, useState } from "react";
import {
  faArrowRight,
  faAward,
  faFlask,
  faPen,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IQueryDTO } from "Interfaces";
import { SearchQueryTypeEnum } from "Enums";
import styles from "./technologySearchFlow.module.scss";
import { Tooltip } from "Components";

interface ITechnologySearchFlow {
  query: IQueryDTO;
  isTechnologySearchOnResults: boolean;
  onDocumentSearchClick: () => void;
  onFirstResultsClick: () => void;
  onEditNaturalLanguageQueryClick: () => void;
}

export const TechnologySearchFlow: FC<ITechnologySearchFlow> = ({
  query,
  isTechnologySearchOnResults,
  onDocumentSearchClick,
  onFirstResultsClick,
  onEditNaturalLanguageQueryClick,
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLParagraphElement | null>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div className={styles.technologySearchFlowContainer}>
      <p className={styles.technologySearchFlowHeader}>Results coming from:</p>
      <div className={styles.technologySearchFlow}>
        <div className={styles.documentSearchFlow}>
          <div className={`${styles.documentSearchContainer} ${!isTechnologySearchOnResults ? styles.padding : ""}`}>
            <button
              type="button"
              onClick={onDocumentSearchClick}
            >
              {isTechnologySearchOnResults ? "Document" : "Technology"} search
            </button>
            {isTechnologySearchOnResults && (<div className={styles.documentSearchType}>
              <FontAwesomeIcon
                className={styles.documentSearchTypeIcon}
                icon={
                  query.type === SearchQueryTypeEnum.UniverseScienceArticles
                    ? faFlask
                    : faAward
                }
              />
              <button
                type="button"
                onClick={onFirstResultsClick}
              >
                first 100
                {query.type === SearchQueryTypeEnum.UniverseScienceArticles
                  ? " science "
                  : " patent "}
                results
              </button>
            </div>)}
          </div>
          <FontAwesomeIcon icon={faArrowRight} className={styles.separatorArrow} />
        </div>
        <div className={styles.naturalLanguageQueryContainer}>
          {query.naturalLanguageQuery && (
            <p
              ref={setReferenceElement}
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
            >
              {query.naturalLanguageQuery}
            </p>
          )}
          <FontAwesomeIcon
            className={styles.editIcon}
            onClick={onEditNaturalLanguageQueryClick}
            icon={faPen}
          />
          <Tooltip
            referenceEl={referenceElement}
            isOpen={isTooltipOpen}
            tooltipText={query.naturalLanguageQuery}
            placement="bottom-start"
          />
        </div>
      </div>
    </div>
  );
};
