// node_modules
import { FC, useCallback, useEffect, useRef, useState } from "react";
// Hooks
import { useAskIgorNotification, useClickOutsideRef } from "Hooks";
// Components
import { Modal } from "../Modals";
import { FindestButton, Tooltip } from "Components";
// Enums
import { IndexingStatusEnum, NotificationStatusEnum, ToastTypeEnum } from "Enums";
// Helpers
import { LocalStorageHelperSingleton, ToastHelperSingleton } from "Helpers";
// Controllers
import { AskIgorControllerSingleton } from "Controllers";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faCircleQuestion,
  faFileCheck,
  faFileExclamation,
  faFileLock,
  faRotateRight,
  faXmark,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
// Styles
import styles from "./documentFullTextIndexed.module.scss";
import { IAskIgorResult, INotification } from "Interfaces";

// Component props type
type TDocumentFullTextIndexedProps = {
  documentId: string;
  indexingStatus: IndexingStatusEnum;
  onFullTextIndexingUpdate?: (indexingStatus: IndexingStatusEnum, result?: string) => void;
};

// Component
export const DocumentFullTextIndexed: FC<TDocumentFullTextIndexedProps> = ({ documentId, indexingStatus, onFullTextIndexingUpdate }) => {
  // State
  const [showCard, setShowCard] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [buttonRef, setButtonRef] = useState<HTMLDivElement | null>(null);
  const [tooltipText, setTooltipText] = useState<string>("");

  // Ref
  const modalRef = useRef<HTMLDivElement>(null);

  // Hooks
  useClickOutsideRef(modalRef, () => setIsInfoModalOpen(false));

  // Logic
  const onButtonClick = async (type: string) => {
    if (type === "Dismiss") {
      onDismissFullTextIndexedCard();
    } else {
      onFullTextIndexingUpdate?.(IndexingStatusEnum.Indexing);

      AskIgorControllerSingleton.generateSourceSummaryAsync({
        correlationId: documentId,
        sourceIds: [documentId],
      });
    }
  };

  const onAskIgorNotificationAsync = useCallback(async (notification: INotification): Promise<void> => {
    const { correlationId: notificationCorrelationId, message, status } = notification;
    const result = JSON.parse(message) as IAskIgorResult;

    if (notificationCorrelationId !== documentId) return;

    switch (status) {
      case NotificationStatusEnum.Error:
        onFullTextIndexingUpdate?.(IndexingStatusEnum.Failed);
        ToastHelperSingleton.showToast(ToastTypeEnum.Error, message);
        break;
      case NotificationStatusEnum.Success:
        onFullTextIndexingUpdate?.(IndexingStatusEnum.Indexed, result.sourceSummary ?? "");
        break;
      default:
        break;
    }
  }, [documentId]);

  useAskIgorNotification(onAskIgorNotificationAsync);

  const onDismissFullTextIndexedCard = () => {
    if (!documentId) {
      return;
    }

    setShowCard(false);
    const currentDismissedDocuments: { [key: string]: number } = LocalStorageHelperSingleton.getItem("fullTextIndexStatusCardDismissedDocuments") ?? {};
    if (Object.keys(currentDismissedDocuments).length === 1000) {
      // find the oldest dismissed document and remove it from the list to keep the list at 1000 documents max
      const oldestDocumentKey = Object.keys(currentDismissedDocuments).reduce((a, b) => currentDismissedDocuments[a] < currentDismissedDocuments[b] ? a : b, "0");
      delete currentDismissedDocuments[oldestDocumentKey];
    }
    if (Object.keys(currentDismissedDocuments).length < 1000) {
      currentDismissedDocuments[documentId] = Date.now();
      LocalStorageHelperSingleton.setItem(currentDismissedDocuments, "fullTextIndexStatusCardDismissedDocuments");
    }
  };

  // Constants
  const indexingStatusContent: Record<IndexingStatusEnum, { icon: IconDefinition; text: string }> = {
    [IndexingStatusEnum.NotIndexed]: { icon: faFileExclamation, text: "Full text not indexed" },
    [IndexingStatusEnum.Indexed]: { icon: faFileCheck, text: "Full text indexed" },
    [IndexingStatusEnum.Indexing]: { icon: faCircleNotch, text: "Indexing full text…" },
    [IndexingStatusEnum.Failed]: { icon: faFileExclamation, text: "Full text indexing failed" },
    [IndexingStatusEnum.Restricted]: { icon: faFileLock, text: "Full text not indexed due to restrictions" }
  };

  const indexingStatusButtons = [
    {
      shouldRender: indexingStatus === IndexingStatusEnum.Failed || indexingStatus === IndexingStatusEnum.Indexing,
      icon: faRotateRight,
      type: "Retry indexing",
      isDisabled: indexingStatus === IndexingStatusEnum.Indexing
    },
    {
      shouldRender: indexingStatus !== IndexingStatusEnum.Indexed,
      icon: faXmark,
      type: "Dismiss",
      isDisabled: false
    }
  ];

  useEffect(() => {
    if (!documentId) return;
    const currentDismissedDocuments: Record<string, number> = LocalStorageHelperSingleton.getItem("fullTextIndexStatusCardDismissedDocuments") ?? {};
    const isDocumentIndexStatusDismissed = currentDismissedDocuments[documentId];
    setShowCard(!isDocumentIndexStatusDismissed);
  }, [documentId]);

  if (!showCard) return <></>;

  // Render
  return (
    <div className={styles.documentFullTextIndexedContainer}>
      <div
        className={`${styles.information} ${indexingStatus === IndexingStatusEnum.Failed ? styles.notIndexed : ""}`}
      >
        <FontAwesomeIcon icon={indexingStatusContent[indexingStatus]?.icon} />
        <p>{indexingStatusContent[indexingStatus]?.text}</p>
        <FindestButton
          extraClassName={styles.indexingButton}
          leftIconName={faCircleQuestion}
          onClick={() => setIsInfoModalOpen(true)}
        />
        {indexingStatusButtons.map(({ shouldRender, icon, type, isDisabled }, index) =>
          shouldRender ? (
            // eslint-disable-next-line react/no-array-index-key
            <div ref={setButtonRef} key={index}>
              <FindestButton
                extraClassName={styles.indexingButton}
                leftIconName={icon}
                onMouseOver={() => setTooltipText(type)}
                onMouseOut={() => setTooltipText("")}
                isDisabled={isDisabled}
                onClick={() => onButtonClick(type)}
              />
            </div>
          ) : null
        )}
        <Tooltip
          referenceEl={buttonRef}
          isOpen={tooltipText.length > 0}
          tooltipText={tooltipText}
          placement="bottom"
        />
      </div>
      {isInfoModalOpen && (
        <Modal
          isOpen={isInfoModalOpen}
          extraClassNames={{ container: styles.informationModal }}
        >
          <div ref={modalRef}>
            <h2>Full text indexing</h2>
            <h3>What does this mean?</h3>
            <p>
              When adding a website through the plugin, or a science article
              that is OpenAccess, we now automatically save and index the body
              text of the site and the full text of the paper.
            </p>
            <h3>Where can I see this text?</h3>
            <p>
              While the text is indexed, we will not show it in the platform to
              avoid copyright and licensing issues.
            </p>
            <h3>
              What is the benefit of having this when I can’t see the text?
            </h3>
            <p>
              We do allow AskIGOR to access the text, so this way you can ask
              questions or extract data from the text using IGOR and have it
              generate content for you based on this.
            </p>
            <h3>It failed to index the text</h3>
            <p>
              You can try to initiate a new indexing again. If the problem
              persists, please reach out to us.
            </p>
            <FindestButton
              extraClassName={styles.closeButton}
              styleProps={["textTransformNone"]}
              onClick={() => setIsInfoModalOpen(false)}
              title="Close"
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
