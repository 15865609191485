// node_modules
import { Dispatch, SetStateAction, useState } from "react";
// Controllers
import { LinkingControllerSingleton } from "Controllers";
// Enums
import {
  LogFeatureNameEnum,
  ObjectTypeEnum,
  ToastTypeEnum,
  WebRequestStatusEnum,
} from "Enums";
// Helpers
import { EntityHelperSingleton, ToastHelperSingleton } from "Helpers";
// Interfaces
import { IDocumentDetails, IEntityDTO, IQueryDTO } from "Interfaces";
// Types
import { TIdNameTypeObjectType } from "Types";

export const useLinkNewEntityToQuery = (
  query: IQueryDTO | undefined,
  setIsLinkCreatedEntityModalOpen: Dispatch<SetStateAction<boolean>>,
  document?: IDocumentDetails,
  documentObjectType?: ObjectTypeEnum,
  updateDocument?: ((document: IDocumentDetails) => void) | undefined
) => {
  const [createdEntityObject, setCreatedEntityObject] = useState<IEntityDTO | undefined>(undefined);

  const linkNewEntityToQueryAsync = async (
    entity: IEntityDTO,
    linkDirection: string,
    linkedObject?: TIdNameTypeObjectType,
    isEntityBeingSavedFromModalAfterTextSelection?: boolean
  ): Promise<IEntityDTO | undefined> => {
    const actionOrigin = `${LogFeatureNameEnum.AdvancedSearch}${
      isEntityBeingSavedFromModalAfterTextSelection
        ? `-${LogFeatureNameEnum.TextSelectionMenu}`
        : ""
    }`;
    // create entity
    const createdEntity =
      await EntityHelperSingleton.createEntityFromLinkCreatedEntityModal(
        entity,
        linkDirection,
        setIsLinkCreatedEntityModalOpen,
        document,
        documentObjectType,
        updateDocument
          ? (updatedDocument: IDocumentDetails) =>
              updateDocument(updatedDocument)
          : undefined,
        linkedObject,
        query ? { ActionOrigin: actionOrigin, QueryGuid: query.guid } : {}
      );

    setCreatedEntityObject(createdEntity);

    // if created entity is not set
    if (!createdEntity) {
      // show error message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not create entity."
      );
      // stop execution, return
      return undefined;
    }

    // show success message
    ToastHelperSingleton.showToast(ToastTypeEnum.Success, "Entity created.");

    // if query is set
    if (query) {
      // link query to entity
      const linkStatus: WebRequestStatusEnum =
        await LinkingControllerSingleton.createToAsync(
          query.guid,
          ObjectTypeEnum.Query,
          createdEntity.id,
          ObjectTypeEnum.Entity,
          actionOrigin
        );

      // if linkStatus is not success
      if (linkStatus !== WebRequestStatusEnum.Success && linkStatus !== WebRequestStatusEnum.AlreadyExists) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not link query to entity."
        );
        // stop execution, return
        return undefined;
      }

      // show success message
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Success,
        "Query linked to entity."
      );
    }

    return createdEntity;
  };

  return { linkNewEntityToQueryAsync, createdEntityObject };
};
