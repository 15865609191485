// node_modules
import { FC, useMemo, MouseEvent } from "react";
// Styles
import "Styles/documentHighlights.scss";
import styles from "./documentMetaData.module.scss";
// Interfaces
import { IDocumentDetails } from "Interfaces";

// Component props type
type TDocumentMetaDataProps = {
    document: IDocumentDetails,
    onSelectedTextOnMouseEvent: (mouseEvent: MouseEvent<HTMLElement>) => void
}

// Component
export const DocumentMetaData: FC<TDocumentMetaDataProps> = ({ document, onSelectedTextOnMouseEvent }) => {
    // Memo
    const hasAnyMetadata = useMemo(() => {
        return (document.authorships && document.authorships.length > 0) ||
            (document.searchInformation && (document.searchInformation.filingDate ||
                document.searchInformation.impactFactor || 
                document.searchInformation.journalName ||
                document.searchInformation.publicationDate || 
                document.searchInformation.patentCountries ||
                document.searchInformation.patentNumbers ||
                document.searchInformation.fixedPatentAssignees ||
                document.searchInformation.fixedPatentAuthors)) ||
            (document.documentCustomInformation && (document.documentCustomInformation.author ||
                document.documentCustomInformation.affiliation ||
                document.documentCustomInformation.publicationDate));
    }, [document.authorships, document.searchInformation, document.documentCustomInformation]);

    // Render
    return (
        hasAnyMetadata ?
            <div className={`${styles.documentMetadataContainer} ${styles.rightContentItem}`}>
                <h3>Metadata</h3>
                {document.searchInformation?.journalName && 
                    <div className={styles.documentMetadata}>
                        <h6>Journal</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.searchInformation.journalName}</p>
                    </div>
                }
                {document.searchInformation?.impactFactor && 
                    <div className={styles.documentMetadata}>
                        <h6>Citation score</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.searchInformation.impactFactor}</p>
                    </div>
                }
                {document.searchInformation?.publicationDate &&
                    <div className={styles.documentMetadata}>
                        <h6>Publication date</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.searchInformation.publicationDate.toString()}</p>
                    </div>
                }
                {document.searchInformation?.filingDate &&
                    <div className={styles.documentMetadata}>
                        <h6>Filing date</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.searchInformation.filingDate.toString()}</p>
                    </div>
                }
                {document.searchInformation?.patentNumbers &&
                    <div className={styles.documentMetadata}>
                        <h6>Patent Numbers</h6>
                        {document.searchInformation.patentNumbers.map((patentNumber) => {
                            return (
                                <p key={patentNumber} onMouseUp={onSelectedTextOnMouseEvent}>{patentNumber}</p>
                            );
                        })}
                    </div>
                }
                {document.searchInformation?.patentCountries &&
                    <div className={styles.documentMetadata}>
                        <h6>Patent countries</h6>
                        {document.searchInformation.patentCountries.map((patentCountry, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <p key={`${patentCountry}-${index}`} onMouseUp={onSelectedTextOnMouseEvent}>{patentCountry}</p>
                            );
                        })}
                    </div>
                }
                {document.authorships && document.authorships.length > 0 &&
                    <div className={styles.documentMetadata}>
                        <h6>Authors</h6>
                        {document.authorships.map((authorship, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={styles.authorship} key={`${authorship.authorId}-${index}`}>
                                    {authorship.authorName && <p onMouseUp={onSelectedTextOnMouseEvent}>{authorship.authorName}</p>}
                                    {authorship.institutionName && <p onMouseUp={onSelectedTextOnMouseEvent}>{authorship.institutionName}</p>}
                                </div>
                            );
                        })}
                    </div>
                }
                {document.searchInformation?.fixedPatentAssignees &&
                    <div className={styles.documentMetadata}>
                        <h6>Assignees</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.searchInformation?.fixedPatentAssignees}</p>
                    </div>
                }
                {document.searchInformation?.fixedPatentAuthors &&
                    <div className={styles.documentMetadata}>
                        <h6>Inventors</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.searchInformation?.fixedPatentAuthors}</p>
                    </div>
                }

                {document.documentCustomInformation?.publicationDate &&
                    <div className={styles.documentMetadata}>
                        <h6>Publication date</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{new Date(document.documentCustomInformation.publicationDate).toISOString().split("T")[0]}</p>
                    </div>
                }
                {document.documentCustomInformation?.author &&
                    <div className={styles.documentMetadata}>
                        <h6>Author</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.documentCustomInformation.author}</p>
                    </div>
                }
                {document.documentCustomInformation?.affiliation &&
                    <div className={styles.documentMetadata}>
                        <h6>Affiliation</h6>
                        <p onMouseUp={onSelectedTextOnMouseEvent}>{document.documentCustomInformation.affiliation}</p>
                    </div>
                }
            </div>
        :
            null
    );
};