import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import styles from "./card.module.scss";
import { FindestButton } from "../FindestButton/FindestButton";
import { IconDefinition as regularIconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition as solidIconDefinition } from "@fortawesome/pro-solid-svg-icons";

// Interfaces for the props
interface CardProps {
  children: ReactNode | null;
  actions?: Action[];
}

interface CardTitleProps {
  children: ReactNode;
}

interface CardContentProps {
  children: ReactNode;
}

export interface Action {
  label: string;
  onClick: () => void;
  buttonType?: "primary" | "secondary" | "tertiary" | "quarternary" | "cancel";
  leftIconName?: regularIconDefinition | solidIconDefinition;
  rightIconName?: regularIconDefinition | solidIconDefinition;
}

// Main Card component
export const Card: React.FC<CardProps> & {
  Title: React.FC<CardTitleProps>;
  Content: React.FC<CardContentProps>;
} = ({ children, actions }) => {
  return (
    <div className={styles.card}>
      {children}
      {actions && actions.length > 0 && (
        <div className={styles.cardActions}>
          {actions.map((action, index) => (
            <FindestButton
              key={action.label}
              onClick={action.onClick}
              leftIconName={action.leftIconName}
              rightIconName={action.rightIconName}
              buttonType={action.buttonType}
            >
              {action.label}
            </FindestButton>
          ))}
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node as PropTypes.Validator<ReactNode>,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ) as PropTypes.Validator<Action[]>,
};

// Subcomponent for the title
const CardTitle: React.FC<CardTitleProps> = ({ children }) => {
  return <h2 className={styles.cardTitle}>{children}</h2>;
};

// Subcomponent for the content
const CardContent: React.FC<CardContentProps> = ({ children }) => {
  return <div className={styles.cardContent}>{children}</div>;
};

// Attach subcomponents to the Card component
Card.Title = CardTitle;
Card.Content = CardContent;
