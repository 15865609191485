// node_modules
import axios, { AxiosError } from "axios";
// Constants
import { LinkingConstants } from "Constants";
// Enums
import { ObjectTypeEnum, WebRequestStatusEnum } from "Enums";
// Helpers
import { AxiosHelperSingleton, LogHelperSingleton } from "Helpers";
// Types
import { TCreateLinkDTO, TCreateLinksDTO, TLinkedToDTO, TLinkGraphDTO, TLinkGraphNodeDTO, TLoadMoreLinkGraphDTO, TTypeGraphNodeDTO } from "Types";

export class LinkingController {
    private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/linking`;

    public async getWholeGraphAsync(): Promise<TLinkGraphNodeDTO[]> {
        try {
            const result = await axios.get<TLinkGraphNodeDTO[]>(`${this._resourcePath}`);
            if (result && result.data) {
                return result.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async getMaximumLowerLevelsAsync(focusedNodeId: string): Promise<number> {
        try {
            // get maximum lower levels
            const response = await axios
                .get<number>(`${this._resourcePath}/graph/${focusedNodeId}/maximumlowerlevels`);

            // deal with response
            if (response && response.data) {
                return response.data;
            } else {
                // otherwise return 0
                return 0;
            }
        } catch {
            // return 0
            return 0;
        }
    }

    public async createToAsync(toLinkdId: string, toLinkType: ObjectTypeEnum,
        fromLinkId: string, fromLinkType: ObjectTypeEnum, actionOrigin?: string): Promise<WebRequestStatusEnum> {
        try {
            const createLinkDTO: TCreateLinkDTO = {
                fromLinkId: fromLinkId,
                fromLinkType: fromLinkType,
                toLinkId: toLinkdId,
                toLinkType: toLinkType
            };

            await axios.post(`${this._resourcePath}`, createLinkDTO);

            LogHelperSingleton.logWithProperties("Link", {
                LinkFrom: fromLinkId,
                LinkFromType: fromLinkType,
                LinkTo: toLinkdId,
                LinkToType: toLinkType,
                ...(actionOrigin ? { ActionOrigin: actionOrigin } : {})
            });

            return WebRequestStatusEnum.Success;
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.status === 409) {
                    LogHelperSingleton.logWithProperties("LinkAlreadyExists", {
                        LinkFrom: fromLinkId,
                        LinkFromType: fromLinkType,
                        LinkTo: toLinkdId,
                        LinkToType: toLinkType,
                        ...(actionOrigin ? { ActionOrigin: actionOrigin } : {})
                    });

                    return WebRequestStatusEnum.AlreadyExists;
                }
            }

            return WebRequestStatusEnum.Failed;
        }
    }

    public async deleteAsync(fromLinkId: string, toLinkdId: string): Promise<boolean> {
        try {
            const deleteLink = {
                fromLinkId: fromLinkId,
                toLinkId: toLinkdId,
            } as TCreateLinkDTO;
            const result = await axios.post(`${this._resourcePath}/deletefrom`, deleteLink);

            return !!result;
        } catch {
            return false;
        }
    }

    public async getLinksAsync(objectId: string, linkTypes: ObjectTypeEnum[]): Promise<TLinkedToDTO[] | undefined> {
        try {
            // init url
            let url = `${this._resourcePath}/${objectId}`;

            // put linkTypes in url if it is defined
            for (let index = 0; index < linkTypes.length; index++) {
                const entityType = linkTypes[index];

                if (index === 0) url += "?";

                url += `objectType[]=${entityType}`;

                if (index + 1 < linkTypes.length) {
                    url += "&";
                }
            }

            const result = await axios.get<TLinkedToDTO[]>(url);

            if (result && result.data) {
                return result.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async getGraphByTypesAsync(): Promise<TTypeGraphNodeDTO[]> {
        try {
            const result = await axios.get<TTypeGraphNodeDTO[]>(`${this._resourcePath}/types`);

            if (result && result.data) {
                return result.data;
            } else {
                return [];
            }
        } catch {
            return [];
        }
    }

    public async hasLinksOfTypesAsync(objectId: string, linkTypes: ObjectTypeEnum[]): Promise<boolean> {
        try {
            // init url
            let url = `${this._resourcePath}/${objectId}/haslinkedtypes`;

            // put linkTypes in url if it is defined
            for (let index = 0; index < linkTypes.length; index++) {
                const entityType = linkTypes[index];

                if (index === 0) url += "?";

                url += `objectType[]=${entityType}`;

                if (index + 1 < linkTypes.length) {
                    url += "&";
                }
            }

            // get result
            const result = await axios.get<boolean>(url);

            // deal with result
            if (result) {
                return result.data;
            } else {
                // otherwise, return false
                return false;
            }
        } catch {
            // otherwise, return false
            return false;
        }
    }

    public async deleteBulkAsync(fromLinkId: string, fromLinkType: ObjectTypeEnum, toLinkdIds: string[], toLinkType: ObjectTypeEnum): Promise<boolean> {
        try {
            const createLinksDTO: TCreateLinksDTO = {
                fromLinkId: fromLinkId,
                fromLinkType: fromLinkType,
                toLinkIds: toLinkdIds,
                toLinkType: toLinkType
            };

            const result = await axios.post(`${this._resourcePath}/from/bulk`, createLinksDTO);

            if (result) {
                return true;
            } else {
                return false;
            }
        } catch {
            return false;
        }
    }

    public async getLinkGraphAsync(focusedNodeId: string, focusedNodeType: ObjectTypeEnum, lowerLevelsLimit: number = LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT,
        doIncludeSiblingsChildren = false
    ): Promise<TLinkGraphDTO | undefined> {
        try {
            // safety-checks on lowerLevelsLimit
            if (lowerLevelsLimit < 1) {
                lowerLevelsLimit = LinkingConstants.GET_LINK_GRAPH_LOWER_LEVELS_DEFAULT_LIMIT;
            }

            // get link graph
            const result = await axios.get<TLinkGraphDTO>(`${this._resourcePath}/graph/${focusedNodeId}/type/${focusedNodeType}`, {
                params: {
                    lowerLevelsLimit,
                    doIncludeSiblingsChildren
                }
            });

            if (result && result.data) {
                return result.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }

    public async postLoadMoreLinkGraph(focusedNodeId: string, focusedNodeType: ObjectTypeEnum,
        nodeIdsAlreadyVisited: string[],
        doIncludeSiblingsChildren = false,
        lowerLevelsLimit = 1,
    ): Promise<TLinkGraphDTO | undefined> {
        try {
            // safety-checks on lowerLevelsLimit
            if (lowerLevelsLimit < 1) {
                lowerLevelsLimit = 1;
            }

            // init url
            const url = `${this._resourcePath}/graph/loadmore/${focusedNodeId}/type/${focusedNodeType}?doIncludeSiblingsChildren=${doIncludeSiblingsChildren}&lowerLevelsLimit=${lowerLevelsLimit}`;

            // create TLoadMoreLinkGraphDTO
            const loadMoreLinkGraphDTO: TLoadMoreLinkGraphDTO = {
                nodeIdsAlreadyVisited
            };

            // get link graph
            const result = await axios.post<TLinkGraphDTO>(url, loadMoreLinkGraphDTO);

            if (result && result.data) {
                return result.data;
            } else {
                return undefined;
            }
        } catch {
            return undefined;
        }
    }
}

export const LinkingControllerSingleton = new LinkingController();