import { AiConstants, TextConstants } from "Constants";
import { AskIgorControllerSingleton, LinkingControllerSingleton } from "Controllers";
import { AskIgorMenuItemEnum, DocumentObjectTypeEnums, NotificationStatusEnum, ObjectTypeEnum, ToastTypeEnum } from "Enums";
import { AskIgorMenuItemHelperSingleton, LogHelperSingleton, ToastHelperSingleton } from "Helpers";
import { useAskIgorNotification } from "Hooks";
import { IAskIgorRequest, IAskIgorResponse, IAskIgorResult, INotification } from "Interfaces";
import { useCallback, useMemo, useRef, useState } from "react";
import { TLogEventName } from "Types";

export const useBulkAskIgor = (processGenerationSuccessAsync: (request: IAskIgorRequest, result: IAskIgorResult, correlationId: string) => Promise<void>,
  processGenerationErrorAsync?: (request: IAskIgorRequest, message: string, correlationId: string) => Promise<void>,
  onAllRequestsDone?: () => void) => {
  const [generationRequested, setGenerationRequested] =
    useState<Map<string, IAskIgorRequest>>(new Map());

  const timeoutRef = useRef<Map<string, number>>(new Map());
  const abortControllerRefs = useRef<Map<string, AbortController>>(new Map());

  const isRequestingGeneration = useMemo(() => generationRequested.size > 0, [generationRequested]);

  const clearRefMaps = useCallback((correlationId: string) => {
    if (timeoutRef.current.has(correlationId)) {
      clearTimeout(timeoutRef.current.get(correlationId));
      timeoutRef.current.delete(correlationId);
    }

    if (abortControllerRefs.current.has(correlationId)) {
      abortControllerRefs.current.get(correlationId)?.abort();
      abortControllerRefs.current.delete(correlationId);
    }
  }, []);

  const cancelAllGenerationRequests = useCallback((doSkipCallback = true) => {
    timeoutRef.current.forEach(clearTimeout);
    timeoutRef.current.clear();

    abortControllerRefs.current.forEach((abortController) => abortController.abort());
    abortControllerRefs.current.clear();

    generationRequested.forEach(
      async (_: IAskIgorRequest, correlationId: string) => {
        await AskIgorControllerSingleton.stopGenerationAsync(correlationId);
      }
    );
    setGenerationRequested(new Map());

    if (!doSkipCallback) onAllRequestsDone?.();
  }, [generationRequested, onAllRequestsDone]);

  const cancelGenerationRequest = useCallback((forInput: string): void => {
    setGenerationRequested((prev) => {
      prev.forEach(
        async (generationRequest: IAskIgorRequest, correlationId: string) => {
          if (generationRequest.input.userInput === forInput) {
            clearRefMaps(correlationId);
            await AskIgorControllerSingleton.stopGenerationAsync(correlationId);
            prev.delete(correlationId);
          }
        }
      );

      if (prev.size === 0) {
        onAllRequestsDone?.();
      }

      return new Map(prev);
    });
  }, [clearRefMaps, onAllRequestsDone]);

  const deleteGenerationRequestedAsync = useCallback(async (
    correlationId: string,
    doStopGeneration = false
  ): Promise<void> => {
    clearRefMaps(correlationId);

    if (doStopGeneration) await AskIgorControllerSingleton.stopGenerationAsync(correlationId);

    setGenerationRequested((prev) => {
      prev.delete(correlationId);
      if (prev.size === 0) {
        onAllRequestsDone?.();
      }
      return new Map(prev);
    });
  }, [clearRefMaps, onAllRequestsDone]);

  const onAiGenerationTimedOutAsync = useCallback(async (correlationId: string): Promise<void> => {
    deleteGenerationRequestedAsync(correlationId, true);

    ToastHelperSingleton.showToast(
      ToastTypeEnum.Error,
      AiConstants.AI_GENERATION_TIMED_OUT
    );
  }, [deleteGenerationRequestedAsync]);

  const onAskIgorNotificationAsync = useCallback(async (notification: INotification): Promise<void> => {
    const { correlationId, message, status } = notification;
    const result = JSON.parse(message) as IAskIgorResult;
    const generation = generationRequested.get(correlationId);

    if (!generation) return;

    switch (status) {
      case NotificationStatusEnum.Error:
        processGenerationErrorAsync ? processGenerationErrorAsync(generation, message, correlationId) : ToastHelperSingleton.showToast(ToastTypeEnum.Error, message);
        break;
      case NotificationStatusEnum.Success:
        await processGenerationSuccessAsync(generation, result, correlationId);
        break;
      default:
        break;
    }

    await deleteGenerationRequestedAsync(correlationId);
  }, [deleteGenerationRequestedAsync, generationRequested, processGenerationErrorAsync, processGenerationSuccessAsync]);

  useAskIgorNotification(onAskIgorNotificationAsync);

  const addGenerationRequested = useCallback((requests: IAskIgorRequest[]): void => {
    requests.forEach((request: IAskIgorRequest) => {
      const abortController = new AbortController();
      abortControllerRefs.current.set(request.correlationId, abortController);
      const timeoutId = window.setTimeout(onAiGenerationTimedOutAsync, AiConstants.AI_GENERATION_TIMEOUT_DURATION, request.correlationId);
      timeoutRef.current.set(request.correlationId, timeoutId);
    });

    setGenerationRequested((prev) => {
      requests.forEach((request: IAskIgorRequest) => {
        prev.set(request.correlationId, request);
      });

      return new Map(prev);
    });
  }, [onAiGenerationTimedOutAsync]);

  const requestGenerationAsync = useCallback(async (request: IAskIgorRequest, generationType: AskIgorMenuItemEnum): Promise<boolean> => {
    const doRequireSourcesOrHighlights =
      AskIgorMenuItemHelperSingleton.getDoRequireSourcesOrHighlights(
        generationType
      );

    if (doRequireSourcesOrHighlights) {
      const noDocumentsOrHighlights = (!request.sourceIds || request.sourceIds.length === 0) && !request.input.pageId;
      const pageWithoutLinks =
        request.input.pageId &&
        !await LinkingControllerSingleton.hasLinksOfTypesAsync(request.input.pageId, [
          ObjectTypeEnum.Highlight,
          ...DocumentObjectTypeEnums
        ]);

      if (noDocumentsOrHighlights || pageWithoutLinks) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          TextConstants.AI_REQUIRES_DOCUMENTS_OR_HIGHLIGHTS
        );
        return false;
      }
    }

    const startLogEventName: TLogEventName =
      AskIgorMenuItemHelperSingleton.getRelatedStartLogEventName(
        generationType
      );
    LogHelperSingleton.log(startLogEventName);

    const abortControllerRef = abortControllerRefs.current.get(request.correlationId);

    let response: IAskIgorResponse | undefined = undefined;
    if (generationType === AskIgorMenuItemEnum.InformationExtraction) {
      response = await AskIgorControllerSingleton.generateInformationExtractionAsync(request, abortControllerRef?.signal);
    } else if (generationType === AskIgorMenuItemEnum.DescriptionFromLinkedSources) {
      response = await AskIgorControllerSingleton.generateDescriptionFromLinkedSourcesAsync(request, abortControllerRef?.signal);
    }

    return abortControllerRef?.signal.aborted || response !== undefined;
  }, []);

  return { generationRequested, addGenerationRequested, isRequestingGeneration, cancelAllGenerationRequests, cancelGenerationRequest, deleteGenerationRequestedAsync, requestGenerationAsync };
};