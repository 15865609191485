// DUPLICATED CODE: Most of this file is duplicated code from SavedInboxDocumentItem.tsx file.
// TODO: Modify when the new Documents APIs are fully implemented.
// node_modules
import {
  faArrowUpRightFromSquare,
  faLink,
  faLinkSlash,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import { Checkbox, CreatedByAccount, LinkedCounts, OpenAccess, Tooltip } from "Components";
// Styles
import styles from "./savedDocumentItem.module.scss";
// Types
import { IInboxListItem } from "Interfaces";
// Helpers
import {
  DateHelperSingleton,
  DocumentTypeHelperSingleton,
  LogHelperSingleton,
} from "Helpers";
type TSavedDocumentTypeProps = {
  savedDocument: IInboxListItem;
  isSelected: boolean;
  onCheckboxChange?: (
    isChecked: boolean,
    savedDocument: IInboxListItem
  ) => void;
  onLinkSavedDocumentClick?: (savedDocument: IInboxListItem) => void;
  onDeleteSavedDocumentClick?: (savedDocument: IInboxListItem) => Promise<void>;
  deleteIsUnlink?: boolean;
  isInboxSection?: boolean;
};
export const SavedInboxDocumentItem: FC<TSavedDocumentTypeProps> = ({
  savedDocument,
  isSelected,
  onCheckboxChange,
  onLinkSavedDocumentClick,
  onDeleteSavedDocumentClick,
  deleteIsUnlink,
  isInboxSection,
}: TSavedDocumentTypeProps) => {
  // state
  const [currSavedDocument, setCurrSavedDocument] =
    useState<IInboxListItem>(savedDocument);
  const [isOpenArticleButtonTooltipOpen, setIsOpenArticleButtonTooltipOpen] =
    useState<boolean>(false);
  const documentUrl = currSavedDocument.fullUrl ?? currSavedDocument.url ?? undefined;
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const openArticleButtonContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setCurrSavedDocument(savedDocument);
  }, [savedDocument]);
  const onOpenDocumentClick = (): void => {
    // safety-checks
    if (!currSavedDocument.id) {
      return;
    }
    // log
    LogHelperSingleton.log("OpenDocument");
    // if the location is not an entity or a study page, then navigate to the document page
    // init url to navigate to
    let urlToNavigateTo = "";
    // if current location pathname does not contain "/", "/inbox" or "/library"
    if (["/", "/inbox", "/library"].indexOf(location.pathname) === -1) {
      // set url to navigate to library
      urlToNavigateTo = `/library/documents/${currSavedDocument.id}`;
    } else {
      // set url to navigate to inbox or library depending on current location pathname
      const currentPath =
        ["/", "/inbox"].indexOf(location.pathname) > -1 ? "inbox" : "library";
      urlToNavigateTo = `/${currentPath}/documents/${currSavedDocument.id}`;
    }
    // navigate to url if url to navigate to is not empty
    if (urlToNavigateTo) {
      navigate(urlToNavigateTo);
    }
  };
  const onOpenDocumentInNewTabClick = (event: MouseEvent): void => {
    // prevent the default behavior of the underlying element
    event.preventDefault();
    event.stopPropagation();
    // safety-checks
    if (!documentUrl) {
      return;
    }
    // log
    LogHelperSingleton.log("GotoDocumentUrl");
    // open document in new tab
    window.open(documentUrl, "_blank", "noopener noreferrer");
  };
  const getLinkedCountsClassName = (): string => {
    if (
      (currSavedDocument.linkedObjectCounts &&
        currSavedDocument.linkedObjectCounts.documentCount !== 0) ||
      currSavedDocument.linkedObjectCounts?.entityCount !== 0 ||
      currSavedDocument.linkedObjectCounts?.fileCount !== 0 ||
      currSavedDocument.linkedObjectCounts?.highlightCount !== 0 ||
      currSavedDocument.linkedObjectCounts?.imageCount !== 0 ||
      currSavedDocument.linkedObjectCounts?.projectCount !== 0 ||
      currSavedDocument.linkedObjectCounts?.studyCount !== 0
    ) {
      return styles.linkedDoc;
    } else {
      return styles.unlinkedDoc;
    }
  };
  // Render
  return (
    <div
      className={[
        styles.savedDocumentItemContainer,
        isSelected ? styles.selected : "",
        isInboxSection ? getLinkedCountsClassName() : "",
      ].join(" ")}
    >
      <div
        onClick={onOpenDocumentClick} className={styles.savedDocumentItem}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onOpenDocumentClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {onCheckboxChange ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.stopPropagation();
              }
            }}
            className={styles.checkboxContainer}
            role="button"
            tabIndex={0}
          >
            <Checkbox
              isChecked={isSelected}
              onCheckboxChange={(isChecked) => {
                onCheckboxChange(isChecked, currSavedDocument);
              }}
              theme="black"
            />
          </div>
        ) : null}
        <p className={styles.savedDocumentType}>
          {DocumentTypeHelperSingleton.getSavedDocumentTypeDisplayName(
            currSavedDocument.savedDocumentType
          )}
        </p>
        <div className={styles.savedDocumentDetails}>
          <div
            title={currSavedDocument.title}
            className={styles.savedDocumentTitle}
          >
            <span className={styles.savedDocumentTitleText}>
              {currSavedDocument.isOpenAccess && <OpenAccess />}
              {currSavedDocument.title}
            </span>
            <div ref={openArticleButtonContainerRef}>
              <FontAwesomeIcon
                title={documentUrl}
                className={[
                  styles.openUrlIcon,
                  documentUrl ? "" : styles.openUrlIconDisabled,
                ].join(" ")}
                icon={faArrowUpRightFromSquare}
                onClick={onOpenDocumentInNewTabClick}
                onMouseOver={() => setIsOpenArticleButtonTooltipOpen(true)}
                onMouseOut={() => setIsOpenArticleButtonTooltipOpen(false)}
              />
              <Tooltip
                referenceEl={openArticleButtonContainerRef.current}
                isOpen={isOpenArticleButtonTooltipOpen}
                tooltipText={
                  documentUrl ?? "The document has no URL"
                }
                placement="bottom-start"
              />
            </div>
          </div>
          <div className={styles.savedDocumentCountsContainer}>
            {currSavedDocument.linkedObjectCounts ? (
              <LinkedCounts
                objectId={currSavedDocument.id}
                indexingStatus={currSavedDocument.documentCustomInformation?.indexingStatus}
                linkedCounts={currSavedDocument.linkedObjectCounts}
                viewMode="list"
              />
            ) : null}
          </div>
        </div>
        <div className={styles.creationInformation}>
          <div className={styles.dateAdded}>
            {DateHelperSingleton.getShortenedDate(
              currSavedDocument.dateAdded
            )}
          </div>
          {currSavedDocument.createdByUsername && (
            <CreatedByAccount
              email={currSavedDocument.createdByUsername}
              userIconSize="small"
              extraClassNames={{
                createdByAccountContainer: styles.createdByAccountContainer,
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.savedDocumentItemActions}>
        {onLinkSavedDocumentClick && (
          <FontAwesomeIcon
            title="Link to"
            icon={faLink}
            className={styles.linkIcon}
            onClick={() => {
              onLinkSavedDocumentClick(currSavedDocument);
            }}
          />
        )}
        {onDeleteSavedDocumentClick && (
          <FontAwesomeIcon
            title={deleteIsUnlink ? "Unlink" : "Delete from inbox"}
            icon={deleteIsUnlink ? faLinkSlash : faTrashCan}
            className={deleteIsUnlink ? styles.unlinkIcon : styles.trashIcon}
            onClick={() => {
              onDeleteSavedDocumentClick(currSavedDocument);
            }}
          />
        )}
      </div>
    </div>
  );
};
