import { FC, useState } from "react";
import { FindestButton, Modal } from "Components";
import styles from "./technologyPromptHelper.module.scss";

type TTechnologyPromptHelper = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  onQueryUpdateAsync: (newNLQ: string) => Promise<void>;
  onDocuments?: boolean;
};

export const TechnologyPromptHelper: FC<TTechnologyPromptHelper> = ({
  isModalOpen,
  setIsModalOpen,
  onQueryUpdateAsync,
  onDocuments,
}: TTechnologyPromptHelper) => {
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<{ question: string; answer: string }[]>([
    {
      question: onDocuments
        ? "What are you looking for? How do you want to group the results?"
        : "What technology/material/ingredient are you exploring?",
      answer: "",
    },
    {
      question: onDocuments
        ? "What can help judge if it is the right answer?"
        : "What function or goal should this technology fulfill?",
      answer: "",
    },
    {
      question: onDocuments
        ? "What type of answer are you expecting? Can you provide example of technologies?"
        : "Which known technologies might fit your needs?",
      answer: "",
    },
    {
      question: onDocuments
        ? ""
        : "Are there any technologies or features you want to avoid?",
      answer: "",
    },
  ]);

  const onSearchClickAsync = async (): Promise<void> => {
    const newNLQ = buildPrompt(questionsAndAnswers, onDocuments || false);
    await onQueryUpdateAsync(newNLQ);
    setIsModalOpen(false);
  };

  const buildPrompt = (
    qa: { question: string; answer: string }[],
    isOnDocuments: boolean
  ): string => {
    const limit = isOnDocuments ? 3 : 4;
    return qa.slice(0, limit)
      .map((item, index) => `Q${index + 1}: ${item.question} ${item.answer}`)
      .join("\n");
  };

  return (
    <Modal
      isOpen={true}
      extraClassNames={{
        overlay: isModalOpen ? "" : styles.modalClosed,
        container: styles.technologyPromptHelperContainer,
        header: styles.header,
      }}
      header="Help me write a great a question"
    >
      <p>
        The better the question, the better the results. So, to help you craft a
        great question, we ask you a couple of questions for you to answer.
        After generating the question, you can still edit and shape the question
        to exactly fit your needs.
      </p>
      {questionsAndAnswers
        .filter((item) => item.question.length)
        .map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.question}>
            <h4>{item.question}</h4>
            <textarea
              value={item.answer}
              onChange={(e) => {
                setQuestionsAndAnswers((prev) => {
                  const newQuestionsAndAnswers = [...prev];
                  newQuestionsAndAnswers[index].answer = e.target.value;
                  return newQuestionsAndAnswers;
                });
              }}
            />
          </div>
        ))}
      <div className={styles.buttons}>
        <FindestButton
          title="Search"
          onClick={onSearchClickAsync}
        />
        <FindestButton
          title="Cancel"
          buttonType="secondary"
          onClick={() => {
            setIsModalOpen(false);
          }}
        />
      </div>
    </Modal>
  );
};
