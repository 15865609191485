import { JSONContent } from "@tiptap/react";
import { EditorConstants } from "Constants";
import { EntityControllerSingleton } from "Controllers";
import { ToastTypeEnum } from "Enums";
import { ToastHelperSingleton } from "Helpers";
import { IEntityDTO } from "Interfaces";

export const useEntity = () => {
  const updateEntityDescritpionAsync = async (
    entityId: string,
    newDescription: string
  ): Promise<boolean> => {
    const isSuccess =
      await EntityControllerSingleton.updateDescriptionAsync(
        entityId,
        newDescription
      );

    if (!isSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not update entity description."
      );

      return false;
    }

    return true;
  };

  const appendContentToEntityDescriptionAsync = async (
    entityId: string,
    content: JSONContent[]
  ): Promise<boolean> => {
    const entity: IEntityDTO | undefined =
      await EntityControllerSingleton.getByIdAsync(entityId);

    if (!entity) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not get entity."
      );

      return false;
    }

    let newDescription: JSONContent =
      EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
    try {
      newDescription = entity.description
        ? JSON.parse(entity.description)
        : EditorConstants.DEFAULT_TIPTAP_EDITOR_JSON_CONTENT;
    } catch (error) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Could not get entity description."
      );
      return false;
    }

    newDescription.content?.push(...content);

    return await updateEntityDescritpionAsync(
      entity.id,
      JSON.stringify(newDescription)
    );
  };

  return {
    updateEntityDescritpionAsync,
    appendContentToEntityDescriptionAsync,
  };
};
