/* eslint-disable @typescript-eslint/no-explicit-any */
import { AskIgorMenuItemEnum } from "Enums";
import { IAskIgorResult, IReferenceTextAndLink } from "Interfaces";
import { Converter } from "showdown";

type Document = { [key: string]: any };
type Highlight = { [key: string]: any };

export class AskIgorHelper {
    private linkPropertyName = "link";

    public extractKeys(
        obj: { [key: string]: string },
        dataToExtract: Set<string>
    ): void {
        Object.keys(obj).forEach((key) => {
            if (key !== this.linkPropertyName) {
                dataToExtract.add(key);
            }
        });
    }

    public integrateHighlights(
        documents: { [key: string]: Document },
        highlights: { [key: string]: Highlight },
        dataToExtract: Set<string>
    ): void {
        Object.values(documents).forEach((document) =>
            this.extractKeys(document, dataToExtract)
        );

        for (const [highlightId, highlight] of Object.entries(highlights)) {
            let highlightNotFoundInDocuments = true;

            this.extractKeys(highlight, dataToExtract);

            for (const document of Object.values(documents)) {
                if (highlight.link === document.link) {
                    highlightNotFoundInDocuments = false;

                    if (!document.highlights) {
                        document.highlights = {};
                    }

                    document.highlights[highlightId] = highlight;
                }
            }

            if (highlightNotFoundInDocuments) {
                documents[highlightId] = highlight;
            }
        }
    }

    public generateContent(
        documents: { [key: string]: Document },
        dataToExtract: Set<string>,
        formatType: "MARKDOWN" | "HTML_UNORDERED_LIST" | "HTML_PARAGRAPH",
        forDataPoint?: string
    ): string {
        const converter = new Converter();
        let contentArray: string[] = [];

        for (const [documentId, document] of Object.entries(documents)) {
            const documentContentArray: string[] = [];

            if (formatType === "HTML_UNORDERED_LIST") {
                documentContentArray.push(`<li>${documentId}<ul>`);
            }

            for (const dataPoint of Array.from(dataToExtract)) {
                if (forDataPoint && forDataPoint !== dataPoint) continue;

                let hasValidContent = false;
                const docContent = document[dataPoint]?.trim();

                if (docContent) {
                    let docContentString = "";

                    switch (formatType) {
                        case "HTML_UNORDERED_LIST":
                            docContentString = `<li>${converter.makeHtml(docContent)} <a target="_blank" rel="noopener noreferrer" href="${document.link}">[Ref]</a></li>`;
                            break;
                        case "HTML_PARAGRAPH":
                            docContentString = `<p>${converter.makeHtml(docContent)} <a target="_blank" rel="noopener noreferrer" href="${document.link}">[Ref]</a></p>`;
                            break;
                        case "MARKDOWN":
                        default:
                            docContentString = `${docContent} [[Ref]](${document.link})\n\n`;
                            break;
                    }

                    documentContentArray.push(docContentString);
                    hasValidContent = true;
                }

                const documentHighlights = document.highlights || {};

                for (const highlightId of Object.keys(documentHighlights)) {
                    const highlight = documentHighlights[highlightId];
                    const highlightContent = highlight[dataPoint]?.trim();

                    if (highlightContent) {
                        let highlightContentString = "";

                        switch (formatType) {
                            case "HTML_UNORDERED_LIST":
                                highlightContentString = `<li>${converter.makeHtml(highlightContent)} <a target="_blank" rel="noopener noreferrer" href="${highlight.link}">[Ref]</a></li>`;
                                break;
                            case "HTML_PARAGRAPH":
                                highlightContentString = `<p>${converter.makeHtml(highlightContent)} <a target="_blank" rel="noopener noreferrer" href="${highlight.link}">[Ref]</a></p>`;
                                break;
                            case "MARKDOWN":
                            default:
                                highlightContentString = `${highlightContent} [[Ref]](${highlight.link})\n\n`;
                                break;
                        }

                        documentContentArray.push(highlightContentString);
                        hasValidContent = true;
                    }
                }

                if (!hasValidContent && formatType === "MARKDOWN") continue;

                if (formatType === "HTML_UNORDERED_LIST" && !hasValidContent) {
                    documentContentArray.push("<li>N/A</li>");
                }
            }

            if (formatType === "HTML_UNORDERED_LIST") {
                documentContentArray.push("</ul></li>");
            }

            if (documentContentArray.length > 0) {
                contentArray = contentArray.concat(documentContentArray);
            }
        }

        const content = contentArray.join("");

        if (!content) {
            switch (formatType) {
                case "HTML_UNORDERED_LIST":
                    return "<ul><li>N/A</li></ul>";
                case "HTML_PARAGRAPH":
                    return "<p>N/A</p>";
                case "MARKDOWN":
                default:
                    return "N/A";
            }
        }

        switch (formatType) {
            case "HTML_UNORDERED_LIST":
                return `<ul>${content}</ul>`;
            case "HTML_PARAGRAPH":
                return content;
            case "MARKDOWN":
            default:
                return content.trim();
        }
    }

    public generateContentFromAskIgorResult(
        formatType: "MARKDOWN" | "HTML_UNORDERED_LIST" | "HTML_PARAGRAPH",
        documents: {
            [key: string]: {
                [key: string]: string;
            };
        },
        highlights: {
            [key: string]: {
                [key: string]: string;
            };
        },
        forDataPoint?: string
    ): string {
        const dataToExtract = new Set<string>();

        this.integrateHighlights(documents, highlights, dataToExtract);

        return this.generateContent(
            documents,
            dataToExtract,
            formatType,
            forDataPoint
        );
    }

    public replaceReferencesInContent(
        content: string,
        referenceDict?: { [key: string]: IReferenceTextAndLink }
    ): string {
        let contentCopy = content;

        const referencesInContent: RegExpMatchArray | null = contentCopy.match(
            /\[REF_(abstract|highlight)_\d+_\d+\]/g
        );

        if (referencesInContent && referenceDict) {
            for (const referenceInContent of referencesInContent) {
                let referenceId = referenceInContent;
                referenceId = referenceId.replace("[", "");
                referenceId = referenceId.replace("]", "");

                if (!referenceDict[referenceId]) {
                    continue;
                }

                const reference: IReferenceTextAndLink | undefined =
                    referenceDict[referenceId];

                if (!reference?.fulltextlink) {
                    continue;
                }

                contentCopy = contentCopy.replace(
                    referenceInContent,
                    `<a target="_blank" rel="noopener noreferrer" href="${reference.fulltextlink}">[Ref]</a>`
                );
            }
        }

        return contentCopy;
    }

    public getAskIgorResultGenerationContent(result: IAskIgorResult, askIgorMenuItem?: AskIgorMenuItemEnum): string {
        if (!askIgorMenuItem) return "";

        const getContentWithReplacedRefs = (content: string, referenceDict?: {
            [key: string]: IReferenceTextAndLink;
        }) =>
            this.replaceReferencesInContent(`<p>${content}</p>`, referenceDict);

        switch (askIgorMenuItem) {
            case AskIgorMenuItemEnum.Answer:
                return result.answer ?? "";
            case AskIgorMenuItemEnum.DescriptionFromGeneralKnowledge:
                return result.descriptionFromGeneralKnowledge ?? "";
            case AskIgorMenuItemEnum.Report:
                return result.report ?? "";
            case AskIgorMenuItemEnum.Section:
                return result.section ?? "";
            case AskIgorMenuItemEnum.DescriptionFromLinkedSources: {
                const { descriptionFromLinkedSources } = result;
                if (descriptionFromLinkedSources?.TechnologyDescription?.Description) {
                    return getContentWithReplacedRefs(descriptionFromLinkedSources.TechnologyDescription.Description, descriptionFromLinkedSources?.TechnologyDescription?.ReferenceDict);
                }
                break;
            }
            case AskIgorMenuItemEnum.ExecutiveSummary: {
                const { executiveSummary } = result;
                if (executiveSummary?.ExecutiveSummary?.Summary) {
                    return getContentWithReplacedRefs(executiveSummary.ExecutiveSummary.Summary, executiveSummary?.ExecutiveSummary?.ReferenceDict);
                }
                break;
            }
            case AskIgorMenuItemEnum.InformationExtraction: {
                const { informationExtraction } = result;
                if (informationExtraction?.InformationExtraction) {
                    return this.generateContentFromAskIgorResult("HTML_UNORDERED_LIST", informationExtraction.InformationExtraction.Documents || {}, informationExtraction.InformationExtraction.Highlights || {});
                }
                break;
            }
            default:
                return "";
        }

        return "";
    }
}

export const AskIgorHelperSingleton = new AskIgorHelper();