// node_modules
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FC, useEffect, useMemo, useState } from "react";
// Contexts
import { defaultEditorContext } from "Providers";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import {
  AskIgorButton,
  AskIgorModal,
  Checkbox,
  SaveToPage
} from "Components";
// Interfaces
import {
  fromIDocumentSearchResultTOISavedDocumentDTO,
  IAskIgorModalOptions,
  IDocumentSearchResult,
  IQueryDTO
} from "Interfaces";
// Styles
import styles from "./querySaveResults.module.scss";
// Controllers
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type TQuerySaveResults = {
  documentsSelected: IDocumentSearchResult[];
  filteredDocumentResults: IDocumentSearchResult[];
  query: IQueryDTO;
  documentCount: number;
  onEntityLinkToAll?: (isChecked: boolean) => void;
  onDeselectAllDocuments?: () => void;
  hasAnswer?: boolean;
  onDocumentsLinkedAsync?: (toObject: TIdNameTypeObjectType) => Promise<void>;
  saveBulk?: boolean;
};

export const QuerySaveResults: FC<TQuerySaveResults> = ({
  documentsSelected,
  filteredDocumentResults,
  query,
  documentCount,
  onEntityLinkToAll,
  onDeselectAllDocuments,
  hasAnswer = false,
  onDocumentsLinkedAsync,
  saveBulk,
}) => {
  const [askIgorModalOptions, setAskIgorModalOptions] =
    useState<IAskIgorModalOptions>(defaultEditorContext.askIgorModalOptions);
  const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);

  const isAllSavedDocumentsSelected = useMemo(() => {
    return (
      filteredDocumentResults.length > 0 &&
      filteredDocumentResults.every((element) =>
        documentsSelected.some(
          (selected) => selected.documentId === element.documentId
        )
      )
    );
  }, [filteredDocumentResults, documentsSelected]);

  const isAnySavedDocumentSelected = useMemo(() => {
    const someMatch = filteredDocumentResults.some((element) =>
      documentsSelected.find(
        (selected) => selected.documentId === element.documentId
      )
    );
    return someMatch && !isAllSavedDocumentsSelected;
  }, [filteredDocumentResults, isAllSavedDocumentsSelected, documentsSelected]);

  const onAskIgorButtonClick = () => {
    setAskIgorModalOptions?.((prevAskIgorModalOptions) => {
      return {
        ...prevAskIgorModalOptions,
        isOpen: true,
        isMinimized: false,
        documentsSelected: documentsSelected.map(
          (doc) => doc.documentId
        ),
      };
    });
  };

  useEffect(() => {
    if (saveBulk === undefined) return;
    !isAllSavedDocumentsSelected && onEntityLinkToAll?.(!isAllSavedDocumentsSelected);
    setIsSavePopupOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveBulk]);

  return (
    <div className={`${styles.querySaveResultsContainer} ${!hasAnswer ? styles.sticky : ""}`}>
      {hasAnswer ? (
        <FontAwesomeIcon
          title="Link to"
          icon={faLink}
          className={styles.queryAnswerResultIcon}
          onClick={() => {
            setIsSavePopupOpen(true);
          }}
        />
      ) : (
        <div className={styles.querySaveResultsContent}>
          <div className={styles.querySaveResultsHeader}>
            <Checkbox
              theme="black"
              isPartiallySelected={isAnySavedDocumentSelected}
              isChecked={isAllSavedDocumentsSelected}
              onCheckboxChange={() => {
                onEntityLinkToAll?.(!isAllSavedDocumentsSelected);
              }}
            />
            {documentsSelected.length > 0 && (
              <>
                <button
                  type="button"
                  className={styles.querySaveResultsButton}
                  onClick={() => {
                    setIsSavePopupOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon={faLink} />
                  Save
                </button>
                <AskIgorButton onClick={onAskIgorButtonClick} />
              </>
            )}
            <div className={styles.querySaveResultsText}>
              {documentsSelected.length > 0 ? (
                <p>
                  {documentsSelected.length} out of {documentCount} documents
                  selected
                </p>
              ) : (
                <p>Select first {filteredDocumentResults.length} documents</p>
              )}
            </div>
          </div>
        </div>
      )}
      <SaveToPage
        isSavePopupOpen={isSavePopupOpen}
        setIsSavePopupOpen={setIsSavePopupOpen}
        query={query}
        onDeselectAllDocuments={onDeselectAllDocuments}
        onDocumentsLinkedAsync={onDocumentsLinkedAsync}
        documentsSelected={documentsSelected} />
      {askIgorModalOptions.isOpen && <AskIgorModal
        query={query}
        options={askIgorModalOptions}
        setOptions={setAskIgorModalOptions}
        selectedSavedDocuments={documentsSelected.map(doc => fromIDocumentSearchResultTOISavedDocumentDTO(doc))}
      />}
    </div>
  );
};
